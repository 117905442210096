import Vue from 'vue'
import * as signalR from '@microsoft/signalr'

// const CLIENT_BASE_URL = process.env.VUE_APP_CLIENT_API_BASE_URL
const CLIENT_BASE_URL = "https://api.aiseex.com"
let lockResolver;

const hub = new signalR.HubConnectionBuilder()
    .withUrl(`${CLIENT_BASE_URL}/hubs/trade`)
    //定义重新连接尝试次数（以毫秒为单位）
    .withAutomaticReconnect([0, 2 * 1000, 10 * 1000, 30 * 1000])
    .configureLogging(signalR.LogLevel.Information)
    .build();
hub.onreconnecting(error => {
    console.warn(`hub连接已断开，正在尝试重新连接..`, error);
})
hub.onreconnected(connectionId => {
    console.info(`hub已重新连接!`, connectionId);
});
hub.onclose(error => {
    console.error(`hub连接已关闭，请手动重连!`, error);
    if (typeof (lockResolver) === "function") {
        lockResolver();
        lockResolver = undefined;
        console.debug('网络锁已释放!');
    }
})

const CallbackEvents = {
    //K线更新时触发
    OnSymbolKLineAppendAsync: "OnSymbolKLineAppendAsync",
    //订单成交时触发
    OnSymbolOrderTradedAsync: "OnSymbolOrderTradedAsync",
    //盘口更新时触发
    OnSymbolOrderbookUpdatedAsync: "OnSymbolOrderbookUpdatedAsync",
    //行情变动时触发
    OnSymbolDayMarketChangedAsync: "OnSymbolDayMarketChangedAsync"
}

/**
 * 获取网络锁权限，防止浏览器标签进入睡眠状态导致连接断开
 * @returns 
 */
function getWebLockPromise() {
    if (lockResolver) {
        return;
    }
    if (navigator && navigator.locks && navigator.locks.request) {
        const promise = new Promise((res) => {
            lockResolver = res;
            console.debug('网络锁已获得!');
        });

        navigator.locks.request('unique_lock_name', { mode: "shared" }, () => {
            return promise;
        });
    }
}
/**
 * 连接到hub
 * @returns 
 */
async function connection() {
    if (hub.state === signalR.HubConnectionState.Connected) {
        console.debug("hub已连接!", hub)
        return;
    }

    try {
        await hub.start();
        console.debug("hub连接成功!", hub)
    } catch (err) {
        console.error("hub连接失败!", err)
        return
    }
    getWebLockPromise();

}
/**
 * 断开hub连接
 * @returns 
 */
async function close() {
    if (hub.state === signalR.HubConnectionState.Disconnected) {
        console.debug("hub已断开!", hub)
        return;
    }
    hub.stop();
    console.debug("hub连接关闭!", hub)
}
/**
 * 确保hub已连接，未连接将抛出异常
 */
function ensureConnected() {
    if (hub.state !== signalR.HubConnectionState.Connected) {
        throw "hub尚未连接!";
    }
}
/**
 * 调用服务器方法
 * @param {String} method 
 * @param {Object} params 
 * @returns 
 */
async function invoke(method, params) {
    try {
        ensureConnected();
    } catch {
        await connection();
    }
    try {
        return await hub.invoke(method, params);
    } catch (err) {
        console.error('hub调用异常!', err)
    }

}
/**
 * 订阅事件
 * @param {Array} events
 * @returns 订阅成功的事件
 */
async function subscribes(events) {
    var subEvents = await invoke("SubscribesAsync", events);
    if (!subEvents || subEvents.length < 1) {
        console.warn(`事件订阅失败!`, events);
        return [];
    }
    console.debug(`事件订阅成功!`, subEvents);
    return subEvents;
}
/**
 * 取消订阅
 * @param {Array} events 
 * @returns 取消订阅的事件
 */
async function unsubscribes(events) {
    var unsubEvents = await invoke("UnsubscribesAsync", events);
    if (!unsubEvents || unsubEvents.length < 1) {
        console.warn(`取消事件订阅失败!`, events);
        return [];
    }
    console.debug(`取消事件订阅成功!`, unsubEvents);
    return unsubEvents;
}
/**
 * 接收到指定事件时触发
 * @param {String} event 事件名
 * @param {Function} handler 处理程序
 * @param {Boolean} replace 是否替换已有的处理程序
 */
function onReceived(event, handler, replace = true) {
    if (replace) {
        hub.off(event)
    }
    hub.on(event, handler);
}

/**
 * 订阅/取消订阅订单成交事件
 * @param {String} symbolId 
 * @param {Boolean} sub 
 * @returns 
 */
async function subscribeTradeEvent(symbolId, sub = true) {
    const event = `symbol#trade#${symbolId}`;
    if (sub) {
        return await subscribes([event])
    } else {
        return await unsubscribes([event])
    }

}
/**
 * 订阅/取消订阅K线更新事件
 * @param {String} symbolId 
 * @param {Boolean} sub 
 * @returns 
 */
async function subscribeKlineEvent(symbolId, sub = true) {
    const event = `symbol#kline#${symbolId}`;
    if (sub) {
        return await subscribes([event])
    } else {
        return await unsubscribes([event])
    }
}
/**
 * 订阅/取消订阅盘口更新事件
 * @param {String} symbolId 
 * @returns 
 */
async function subscribeOrderbookEvent(symbolId, sub = true) {
    const event = `symbol#orderbook#${symbolId}`;
    if (sub) {
        return await subscribes([event])
    } else {
        return await unsubscribes([event])
    }
}
/**
 * 订阅/取消订阅行情变动事件
 * @param {Array} symbolIds 
 * @param {Boolean} sub 
 */
async function subscribeMarketEvent(symbolIds, sub = true) {
    let events = [];
    symbolIds.forEach(s => events.push(`symbol#market#${s}`))
    if (sub) {
        return await subscribes(events)
    } else {
        return await unsubscribes(events)
    }
}
//挂载到vue
Vue.prototype.$tradeHub = {
    CallbackEvents,
    connection,
    close,
    subscribes,
    unsubscribes,
    onReceived,
    subscribeTradeEvent,
    subscribeKlineEvent,
    subscribeOrderbookEvent,
    subscribeMarketEvent
};