<template>
	<div class="headerNav">
		<span class="head fl"><i class="iconfont">&#xe65f;</i><a @click="tap()">{{name || $t('common.login')+' / '+$t('common.register')}}</a></span>
		<div class="search fr tr"><i><img :src="sitelogo" height="20"></i></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'Header',
	computed: {
		...mapGetters(['name','sitelogo'])
	},
	methods: {
		tap () {
			if(this.name.length) {
				this.$router.push("/me")
			}else {
				this.$router.push("/login")
			}
		}
	}
}
</script>

<style scoped>
	.headerNav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		height: 56px;
		line-height: 56px;
		padding: 0 10px;
		color: #666;
		background-color: #fbfbfb;
		z-index: 2;
	}
	.head {
		display: inline-block;
		line-height: 56px;
	}
	.head i {
		font-size: 30px;
		width: 56px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.head a {
		line-height: 56px;
		display: inline-block;
		font-size: 14px;
	}
	.search {
		width: 100px;
	}
	.search i {
		font-size: 16px;
	}
</style>
