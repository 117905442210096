import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import './assets/css/reset.css'
import './assets/css/common.css'
import Mint from 'mint-ui'
import 'mint-ui/lib/style.css'
import "@/utils/tradeHub"
import "./permission"
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
Vue.use(Mint)
Vue.use(VueI18n)
Vue.use(VueClipboard)

/*多语言初始化-版本9不兼容，安装8 npm install vue-i18n@8*/
const i18n = new VueI18n({
	locale: 'en',
	messages: {
		'zh-Hans': require('./assets/lang/zh'),
		'zh-Hant': require('./assets/lang/zh_fan'),
		'en': require('./assets/lang/en'),
		'ja': require('./assets/lang/jp')
	}
})

new Vue({
	render: h => h(App),
	i18n,
	router,
	store,
}).$mount('#app')
