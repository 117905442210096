<template>
	<div class="coll">
		<v-header></v-header>
		<div class="banner">
			<img :src="collection.bannerImageUrl">
		</div>
		<div class="logo">
			<img :src="collection.logoImageUrl">
		</div>
		<div class="intro">
			<h1>{{collection.name}}</h1>
			<h2>{{$t('collection.by')}} <span>{{collection.creator}}</span></h2>
			<!-- <p>Rare Apepes is an homage to two of NFT culture's most iconic works.</p> -->
			<p>{{collection.description}}</p>
		</div>
		<div class="data">
			<div><p>{{collection.totalCount}}</p><span>{{$t('collection.items')}}</span></div>
			<div><p>3.1K</p><span>{{$t('collection.owners')}}</span></div>
			<div><p>{{collection.totalVolume}}</p><span>{{$t('collection.totalVolume')}}</span></div>
			<div><p>{{collection.floorPrice}} {{collection.paymentTokenId}}</p><span>{{$t('collection.floorPrice')}}</span></div>
			<div><p>{{collection.bestOffer || 0}} {{collection.paymentTokenId}}</p><span>{{$t('collection.bestOffer')}}</span></div>
		</div>
		<div class="items">
			<div class="tit">
				<span>{{$t('collection.items')}}</span>
			</div>
			<div class="filter">
				<div class="search">
					<span class="iconfont">&#xe615;</span>
					<input type="input" placeholder="Search by name" v-model="matchName" @input="matchFunc()">
				</div>
				<div class="btns">
					<mt-button @click="showFilters(true)">
						<i class="iconfont">&#xe61a;</i>&nbsp;&nbsp;{{$t('collection.filters')}}
					</mt-button>
					<span class="w10"></span>
					<mt-button  @click="showSort(true)">
						<i class="iconfont">&#xe6a8;</i>&nbsp;&nbsp;{{$t('collection.sort')}}
					</mt-button>
				</div>
			</div>
			<div class="list">
				<div class="item" @click="goNft(item.id)" v-for='item of nftArr' :key="item.id">
					<div class="img"><img :src="item.imageUrl"></div>
					<div class="detail">
						<h1>{{item.name}}</h1>
						<div class="price">
							<p>{{$t('collection.price')}}</p>
							<p class="num">{{item.offerPrice || '***'}} <em class="uppercase">{{collection.paymentTokenId}}</em></p>
						</div>
						<div class="last">
							{{$t('collection.lastSale')}}: {{item.lastSalePrice || 0}} {{collection.paymentTokenId}}
						</div>
					</div>
				</div>
				<!-- <div class="item">
					<div class="img"><img src="../assets/img/collection-item.png"></div>
					<div class="detail">
						<h1>Rare Apepe #5721</h1>
						<div class="price">
							<p>Price</p>
							<p class="num">71872 USDT</p>
						</div>
						<div class="last">
							Last sale: 12712 usdt
						</div>
					</div>
				</div>
				<div class="item">
					<div class="img"><img src="../assets/img/collection-item.png"></div>
					<div class="detail">
						<h1>Rare Apepe #5721</h1>
						<div class="price">
							<p>Price</p>
							<p class="num">71872 USDT</p>
						</div>
						<div class="last">
							Last sale: 12712 usdt
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="filters">
			<mt-popup
				v-model="filterVisible"
				position="bottom">
				<div class="status">
					<h1>Filters<span class="iconfont"></span></h1>
					<div class="tit">Status</div>
					<mt-checklist
						align="right"
						v-model="statusValue"
						:options="filterOptions">
					</mt-checklist>
					<div class="tit">Price</div>
					<mt-radio
						align="right"
						v-model="priceValue"
						:options="priceOptions">
					</mt-radio>
				</div>
				<div class="btns">
					<mt-button type="default" @click="clearFunc()">{{$t('collection.clearAll')}}</mt-button>
					<mt-button type="primary" @click="filtersDoneFunc()">{{$t('collection.done')}}</mt-button>
				</div>
			</mt-popup>
		</div>
		<div class="sort">
			<mt-popup
				v-model="sortVisible"
				position="bottom">
				<div class="status">
					<h1>Sort by<span class="iconfont"></span></h1>
					<mt-radio
						align="right"
						v-model="sortValue"
						:options="sortOptions">
					</mt-radio>
				</div>
				<div class="btns">
					<mt-button type="primary" @click="sortDoneFunc()" size="large">{{$t('collection.done')}}</mt-button>
				</div>
			</mt-popup>
		</div>
	</div>
</template>

<script>
import vHeader from '@/components/Header'
import { getCollectionByID, getAssetsByID } from '@/api/nft.js'
export default {
	name: 'Collection',
	components: {
		vHeader
	},
	data () {
		return {
			collection: {},//集合信息
			nftArr: [],//nft数组
			matchName: '',//搜索框匹配
			filterVisible: false,
			sortVisible: false,
			statusValue: [1,0], //1是售卖中，0是竞价中
			filterOptions: [
				{
					label: this.$t('collection.buyNow'),
					value: '1',
					disabled: false
				},
				{
					label: this.$t('collection.onAuction'),
					value: '0',
					disabled: false
				}
			],
			priceValue: "4",
			priceOptions: [
				{
					label: '<1k USDT',
					value: '1',
					disabled: false
				},
				{
					label: '<10k USDT',
					value: '2',
					disabled: false
				},
				{
					label: '<100k USDT',
					value: '3',
					disabled: false
				},
				{
					label: this.$t('collection.all'),
					value: '4',
					disabled: false
				}
			],
			sortValue: '1',
			sortOptions: [
				{
					label: this.$t('collection.pricelth'),
					value: '1',
					disabled: false
				},
				{
					label: this.$t('collection.pricehtl'),
					value: '2',
					disabled: false
				},
				{
					label: this.$t('collection.favorited'),
					value: '3',
					disabled: false
				}
			],
		}
	},
	methods: {
		showFilters(tag) {
			this.filterVisible = tag
		},
		showSort(tag) {
			this.sortVisible = tag
		},
		//查询集合信息
		fetchData(collectionId) {
			getCollectionByID(collectionId).then(data => {
				console.log(typeof(data))
				this.collection = data
				this.$store.commit('SET_COLLID', this.collection.id)
				this.$store.commit('SET_COLLNAME', this.collection.name)
				this.$store.commit('SET_TOKENSTANDARD', this.collection.tokenStandard)
				this.$store.commit('SET_BLOCKCHAIN', this.collection.blockchain)
				this.$store.commit('SET_FEE', this.collection.sellFee)
				this.$store.commit('SET_PAYMENTTOKENID', this.collection.paymentTokenId)
			})
		},
		//获取集合的全部元素
		getnftArr(collectionId) {
			let data = {
				collectionId,
				MaxResultCount: 100,
				sorting: 'CreateTime'
			}
			getAssetsByID(data).then(data => {
				//console.log(data)
				this.nftArr = data.items
				this.nftOrignArr = data.items
			})
		},
		//搜索框匹配
		matchFunc () {
			let tag = this.matchName.trim()
			if(tag=='') {
				this.nftArr = this.nftOrignArr.filter(item => {
					return item.name !== ''
				})
			} else {
				this.nftArr = this.nftOrignArr.filter(item => {
					return item.name.indexOf(tag) >= 0
				})
			}
		},
		//去到某个NFT图片
		goNft(id) {
			this.$router.push({
				path: '/nft',
				query: {
					id
				}
			})
		},
		//筛选Done
		filtersDoneFunc () {
			//状态筛选 status
			if(this.statusValue.length == 1 ) {
				if ( this.statusValue[0] == 1){ //售卖中
					this.nftArr = this.nftOrignArr.filter(item => {
						return item.onSale
					})
				}else { //竞价中 this.statusValue[0] == 0
					this.nftArr = this.nftOrignArr.filter(item => {
						return !item.onSale
					})
				}
			}
			else if(this.statusValue.length != 1 ) { //全选或者都不选
				this.statusValue = [1, 2]
				console.log('all!!!')
			}
			//价格筛选 price
			if(this.priceValue == 1) {
				this.nftArr = this.nftOrignArr.filter(item => {
					return item.offerPrice < 1000
				})
			}else if(this.priceValue == 2){
				this.nftArr = this.nftOrignArr.filter(item => {
					return item.offerPrice < 10000
				})
			}else if(this.priceValue == 3){
				this.nftArr = this.nftOrignArr.filter(item => {
					return item.offerPrice < 100000
				})
			}
			this.showFilters(false)
			console.log(this.statusValue)
			console.log(this.priceValue)
		},
		//排序
		sortDoneFunc () {
			if(this.sortValue == 1) {
				this.nftArr = this.nftOrignArr.sort((a, b) => {
					return a.offerPrice < b.offerPrice
				})
			}else if(this.sortValue == 2) {
				this.nftArr = this.nftOrignArr.sort((a, b) => {
					return a.offerPrice > b.offerPrice
				})
			}
			console.log(this.sortValue)
			console.log(this.nftArr)
			this.showSort(false)
		},
		//清除筛选
		clearFunc() {
			this.statusValue = [1, 2]
			this.priceValue == 4
			this.showFilters(false)
		}
	},
	mounted () {
		let collectionId = this.$route.query.id
		this.fetchData(collectionId)
		this.getnftArr(collectionId)
	}
}
</script>

<style scoped>
.coll {
	padding-top: 54px;
}
.banner {
	line-height: 0;
	height: 100px;
}
.banner img {
	height: 100px;
	width: 100%;
}
.logo {
	line-height: 0;
	margin-top: -30px;
	height: 76px;
	padding-left: 15px;
}
.logo img {
	width: 80px;
	border:3px #fff solid;
	border-radius: 10px;
} 
.intro {
	padding: 0 15px;
	margin-bottom: 20px;
}
.intro h1 {
	font-size: 22px;
	line-height: 52px;
}
.intro h2,.intro p {
	font-weight: normal;
	font-size: 14px;
}
.intro h2 span {
	font-weight: bold;
}
.intro p {
	margin-top: 20px;
}
.data {
	padding: 0 15px;
	overflow: hidden;
}
.data div {
	float: left;
	width: 33.3%;
	margin-bottom: 20px;
}
.data div p {
	font-size: 20px;
	font-weight: bold;
}
.data div span {
	font-size: 15px;
	color: #999;
}
.items {
	padding:0 15px 15px;
}
.items .tit {
	border-bottom: 2px #f1f1f1 solid;
}
.items .tit span{
	display: inline-block;
	border-bottom: 2px #666 solid;
	line-height: 40px;
	font-size: 17px;
	font-weight: bold;
}
.search {
	margin-top: 24px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid rgb(229, 232, 235);
	color: rgb(4, 17, 29);
	padding: 10px;
	height: 28px;
	font-size: 16px;
}
.search span {
	color: gray;
	margin-right: 10px;
}
.search input {
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	font-size: 17px;
	line-height: 26px;
	min-height: 26px;
}
.filter .btns {
	display: flex;
	margin-top: 10px;
}
.filter .btns button {
	height: 52px;
	background-color: transparent;
	border: none;
	outline: none;
	flex:1;
	color: #333;
	border-radius: 10px;
	border: 2px solid rgb(229, 232, 235);
	font-weight: 600;
	font-size: 16px;
}
.w10 {
	display: block;
	width: 10px;
}
.list {
	overflow: hidden;
	margin-top: 18px;
}
.list .item {
	width: 49%;
	float: left;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	box-shadow: #ddd 0px 1px 6px;
	overflow: hidden;
	box-sizing: border-box;
	margin-bottom: 10px;
}
.list .item:nth-child(2n) {
	margin-left: 1%;
}
.list .item:nth-child(2n+1) {
	margin-right: 1%;
}
.list .item img{
	margin: 5%;
	width: 90%;
}
.item .detail {
	padding: 12px;
	font-size: 14px;
}
.item .detail .price {
	margin-top: 10px;
	font-size: 12px;
	font-weight: bold;
}
.item .detail .price .num {
	font-size: 14px;
}
.item .detail .last {
	margin-top: 20px;
	color: #999;
	font-size: 13px;
}
.mint-popup-bottom {
	width: 100%;
}
.status .tit {
	padding: 15px 24px 0;
	font-weight: bold;
}
.status h1 {
	line-height: 60px;
	font-size: 20px;
	text-align: center;
}
.filters .btns, .sort .btns {
	padding: 10px;
	border-top: 1px #ddd solid;
}
.filters .btns button{
	width: 46%;
	margin: 5px 2%;
	line-height: 48px;
	height: 48px;
}
.uppercase {
	font-weight: bold !important;
}
</style>
