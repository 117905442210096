import { loginByUsername } from '@/api/login'
import { getUserInfo } from '@/api/user'
import { getToken, setToken, removeToken, getFreshToken, setFreshToken, removeFreshToken } from '@/utils/auth'
const user = {
	state: {
		id: 0,//用户ID
		email: '',
		country: '',
		inviteCode: '',
		name: '', //名称
		token: getToken(), //用户token
		freshToken: getFreshToken(),//刷新token
		hasAssetPassword: false,//是否设置了资金密码
		idConfirmed: false,//是否认证
		currency: '',//货币单位
		exchangeRateByUSD: 1,//兑换比例
	},
	mutations: {
		//设置token
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		//设置freshToken
		SET_FRESHTOKEN: (state, token) => {
			state.freshToken = token
		},
		//设置ID
		SET_ID: (state, id) => {
			state.id = id
		},
		//设置currency
		SET_CURRENCY: (state, currency) => {
			state.currency = currency
		},
		//设置兑换比例
		SET_RATEBYUSD: (state, exchangeRateByUSD) => {
			state.exchangeRateByUSD = exchangeRateByUSD
		},
		//设置是否认证
		SET_IDCONFIRMED: (state, tag) => {
			state.idConfirmed = tag
		},
		//设置是否设置了资金密码
		SET_HASASSETPASSWORD: (state, tag) => {
			state.hasAssetPassword = tag
		},
		//设置用户名
		SET_NAME: (state, name) => {
			state.name = name
			state.email = name
		},
		//设置国家
		SET_COUNTRY: (state, country) => {
			state.country = country
		},
		//设置邀请码
		SET_INVITECODE: (state, inviteCode) => {
			state.inviteCode = inviteCode
		},
		// 清空信息
		RESET: (state) => {
			state.id = '',//用户ID
			state.email = '',
			state.country = '',
			state.currency = '',
			state.inviteCode = '',
			state.name = '', //名称
			state.token = getToken(), //用户token
			state.freshToken = getFreshToken(), //用户freshtoken
			state.hasAssetPassword = false,//是否设置了资金密码
			state.idConfirmed = false//是否认证
		}
	},
	actions: {
		//用户名登陆
		LoginByUsername({ commit }, userInfo) {
			const username = userInfo.username.trim()
			return new Promise((resolve, reject) => {
				loginByUsername(username, userInfo.password).then(data => {
					commit('SET_TOKEN', data.access_token)
					commit('SET_FRESHTOKEN', data.refresh_token)
					setToken(data.access_token)
					setFreshToken(data.refresh_token)
					//console.log(data.access_token)
					resolve(data)
				}).catch(error => {
					//console.log(error)
					reject(error)
				})
			})
		},
		//获取用户信息
		GetUserInfo({ commit }) {
			return new Promise((resolve, reject) => {
				getUserInfo().then(data => {
					commit('SET_NAME', data.userName)
					commit('SET_ID', data.id)
					commit('SET_COUNTRY', data.country)
					commit('SET_CURRENCY', data.currency)
					commit('SET_INVITECODE', data.inviteCode)
					commit('SET_IDCONFIRMED', data.idConfirmed)
					commit('SET_HASASSETPASSWORD', data.hasAssetPassword)
					resolve(data)
				}).catch(error => {
					reject(error)
				})
			})
		},
		//退出登录
		LogOut ({ commit }) {
			return new Promise((resolve) => {
				commit('SET_TOKEN','')
				commit('SET_FRESHTOKEN','')
				commit('RESET')
				removeToken()
				removeFreshToken()
				resolve()
			})
		}
	}
}

export default user