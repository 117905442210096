<template>
	<div class="kline">
		<div class="tools">
			<ul class="times">
				<li v-for="item in timeList" :key="item.id" :class="timeId === item.id ? 'cho':''"
					@click="choiceTime(item)">
				{{item.label}}
				</li>
			</ul>
		</div>
		<div id="chart"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { init, dispose } from 'klinecharts'
import { config } from "./config.js"
//import generatedKLineDataList from './generatedKLineDataList'
import { getKlines, getlatestKline } from '@/api/kline.js'
export default {
	name: 'Kline',
	props: {
		latestMarket: {
			type: Object
		}
	},
	computed: {
		...mapGetters(['langId'])
	},
	data () {
		return {
			symbolId: '',//交易对ID
			dealInfo: {},//交易对信息
			timeList: [
				{label: '1M', id: '1min'},
				{label: '5M', id: '5min'},
				{label: '15M', id: '15min'},
				{label: '30M', id: '30min'},
				{label: '1H', id: '1hour'},
				{label: '1D', id: '1day'},
				{label: '1W', id: '1week'},
				{label: '1M', id: '1mon'}
			],//k线时间
			timeId: '5min',//当前选择的时间
			typeValue: 'kline',//图形类型
			curKlineDate: {},//当前正在走的一根k线数据
			latestOneData: {},//最新的一条数据
			klinecharts: null,//K线图实例
			klineData: [], //k线数据
			timer: null,//获取最新k线定时器
		}
	},
	watch: {
		//行情实时更新
		latestMarket (newVal) {
			// let len = this.klineData.length
			// if( len == 0) { //如果数据没有获取到则退出
			// 	return
			// }
			let last = {}//获取最后一个元素
			last.close = this.curKlineDate.close
			last.high = this.curKlineDate.high
			last.low = this.curKlineDate.low
			last.open = this.curKlineDate.open
			last.timestamp = this.curKlineDate.timestamp
			last.volume = this.curKlineDate.volume
			if(!last) { //如果最后一条数据没有获取到则退出
				return
			}
			last.close = newVal.latestPrice//更新最新值
			//当前价格大于最高价，则更新最高价
			if(newVal.latestPrice > last.high) {
				last.high = newVal.latestPrice
			}
			//当前价格小于最低价，则更新最低价
			if(newVal.latestPrice < last.low) {
				last.low = newVal.latestPrice
			}
			//更新视图
			this.klinecharts.updateData(last)
		}
	},
	async mounted () {
		this.symbolId = this.$route.query.id
		//获取历史走势
		//console.log('获取历史走势')
		await this.getKlinesFunc()
		//console.log('获取当前正在走的那一根k线的走势')
		this.getlatestKlineFunc()
		this.timer = setInterval(()=> {
			this.getlatestKlineFunc()
		}, 10000)
		//初始化k线
		this.klinecharts = init('chart')
		//设置配置
		this.klinecharts.setStyleOptions(config)
		//设置精度
		this.klinecharts.setPriceVolumePrecision(4,4)
		//成交量等指标
		this.setSubTechnicalIndicator(); 
		//设置语言
		this.setLanguage(this.langId);
	},
	methods: {
		//获取历史走势
		getKlinesFunc() {
			let data = {
				symbolId: this.symbolId,
				category: this.timeId
			}
			//获取历史k线数据
			getKlines(data).then(data => {
				//console.log(data)
				//console.log(this.klineData.length)
				let klineArr = data.items.reverse()
				klineArr.forEach(item => {
					this.klineData.push(
						{
							close: item.close, 
							high: item.highest, 
							low: item.lowest, 
							open: item.open, 
							timestamp: item.time, 
							volume: item.volume,
						}
					)
				})
				this.initData(); //初始化视图数据
			})
		},
		//获取当前正在走的那一根走势线
		getlatestKlineFunc() {
			getlatestKline(this.symbolId, this.timeId).then(data => {
				this.curKlineDate.close = data.close
				this.curKlineDate.high = data.highest
				this.curKlineDate.low = data.lowest
				this.curKlineDate.open = data.open
				this.curKlineDate.timestamp = data.time
				this.curKlineDate.volume = data.volume
			})
		},
		//初始化数据
		initData () {
			// 设置样式配置
			this.klinecharts.applyNewData(this.klineData);
		},
		//设置指标
		setSubTechnicalIndicator() {
			this.klinecharts.createTechnicalIndicator('MA', false, { id: 'candle_pane' })
			this.klinecharts.createTechnicalIndicator('VOL', false, {height: 60})
		},
		//清空数据
		clearDataFunc () {
			this.klineData = []
			this.curKlineDate = []
			this.klinecharts.clearData();
			clearInterval(this.timer)
		},
		//选择时间
		async choiceTime(timeInfo) {
			this.timeId = timeInfo.id;
			//清空数据
			await this.clearDataFunc()
			//重新获取数据
			this.getKlinesFunc()
			//console.log('--------------------获取当前正在走的那一根k线的走势--------------')
			this.getlatestKlineFunc()
			this.timer = setInterval(()=> {
				this.getlatestKlineFunc()
			},10000)
		},
		//语言
		setLanguage(language) {
			this.klinecharts.setStyleOptions(this.getLanguageOptions(language))
		},
		//根据语言设置显示
		getLanguageOptions (language) {
			return {
				candle: {
					tooltip: {
						labels: language === 'zh-Hans'
						? ['时间：', '开：', '收：', '高：', '低：', '成交量：']
						: ['T: ', 'O: ', 'C: ', 'H: ', 'L: ', 'V: ']
					}
				}
			}
		}
	},
	destroyed() {
		dispose('chart')
		clearInterval(this.timer)
	},
}
</script>

<style scoped>
.times {
	margin:5px 10px;
	_color: #4e5b85;
	color: #6c7eb7;
}
.times li {
	float: left;
	margin-right: 20px;
	font-size: 12px;
}
.times li.cho {
	color: #b0b8db;
	border-bottom: 2px #fff solid;
}
.kline {
	height: 432px;
	width: 100%;
}
#chart {
	height: 400px;
}
</style>
