<template>
	<div class="container">
		<div class="header">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			<i>|</i>
			<span>{{symbolName}}</span>
		</div>
		<div class="wrapper">
			<div class="price">
				<div class="pl">
					<h1 class="green">{{ market.latestPrice}}</h1>
					<p class="">≈ {{market.latestPrice * exchangeRateByUSD}} <span class="lowercase">{{currency}}</span> <span class="green">{{toRateFunc(market.change)}}</span></p>
				</div>
				<div class="pr">
					<div>
						<p>24H{{$t('trend.high')}}</p>
						<p class="amount">{{market.highestPrice}}</p>
					</div>
					<div>
						<p>24H{{$t('trend.volume')}}</p>
						<p class="amount">{{(market.volume/1000).toFixed(1) + 'K'}}</p>
					</div>
					<div>
						<p>24H{{$t('trend.low')}}</p>
						<p class="amount">{{market.lowestPrice}}</p>
					</div>
					<div>
						<p>24H{{$t('trend.turnover')}}</p>
						<p class="amount">{{((market.turnover / 1000000).toFixed(2) )+ 'M'}}</p>
					</div>
				</div>
			</div>
			<K-line :latestMarket="market"></K-line>
		</div>
		<div class="orders">
			<div class="orders-nav">
				<a :class="{'cho':orderType==1}" @click="orderTypeChange(1)">{{$t('trend.entrust')}}</a>
				<a :class="{'cho':orderType==2}"  @click="orderTypeChange(2)">{{$t('trend.deal')}}</a>
			</div>
			<!-- 委托挂单 -->
			<div class="list" v-show="orderType==1">
				<div class="tit">
					<div class="tl">{{$t('trend.buyList')}} {{$t('common.amount')}}({{symbolName.split('/USDT')[0]}})</div>
					<div class="tc">{{$t('common.price')}}(USDT)</div>
					<div class="tr">{{$t('common.amount')}}({{symbolName.split('/USDT')[0]}}) {{$t('trend.sellList')}}</div>
				</div>
				<div class="list-cnt">
					<div class="buy">
						<div class="item" v-for="(item, index) of buy" :key="index">
							<span class="num">{{index+1}}</span>
							<span class="amount">{{item.volume}}</span>
							<span class="price">{{item.price}}</span>
						</div>
						<!-- <div class="item">
							<span class="num">1</span>
							<span class="amount">40.0000</span>
							<span class="price">3.2187</span>
						</div> -->
					</div>
					<div class="sell">
						<div class="item" v-for="(item, index) of sell" :key="index">
							<span class="price">{{item.price}}</span>
							<span class="amount">{{item.volume}}</span>
							<span class="num">{{index+1}}</span>
						</div>
						<!-- <div class="item">
							<span class="price">78681.2187</span>
							<span class="amount">40.0000</span>
							<span class="num">1</span>
						</div> -->
					</div>
				</div>
				
			</div>
			<!-- 成交订单 -->
			<div class="list deal" v-show="orderType==2">
				<div class="tit">
					<div class="time">{{$t('common.time')}}</div>
					<div class="direction">{{$t('common.direction')}}</div>
					<div class="price">{{$t('common.price')}}(USDT)</div>
					<div class="amount tr">{{$t('common.amount')}}({{symbolName.split('/USDT')[0]}})</div>
				</div>
				<div class="list-cnt" v-for="(item,index) of orders" :key="index">
					<div class="item">
						<span class="time">{{getLocalTime(item.timestamp)}}</span>
						<span :class="[item.direction ==1 ? 'red':'green','direction']">{{item.direction == 1 ? $t('common.buy'):$t('common.sell')}}</span>
						<span class="price">{{item.price.toFixed(4)}}</span>
						<span class="amount tr">{{item.volume}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<div class="bot">
			<mt-button @click="trade(1)" class="buy" type="primary" size="small">{{$t('common.buy')}}</mt-button>
			<mt-button @click="trade(2)" class="sell" type="primary" size="small">{{$t('common.sell')}}</mt-button>
			<mt-button type="default" size="small" @click="toSecond()">{{$t('index.second')}}</mt-button>
			<span v-show="!favoriteTag" class="fr" @click="addFavorite('add')">
				<p><i class="iconfont">&#xe634;</i></p>
				<p>{{$t('trend.add')}}</p>
			</span>
			<span v-show="favoriteTag"  class="fr favorite" @click="addFavorite('remove')">
				<p><i class="iconfont">&#xe634;</i></p>
				<p>{{$t('trend.remove')}}</p>
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSymbolsDayMarket, addFavorite, removeFavorite, getFavorites } from '@/api/symbols.js'
import { timestampToTime, toRate }from "@/utils/third.js"
import KLine from "@/components/Kline/Kline.vue"

export default {
	name: 'Trend',
	components: {
		KLine
	},
	computed: {
		...mapGetters(['name','currency','exchangeRateByUSD'])
	},
	data () {
		return {
			symbolId: '', //交易对ID
			symbolName: '', //交易对名称
			market: {
				change: 0,
				highestPrice: 0,
				latestPrice: 0,
				lowestPrice: 0,
				turnover: 0,
				updateTime: 0,
				volume: 0
			}, //日行情
			buy: [], //盘口数据 -买盘
			sell: [], //盘口数据-卖盘
			orders: [], //成交订单列表
			orderType: 1,//委托订单1， 成交订单2
			favoriteTag: false,//是否自选
		}
	},
	methods: {
		//加自选
		addFavorite(tag) {
			if(tag == 'add') { //加自选
				addFavorite(this.symbolId).then(() => {
					this.$toast({
						message: this.$t('trend.addSuc'),
						duration: 1000
					})
					this.favoriteTag = true
				})
			}
			if(tag == 'remove') {
				removeFavorite(this.symbolId).then(() => {
					this.$toast({
						message: this.$t('trend.removeSuc'),
						duration: 1000
					})
					this.favoriteTag = false
				})
			}
		},
		//判断是否自选
		judgeFavorite() {
			if(name) {
				getFavorites().then(data => {
					//console.log("----data.items----")
					//console.log(data.items)
					//console.log(this.symbolId)
					let ind = (data.items).findIndex(item => {
						return item.symbolId == this.symbolId
					})
					if(ind > -1) {
						this.favoriteTag = true
					}else {
						this.favoriteTag = false
					}
				})
			}
		},
		//小数换成百分比
		toRateFunc (num) {
			return toRate(num)
		},
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS).slice(11) 
		},
		//买入direction:1 卖出direction:2
		trade(direction) {
			this.$router.push({
				path: '/trade',
				query: {
					id: this.symbolId,
					direction
				}
			})
		},
		//切换订单类型:委托订单1， 成交订单2
		orderTypeChange(type) {
			if(this.orderType == type) {
				return 
			}
			this.orderType = type
			if(type==2) {
				//订阅订单
				if(this.orders.length == 16) {
					this.subscribeTradeFunc(this.symbolId)
				}
			}
		},
		//到秒合约界面
		toSecond() {
			this.$router.push({
				path: '/second',
				query: {
					id: this.symbolId
				}
			})
		},
		//订阅盘口信息
		async subscribeOrderbookFunc(id) {
			//订阅盘口
			//console.log("---------订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id)
			//接收盘口更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderbookUpdatedAsync, (data) =>{
				//console.log("---------接收盘口更新------------")
				//console.log(data)
				//this.orderbook = data.orderbook
				this.buy = data.orderbook.buy
				this.sell = data.orderbook.sell.reverse()
				if(this.buy.length == 0) {
					this.buy = [{price:0, totalVolume:0, volume:0}]
				}
				if(this.sell.length == 0) {
					this.sell = [{price:0, totalVolume:0, volume:0}]
				}
				this.$set(this.buy, 0, this.buy[0])
				this.$set(this.sell, 0, this.sell[0])
			})
		},
		//订阅行情信息
		async subscribeMarketFunc(id) {
			//订阅行情
			//console.log("---------订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id])
			//接收行情更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolDayMarketChangedAsync, (data) =>{
				//console.log("---------接收行情更新------------")
				//console.log(data)
				this.market = data.market
			})
		},
		//订阅订单信息
		async subscribeTradeFunc(id) {
			//订单信息
			//console.log("---------订阅订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id])
			//接收订单更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderTradedAsync, (data) =>{
				//console.log("---------接收订单信息------------")
				this.orders.unshift(data)
				this.curPrice = this.orders[0].price.toFixed(4)
				this. market.latestPrice = this.orders[0].price.toFixed(4)
				if(this.orders.length>16) {
					this.orders.length = 16
					// if(this.orderType == 1) { //委托挂单界面，那么不再订阅订单信息，节约性能
					// 	this.unsubscribeTradeFunc(this.symbolId)
					// }
				}
				console.log(this.orders)
			})
		},
		//取消订阅盘口信息
		async unsubscribeOrderbookFunc(id) {
			//console.log("---------取消订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id, false)
		},
		//取消订阅行情信息
		async unsubscribeMarketFunc(id) {
			//console.log("---------取消订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id], false)
		},
		//取消订阅订单信息
		async unsubscribeTradeFunc(id) {
			//console.log("---------取消订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id], false)
		},
	},
	async mounted () {
		//判断当前币种是否自选
		this.judgeFavorite()
		this.symbolId = this.$route.query.id
		this.symbolName = this.$route.query.name
		//获取初始行情
		getSymbolsDayMarket({symbols:this.symbolId}).then(data => {
			//console.log("获取交易对初始行情数据")
			//console.log(data)
			this.market = data.items[0].market
		})
		//订阅行情
		await this.subscribeMarketFunc(this.symbolId)
		//订阅盘口
		await this.subscribeOrderbookFunc(this.symbolId)
		//订阅订单
		await this.subscribeTradeFunc(this.symbolId)
	},
	//离开组件执行
	beforeDestroy() {
		//console.log('destroyed')
		this.unsubscribeOrderbookFunc(this.symbolId)
		this.unsubscribeMarketFunc(this.symbolId)
		this.unsubscribeTradeFunc(this.symbolId)
	}
}
</script>

<style scoped>
.container {
	_background-color: #1a243b;
	background-color: #29395c;
	height: 100%;
	overflow-y: scroll;
}
.header {
	position: fixed;
	z-index: 10;
	top: 0;
	width: 100%;
	height: 44px;
	line-height:44px;
	padding: 0 10px;
	color: #fff;
	background-color: #29395c;
}
.header i {
	margin: 0 10px;
}
.wrapper {
	margin-top:44px;
}
.price {
	display: flex;
}
.price div.pl {
	flex: 1;
}
.price div.pr {
	width: 150px;
	font-size: 12px;
	color: #6c7eb7;
}
.price div.pr p.amount{
	color: #b0b8db;
}
.price div.pl {
	padding: 20px 10px;
	font-size:20px
}
.price div.pl p {
	font-size:12px;
	line-height:26px;
	color:#b0b8db;
}
.price div.pl p span {
	margin-left: 5px
}
.price div.pr div {
	box-sizing: border-box;
	float: left;
	margin: 0 0 6px 0;
}
.price div.pr div:nth-child(2n+1) {
	width:40%;
	line-height: 18px;
}
.price div.pr div:nth-child(2n) {
	width:60%;
	line-height: 18px; 
}
.orders {
	border-top: 6px #202e4a solid;
	color: #fff;
	padding: 10px;
	font-size: 12px;
	_margin-bottom: 50px;
}
.orders-nav {
	border-bottom: 1px #334670 solid;
}
.orders-nav a {
	color: #fff;
	display: inline-block;
	width: 80px;
	text-align: center;
	line-height: 32px;
}
.orders-nav a.cho {
	color: #b0b8db;
	border-bottom: 2px #b0b8db solid;
}
.list {
	padding: 10px;
}
.list .tit {
	height: 36px;
	line-height: 36px;
	display: flex;
}
.list .tit div {
	flex: 1;
	font-size: 12px;
	color: #b0b8db;
}
.list-cnt {
	display: flex;
}
.list-cnt>div {
	flex: 1;
}
.list-cnt .item {
	display: flex;
	line-height: 40px;
}
.list-cnt .buy {
	border-right: 1px #31446c solid;
}
.list-cnt .item span {
	display: inline-block;
}
.list-cnt .item span.num{ 
	width: 20px;
	color: #b0b8db;
}
.list-cnt .item span.amount{
	flex: 1;
}
.list-cnt .item span.price{
	width: 75px;
}
.deal .list-cnt span {
	flex: 1;
}
.deal .item {
	line-height: 30px;
}
.time {
	width: 80px;
	flex: unset !important;
}
.direction {
	width: 90px;
	flex: unset !important;
}
.buy span.price {
	color: #03ad8f;
	padding-right: 10px;
	text-align: right;
}
.sell span.price {
	color: #d14b64;
	padding-left: 10px;
}
.bot {
	color: #fff;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 8px;
	background-color: #1b2c52;
}
.bot {
	font-size: 13px;
}
.bot button{
	border-radius: 0 !important;
	margin-right: 10px;
	float: left;
}
.bot button.buy {
	background-color: #03ad8f;
}
.bot button.sell {
	background-color: #d14b64;
}
.mint-button--small {
    padding: 0 26px;
}
.bot span {
	width: 80px;
	text-align: center;
	font-size: 12px;
}
.bot span p {
	line-height: 14px;
}
.favorite i {
	color: #03ad8f;
}
</style>
