<template>
	<div class="container bgwhite">
		<div class="headerNav">
			<img :src="sitelogo">
		</div>
		<div class="wrapper">
			<div class="topNav">
				<router-link :to="'/trade?id='+curSymbol.id">{{$t('trade.coin')}}</router-link>
				<router-link :to="'/second?id='+curSymbol.id" class="active">{{$t('trade.second')}}</router-link>
			</div>
			<div class="trade-symbol">
				<p>
					<i class="iconfont">&#xe684;</i>
					<span @click="showSymbols()">{{curSymbol.name}} / USDT</span>
					<span class="actions fr">
						<i v-show="!favoriteTag" class="iconfont" @click="addFavorite('add')">&#xe634;</i>
						<i v-show="favoriteTag" class="iconfont active" @click="addFavorite('remove')">&#xe634;</i>
						<i class="iconfont" @click="toTrend()">&#xe631;</i>
					</span>
				</p>
			</div>
			<div class="trade-panel bgc">
				<div class="trade-panel-title">
				</div>
				<div class="trade-panel-content">
					<div class="trade-panel-form">
						<div class="stit">{{$t('second.dealAmount')}}(USDT)</div>
						<div class="input-box">
							<input type="number" :placeholder="$t('second.dealAmount')" v-model="volume">
						</div>
						<div class="list">
							<a 
							:class="{'cho':item == volume}" 
							v-for="(item,index) of volumeArr" 
							:key="index"
							@click="volumeClick(item)"
							>{{item}}</a>
						</div>
						<div class="stit mt10">{{$t('second.time')}}</div>
						<div class="list ltime">
							<!-- <a class="cho">60s<p>收益10%</p></a>
							<a>120s<p>收益10%</p></a>
							<a>180s<p>收益10%</p></a>
							<a>240s<p>收益10%</p></a>
							<a>300s<p>收益10%</p></a> -->
							<a 
							v-for="item of curSecondContract.odds" 
							:key="item.id"
							:class="{'cho':curOdds.id == item.id}"
							@click="chooseOdds(item.id)"
							>
								{{item.seconds}}s<p>{{$t('second.odds')}}{{parseInt(item.odds * 100) + '%'}}</p>
							</a>
						</div>
						<div class="red small">({{$t('second.minVolume')}} {{curOdds.minVolume}} usdt)</div>
						<div class="total">
							<span>{{$t('second.available')}} <em class="green">{{usdtAsset.balance}}</em> <em>USDT</em></span>
						</div>
						<button @click="showOrder(1)" class="bggreen submit">{{$t('second.doLong')}}</button>
						<button @click="showOrder(2)" class="bgred submit fr">{{$t('second.doShort')}}</button>
						<button @click="login()" v-show="!name" class="login submit">{{$t('common.login')}}</button>
					</div>
					<div class="handicap">
						<div class="handicap-title">
							<span class="index">{{$t('trade.handicap')}}</span>
							<span class="price">{{$t('trade.price')}}</span>
							<span class="amount fr">{{$t('trade.amount')}}</span>
						</div>
						<div class="handicap-sell">
							<dl @click="choPrice(order.price)" v-for="(order,index) of sell.slice(0, 5).reverse()" :key="index">
								<dt class="fl">{{index+1}}</dt>
								<dd class="fl">{{order.price}}</dd>
								<dd class="fr">{{order.totalVolume}}</dd>
							</dl>
						</div>
						<div class="handicap-price color-down">
							<em id="nowprice">{{market.latestPrice}}</em> 
							<b class="rate">{{toRateFunc(market.change)}}</b>
							<span>≈<b id="now-price-equivalent">{{(market.latestPrice * exchangeRateByUSD).toFixed(2)}} <i>{{currency}}</i></b></span>
						</div>
						<div class="handicap-buy">
							<dl v-for="(order,index) of buy.slice(0, 5)" :key="index">
								<dt class="fl">{{index+1}}</dt>
								<dd class="fl">{{order.price}}</dd>
								<dd class="fr">{{order.totalVolume}}</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
			<div class="trade-order">
				<div class="trade-order-title">
					<span @click="changeOrderType(1)" :class="{'cur':orderType==1}">{{$t('trade.curEntrust')}}</span>
					<span @click="changeOrderType(2)" :class="{'cur':orderType==2}">{{$t('trade.historyEntrust')}}</span>
				</div>
				<div class="list">
					<div class="item" v-for="order of curOrders" :key="order.id">
						<div class="name">{{$t('second.category')}}：{{curSymbol.name}}<span class="fr time">{{getLocalTime(order.creationTime)}}</span> 
						({{order.closedTime-order.creationTime}}s) ({{$t('second.odds')}} {{parseInt(order.odds*100) + '%'}})
						</div>
						<div>{{$t('second.openPrice')}}：<span>{{order.openPrice}}</span></div>
						<div>{{$t('second.endPrice')}}：<span v-if="order.status.id == 1" :class="[market.latestPrice > order.openPrice ? 'green' : 'red']">{{market.latestPrice}}
							<em class="iconfont" v-show="market.latestPrice > order.openPrice"> &#xe610;</em>
							<em class="iconfont" v-show="market.latestPrice < order.openPrice"> &#xe611;</em>
						</span>
							<span v-if="order.status.id == 2" :class="[order.closePrice > order.openPrice ? 'green' : 'red']">{{order.closePrice}}
							<em class="iconfont" v-show="order.closePrice > order.openPrice">  &#xe610;</em>
							<em class="iconfont" v-show="order.closePrice < order.openPrice"> &#xe611;</em>
							</span>
							<span v-if="order.status.id == 3">--</span>
							
						</div>
						<div>{{$t('second.dealAmount')}}：{{order.cost}} (usdt)</div>
						<div>{{$t('second.dealDirection')}}：<span :class="order.direction.id + 1 | orderStatusColor">{{order.direction.id == 1 ? $t('second.doLong') :$t('second.doShort')}}</span></div>
						<div class="status"
						:class="order.status.id | orderStatusColor">
							<div :class="{'win':order.earning > 0}">
							{{orderStatus(order.status.id)}} {{order.time}}<span v-show="order.time"> s</span>
							<em v-show="order.earning > 0"> ({{$t('second.profit')}}：{{order.earning}} usdt)</em>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-footer curState="trade"></v-footer>
		<mt-popup
			v-model="symbolsTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="symbolsOptions" value-key='name' @change="selectSymbol"></mt-picker>
		</mt-popup>
		<mt-popup
			v-model="orderTag"
			position="bottom"
			modal="false">
			<div class="orderInfo">
				<h1>{{$t('common.orderDetail')}}</h1>
				<p>{{$t('second.dealCategory')}}：{{curSymbol.name}}</p>
				<!-- 市价买入，数量是USDT数量，需要转换成币种数量 -->
				<p>{{$t('second.dealAmount')}}：<span>{{volume}} (USDT)</span></p>
				<p>{{$t('second.dealTime')}}：{{curOdds.seconds}} s <span class="green">({{$t('second.profit')}}: {{parseInt(curOdds.odds * 100) + '%'}})</span></p>
				<p>{{$t('second.dealDirection')}}：<span>{{direction==1 ? $t('second.doLong') : $t('second.doShort')}}</span></p>
				<div class="btns">
					<mt-button @click="coinTrade()" type="primary">{{$t('second.sure')}}</mt-button>
					<mt-button @click="cancelTrade()" type="default">{{$t('second.cancel')}}</mt-button>
				</div>
			</div>
		</mt-popup>
	</div>
	
</template>

<script>
import { mapGetters } from 'vuex'
import { addFavorite, removeFavorite, getFavorites } from '@/api/symbols.js'
import { timestampToTime, toRate } from "@/utils/third.js"
import { getCoinAccounts } from "@/api/finance.js"
import { getSecondContracts, getSecondContractByID, secondContractTrade, getSecondContractRecords } from '@/api/second.js'
import { getServerTime } from "@/api/setting.js"
import vFooter from '@/components/Footer'
export default {
	name: 'Second',
	components: {
		vFooter,
	},
	computed: {
		...mapGetters(['name','sitelogo','exchangeRateByUSD','currency','idConfirmed'])
	},
	filters: {
		// orderStatus (orderStatusId, vueThis) {
		// 	switch (orderStatusId) {
		// 		case 1: return vueThis.$t('second.orderStatus1');
		// 		case 2: return vueThis.$t('second.orderStatus2');
		// 		case 3: return vueThis.$t('second.orderStatus3');
		// 	}
		// },
		orderStatusColor (orderStatusId) {
			switch (orderStatusId) {
				case 1: return 'blue';
				case 2: return 'green';
				case 3: return 'red';
			}
		}
	},
	data () {
		return {
			//vueThis: this,
			switchBtn: true, //购买按钮
			volumeArr: [100, 200, 500, 1000, 5000, 10000],//数量数组
			volume: 10,//数量
			curSecondContract: {},//当前秒合约信息
			curOdds: {},//当前选择的时间赔率
			direction: 0, //买涨还是买跌
			disTime: 0,//服务端和本地时间戳的差
			symbolsTag: false, //交易对弹窗
			orderTag: false,//订单详情弹窗
			curSymbol: {},// 当前交易对
			symbols: [] , //交易对
			timers: {},//保存所有的定时器对象
			symbolsOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}],
			buy: [{}], //盘口数据 -买盘
			sell: [{}], //盘口数据-卖盘
			orders: [], //订单列表
			orderType: 1,//订单类型，当前委托 1，历史委托 2
			curOrders: [],//当前筛选的订单
			market: {
				change: 0,
				highestPrice: 0,
				latestPrice: 0,
				lowestPrice: 0,
				turnover: 0,
				updateTime: 0,
				volume: 0
			},//行情数据
			assets: [], //用户资产
			curAsset: {},//当前币种资产
			usdtAsset: {},//usdt资产
			ratio: 0,// 投资仓位比例
			first: 1, //第一次进入页面
			favoriteTag: false,//是否自选
		}
	},
	methods: {
		orderStatus (orderStatusId) {
			switch (orderStatusId) {
				case 1: return this.$t('second.orderStatus1');
				case 2: return this.$t('second.orderStatus2');
				case 3: return this.$t('second.orderStatus3');
			}
		},
		//加自选
		addFavorite(tag) {
			if(tag == 'add') { //加自选
				addFavorite(this.curSymbol.id).then(() => {
					this.$toast({
						message: this.$t('trade.addFavoriteSuc'),
						duration: 1000
					})
					this.favoriteTag = true
				})
			}
			if(tag == 'remove') {
				removeFavorite(this.curSymbol.id).then(() => {
					this.$toast({
						message: this.$t('trade.removeFavoriteSuc'),
						duration: 1000
					})
					this.favoriteTag = false
				})
			}
		},
		//判断是否自选
		judgeFavorite() {
			if(this.name) { //判断是登录状态
				getFavorites().then(data => {
					let ind = data.items.findIndex(item => {
						return item.symbolId == this.curSymbol.id
					})
					if(ind > -1) {
						this.favoriteTag = true
					}else {
						this.favoriteTag = false
					}
				})
			}
		},
		//去登录页面
		login() {
			this.$router.push({
				path: '/login',
				query: {
				}
			})
		},
		//去到走势页面
		toTrend() {
			this.$router.push({
				path: '/trend',
				query: {
					id: this.curSymbol.id,
					name: this.curSymbol.name
				}
			})
		},
		//小数换成百分比
		toRateFunc (num) {
			return toRate(num)
		},
		//显示所有交易对
		showSymbols () {
			this.symbolsTag = !this.symbolsTag
		},
		//资产筛选
		assetFilter(baseCoinId) {
			return this.assets.filter(item => {
				return item.coinId == baseCoinId
			})[0]
		},
		//订单详情
		showOrder(direction) {
			//没有登录
			if(!this.name) {
				this.$toast({
					message: this.$t('common.loginFirst'),
					duration: 1000
				})
				return
			}
			//没有认证
			if(!this.idConfirmed) {
				this.$messagebox({
					title: this.$t('common.confirmFirst'),
					message: this.$t('common.confirmNow'),
					confirmButtonText: this.$t('common.verifyNow'),
					cancelButtonText: this.$t('common.wait'),
					showCancelButton: true
				}).then(action => {
					if(action == 'confirm') {
						this.$router.push('/identity')
					} else {
						//console.log('取消')
					}
				})
				return
			}
			//下注金额小于最小金额限制
			if(this.curOdds.minVolume !== null && this.volume < this.curOdds.minVolume) {
				this.$toast({
					message: this.$t('second.minVolume') + this.curOdds.minVolume,
					duration: 1000
				})
				return
			}
			if(this.curOdds.maxVolume !== null && this.volume > this.curOdds.maxVolume) {
				this.$toast({
					message: this.$t('second.maxVolume') + this.curOdds.minVolume,
					duration: 1000
				})
				return
			}
			//余额不足
			if(this.volume > this.usdtAsset) {
				this.$toast({
					message: this.$t('common.insufficient'),
					duration: 1000
				})
				return
			}
			this.direction = direction
			this.orderTag = true
		},
		//选择交易对
		async selectSymbol (picker, values){
			if(!this.symbolsTag) {
				return
			}
			//alert(this.symbolsOptions[0].defaultIndex)
			//第一次进入页面，并且不是默认第一个picker(defaultIndex =0)默认值，则跳过一次执行，赋值之后会再次执行
			// if(this.first && this.symbolsOptions[0].defaultIndex != 0) {
			// 	this.first = 0
			// 	return
			// }
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			//订阅盘口和行情
			if(this.curSymbol.id) {
				this.judgeFavorite()
				//console.log('-------selectSymbol-----')
				//console.log(this.curSymbol.name)
				//切换交易对先取消订阅盘口取消订阅行情
				await this.unsubscribeOrderbookFunc(this.curSymbol.id)
				await this.unsubscribeMarketFunc(this.curSymbol.id)
				await this.unsubscribeTradeFunc(this.curSymbol.id)
				this.market.latestPrice = 0
				//设置当前交易对为选中的交易对
				//console.log(picker.getValues()[0])
				if(picker.getValues()[0]) {
					this.curSymbol = picker.getValues()[0]
				}
				//console.log('-------selectSymbol2-----')
				//console.log(this.curSymbol.name)
				//订阅盘口 订阅行情
				await this.subscribeMarketFunc(this.curSymbol.id)
				await this.subscribeOrderbookFunc(this.curSymbol.id)
				await this.subscribeTradeFunc(this.curSymbol.id)
				//获取当前币种秒合约信息
				this.getSecondContractInfo(this.curSymbol.id)
				//加载订单
				//alert('getOrdersFunc')
				if(this.name) { //判断是登录状态
					this.getOrdersFunc()
				}
			}
			
		},
		//获取秒合约的信息
		getSecondContractInfo(id) {
			getSecondContractByID(id).then(data => {
				//console.log("-------------获取秒合约的信息------------")
				//console.log(data)
				this.curSecondContract = data
				//初始化当前的时间赔率
				this.curOdds = this.curSecondContract.odds[0]
				this.volume = this.curOdds.minVolume
			})
		},
		//选择时间赔率
		chooseOdds(id){
			//console.log(id)
			this.curOdds = this.curSecondContract.odds.filter(item => {
				return item.id == id
			})[0]
			this.volume = this.curOdds.minVolume
		},
		//选择开仓数量
		volumeClick (volume) {
			this.volume = volume
		},
		//为当前进行中的订单开启定时器
		openInterview (orderId, endTime, ind) {
			//alert(orderId)
			//alert("开启定时器"+ind)
			//console.log(orderId, endTime)
			//alert(ind)
			this.timers[orderId] = setInterval(() => {
				let curTime = parseInt(new Date().getTime()/1000) + this.disTime//算出当前服务器端时间
				//截止时间-当前时间,算出距离截止的秒数
				//console.log(endTime)
				//console.log(curTime)
				let time = (endTime - curTime)//需要显示的倒计时时间
				//console.log(time)
				if(time <= 0) {
					time = 0
					clearInterval(this.timers[orderId])
					//更新订单状态
					this.getOrdersFunc()
				}
				//更新订单秒数属性
				//this.curOrders[ind].time = time
				this.$set(this.curOrders[ind], 'time', time)
				//console.log(this.curOrders)
			}, 1000)
		},
		//清除所有的定时器
		clearAllTimer () {
			for (var each in this.timers) {
				clearInterval(this.timers[each])
			}
		},
		//取消订单
		cancelTrade () {
			this.orderTag = false
		},
		//币币交易，买入卖出
		coinTrade() {
			if(!this.switchBtn) {
				this.$toast({
					message: this.$t('common.submiting'),
					duration: 500
				})
				return
			}
			this.orderTag = false
			this.switchBtn = false
			this.$toast({
				message:  this.$t('second.being'),
				duration: 1000
			})
			let data = {
				"contractId": this.curSymbol.id,
				"oddsId": this.curOdds.id,
				"direction": this.direction,
				"volume": this.volume
			}
			secondContractTrade(data).then(data => {
				console.log(data)
				//获取全部订单
				this.getOrdersFunc()
				this.switchBtn = true
				this.$toast({
					message:  this.$t('common.orderSuc'),
					duration: 1000
				})
			}).catch(err => {
				console.log(err)
				this.switchBtn = true
				this.$toast({
					message: this.$t('second.fail'),
					duration: 1000
				})
			})
		},
		//获取订单
		getOrdersFunc () {
			//获取订单前先清除所有的定时器
			this.clearAllTimer()
			let params = {
				"ContractId": this.curSymbol.id,
				"MaxResultCount": 50
			}
			getSecondContractRecords(params).then(data => {
				//console.log(data)
				this.orders = data.items
				//最近订单
				// if(this.orderType == 1) {
				// 	this.curOrders = this.orders.slice(0, 10)
				// } else {
				// 	this.curOrders = [].concat(this.orders)
				// }
				this.curOrders = this.orders.filter((item) => {
					if(this.orderType == 1) {
						return item.status.id == this.orderType
					} else {
						return item.status.id > 1
					}
				})
				//console.log("为正在进行中的订单开启定时器")
				this.curOrders.forEach((item, index) => {
					if(item.status.id == 1) {
						this.openInterview(item.id, item.closedTime, index)
					}
				})
			})
		},
		//切换订单类型
		changeOrderType(type) {
			this.orderType = type
			// if(this.orderType == 1) {
			// 	this.curOrders = this.orders.slice(0, 10)
			// } else {
			// 	this.curOrders = [].concat(this.orders)
			// }
			// this.curOrders = this.orders.filter((item) => {
			// 	if(this.orderType == 1) {
			// 		return item.status.id == this.orderType
			// 	} else {
			// 		return item.status.id > 1
			// 	}
			// })
			// console.log(this.curOrders)
			this.getOrdersFunc()
		},
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
		//订阅盘口信息
		async subscribeOrderbookFunc(id) {
			//订阅盘口
			//console.log("---------订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id)
			//接收盘口更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderbookUpdatedAsync, (data) =>{
				//console.log("---------接收盘口更新------------")
				//console.log(data)
				//this.orderbook = data.orderbook
				this.buy = data.orderbook.buy
				this.sell = data.orderbook.sell.reverse()
				if(this.buy.length == 0) {
					this.buy = [{price:0, totalVolume:0, volume:0}]
				}
				if(this.sell.length == 0) {
					this.sell = [{price:0, totalVolume:0, volume:0}]
				}
				this.$set(this.buy, 0, this.buy[0])
				this.$set(this.sell, 0, this.sell[0])
				if(this.market.latestPrice == 0) {
					this.market.latestPrice = this.buy[0].price
				}
			})
		},
		//订阅行情信息
		async subscribeMarketFunc(id) {
			//订阅行情
			//console.log("---------订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id])
			//接收行情更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolDayMarketChangedAsync, (data) =>{
				console.log("---------接收行情更新------------")
				console.log(data)
				this.market = data.market
			})
		},
		//订阅订单信息
		async subscribeTradeFunc(id) {
			//订阅行情
			//console.log("---------订阅订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id])
			//接收订单更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderTradedAsync, (data) =>{
				//console.log("---------接收订单信息------------")
				//console.log(this.curSymbol)
				let quoteCoinDecimals = this.curSecondContract.quoteCoinDecimals
				this.market.latestPrice = data.price.toFixed(quoteCoinDecimals)
				//this.market.latestPrice = data.price.toFixed(4)
				// if(this.orders.length>16) {
				// 	this.orders.length = 16
				// 	// if(this.orderType == 1) { //委托挂单界面，那么不再订阅订单信息，节约性能
				// 	// 	this.unsubscribeTradeFunc(this.symbolId)
				// 	// }
				// }
				// console.log(this.orders)
			})
		},
		//取消订阅盘口信息
		async unsubscribeOrderbookFunc(id) {
			//console.log("---------取消订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id, false)
		},
		//取消订阅行情信息
		async unsubscribeMarketFunc(id) {
			//console.log("---------取消订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id], false)
		},
		//取消订阅订单信息
		async unsubscribeTradeFunc(id) {
			//console.log("---------取消订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id], false)
		},
		
	},
	activated () {
		//获取服务端时间
		getServerTime().then(data => {
			let localTime = parseInt(new Date().getTime()/1000)
			let serverTime = data.timestamp
			this.disTime = serverTime - localTime
			//console.log(this.disTime)
		})
		//获取秒合约
		getSecondContracts().then(async (data) => {
			//通过地址栏获取交易对ID
			let tmpId = this.$route.query.id
			//console.log('------------------')
			//console.log(data)
			this.symbols = data.items
			if(tmpId && tmpId !== 'undefined') { //如果地址栏有交易对ID，则根据id选择出当前的交易对信息
				this.curSymbol = this.symbols.filter(item => {
					return item.id == tmpId
				})[0]
				if(!this.curSymbol) { //当前币种没有秒合约
					this.curSymbol = this.symbols[0]
					this.$toast({
						message: this.$t('second.noSupport'),
						duration: 2000
					})
				}
			} else { //如果地址栏没有交易对ID则默认第一个交易对信息
				this.curSymbol = this.symbols[0]
			}
			//设置picker的values
			this.symbolsOptions[0].values = this.symbols
			//当前交易对赋值给picker默认选项
			let ind = this.symbols.findIndex(item => {
				return item.id ==this.curSymbol.id
			})
			//console.log("--------this.symbolsOptions-----------")
			//console.log(this.symbolsOptions[0])
			//console.log(ind)
			this.symbolsOptions[0].defaultIndex = ind
			//订阅行情和盘口
			await this.subscribeOrderbookFunc(this.curSymbol.id)
			await this.subscribeMarketFunc(this.curSymbol.id)
			await this.subscribeTradeFunc(this.curSymbol.id)
			//获取当前币种秒合约信息
			this.getSecondContractInfo(this.curSymbol.id)
			
			//获取用户资产
			if(this.name) { //判断是登录状态
				getCoinAccounts().then(data => {
					this.assets = data.items
					this.usdtAsset = this.assetFilter('usdt')
				})
				this.getOrdersFunc()
			}
		})
		//this.symbolsOptions[0].defaultIndex = 1
		//获取全部订单
		//this.getOrdersFunc()
		//获取用户资产
		// if(this.name) { //判断是登录状态
		// 	getCoinAccounts().then(data => {
		// 		this.assets = data.items
		// 		this.usdtAsset = this.assetFilter('usdt')
		// 	})
		// }
	},
	//离开组件执行								
	deactivated() {
		//console.log('destroyed')
		this.unsubscribeOrderbookFunc(this.curSymbol.id)
		this.unsubscribeMarketFunc(this.curSymbol.id)
		this.unsubscribeTradeFunc(this.curSymbol.id)
	}
}
</script>

<style scoped>
.wrapper {
	padding-top: 54px;
	padding-bottom: 60px;
}
.headerNav {
	position: fixed;
	top: 0;
	left: 0;
	height: 54px;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid #e7ebee;
	padding-left: 15px;
	padding-right: 20px;
	z-index: 1;
	background-color: #fff;
}
.headerNav img {
	height: 30px;
	margin-top: 15px;
}
.topNav {
	padding: 0 20px;
	border-bottom: 1px solid #eff2f4;
}
.topNav a {
	color: #8c9fad;
	display: inline;
	font-size: 22px;
	font-weight: 600;
	line-height: 56px;
}
.topNav a.active {
	color: #333;
}
.topNav a.active {
	
}
.topNav a+a {
	margin-left: 30px;
}
.trade-symbol {
	padding-left: 15px;
	height: 50px;
	font-size: 16px;
	font-weight: 600;
	line-height: 50px;
	position: relative;
}
.trade-symbol p {
	margin-right: 20px;
}
.actions {
}
.actions i {
	margin-left: 10px;
	font-size: 18px;
}
.actions i:first-child {
	color: #d8d8d8;
}
.actions i.active {
	color: #333;
}
.trade-panel-title {
	height: 46px;
	padding-left: 15px;
	width: 200px;
	padding-top: 10px;
}
.trade-panel-title button {
	font-size: 20px;
	height: 40px;
	line-height: 46px;
	margin-right: 25px;
	padding-bottom: 10px;
	border-bottom: 2px solid transparent;
	font-weight: 700;
	float: left;
}
.trade-panel-title button.cur:nth-child(1) {
	border-bottom-color: #03ad8f;
}
.trade-panel-title button.cur:nth-child(2) {
	border-bottom-color: #d14b64;
}
.trade-panel-content {
	padding-left: 15px;
	border-bottom: 20px solid #f7f7fb;
	padding-bottom: 20px;
	box-sizing: border-box;
	display: flex;
	width: 100%;
}
.trade-panel-content:after {
	display: block;
	visibility: hidden;
	height: 0;
	clear: both;
	content: ".";
}
.trade-panel-form {
	margin-top: -40px;
	width: 200px;
	padding-top: 10px;
}
.trade-panel-form .input-box input {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 36px;
	border: 1px solid #c8cfd5;
	font-size: 17px;
	font-weight: 600;
	padding-left: 12px;
	padding-right: 12px;
}
.select-box {
	position: relative;
	float: right;
	white-space: nowrap;
}
.select-box select {
	border: none;
	outline: none;
	background: 0 0;
	-webkit-appearance: none;
	color: rgba(31,63,89,.5);
	font-size: 14px;
	line-height: 28px;
	padding-right: 30px;
	width: 100%;
	padding: 10px 20px 10px 0;
}
.arrow {
	top: -3px;
	left:-15px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}
.arrow:before {
	content: "";
	position: absolute;
}
.arrow:before {
	top: 0;
	left: 0;
	border-top: 6px solid #c5cfd5;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}
.equivalent {
	color: #c5cfd5;
	font-size: 10px;
	padding-top: 4px;
	box-sizing: border-box;
	height: 32px;
}
.btn-group {
	border: 1px solid #c8cfd5;
	height: 30px;
	box-sizing: border-box;
}
.btn-group button {
	color: #8c9fad;
	width: 65px;
	height: 28px;
	line-height: 28px;
	font-size: 12px;
	float: left;
}
.btn-group button.cho {
	background-color: #ffcd36;
	color: #000;
}
.btn-group span {
	float: left;
	width: 1px;
	height: 16px;
	margin-top: 6px;
	background: #e7ebee;
}
.total {
	white-space: nowrap;
	height: 30px;
	padding-top: 6px;
	color: #8c9fad;
	font-size: 13px;
}
/* .trade-panel-form .submit {
	width: 200px;
	height: 46px;
	font-size: 16px;
	line-height: 46px;
	font-weight: 700;
	color: #fff;
} */
.trade-panel-form .submit {
	width: 95px;
	height: 46px;
	font-size: 16px;
	line-height: 46px;
	font-weight: 700;
	color: #fff;
}
.login {
	background-color: #f9f9f9;
	color: #999 !important;
	border:1px #eee solid;
	border-radius: 5px;
	margin-top: 5px;
	width: 200px !important;
}
/* .trade-panel-form.sell .submit {
	background: #d14b64;
}
.trade-panel-form.buy .submit {
	background: #03ad8f;
} */
/* .trade-panel-form .submit span {
	text-transform: uppercase;
} */
/* .trade-panel-form.sell .submit {
	background: #d14b64;
}
.trade-panel-form.buy .submit {
	background: #03ad8f;
} */
.handicap {
	margin-top: -30px;
	flex: 1;
	margin-left: 20px;
	margin-right: 6px;
	overflow: hidden;
}
.handicap-title {
	font-size: 13px;
	color: #c5cfd5;
	height: 28px;
}
.handicap-title .index {
	margin-right: 20px;
}
.handicap-sell {
	font-size: 12px;
	color: #d14b64;
}
.handicap-buy  {
	font-size: 12px;
	color: #03ad8f;
}
.handicap-sell dl,.handicap-buy dl {
	height: 26px;
	line-height: 26px;
	overflow: hidden;
}
.handicap-sell dl dt,.handicap-buy dl dt {
	width: 25px;
	text-align:left;
}
.handicap-sell dl dd+dd,.handicap-buy dl dd+dd {
	color: #999;
}
.handicap-price {
	padding-top: 6px;
	padding-bottom: 6px;
}
.handicap-price em {
	height: 30px;
	line-height: 30px;
	font-size: 20px;
	font-weight: bold;
}
.handicap-price b.rate {
	font-size: 10px;
	margin-left: 5px;
}
.handicap-price span {
	color: #c5cfd5;
	font-size: 10px;
	line-height: 15px;
	display: block;
}
.trade-order-title {
	height: 60px;
	line-height: 60px;
	font-size: 16px;
	padding: 0 15px;
}
.trade-order-title span {
	display: inline-block;
	line-height: 28px;
	margin-right: 20px;
}
.trade-order-title span.cur{
	color: #ffcd36;
	border-bottom: 1px #ffcd36 solid;
}
.mint-popup-bottom {
	width: 100%;
}
.list .item{
	padding: 5px 15px;
	font-size: 12px;
	position: relative;
	border-bottom: 1px #eee solid;
}
div.status {
	position: absolute;
	right: 15px;
	bottom: 29px;
	display: inline-block;
	line-height: 30px;
	text-align: center;
	background-color: #ddd;
	border-radius: 5px;
	overflow: hidden;
}
div.status div {
	padding: 0 15px;
}
.win {
	background-color: #efeb77;
}
.orderInfo {
	padding: 20px 15px;
	position: relative;
}
.orderInfo h1 {
	margin-bottom: 10px;
}
.orderInfo p {
	line-height: 24px;
	font-size: 13px;
}
.orderInfo .btns {
	position: absolute;
	right: 10px;
	top: 25px;
	width: 100px;
}
.orderInfo .btns button {
	font-size: 14px;
	margin:10px 0 10px 0;
}

.stit {
	line-height: 15px;
	border-left: 3px #ffcd36 solid;
	padding-left: 10px;
	margin-bottom: 8px;
}
.list {
	margin-top: 10px;
}
.list a {
	display: inline-block;
	border: 1px solid #c8cfd5;
	font-size: 14px;
	font-weight: 600;
	margin-right: 10px;
	margin-bottom: 6px;
	text-align: center;
	width: 50px;
}
.ltime a {
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 6px;
	width: auto;
}
.list a p {
	font-size: 12px;
	font-weight: normal;
}
.list a.cho {
	background: yellow;
	color: red;
	border: 1px #b9b906 solid;
}
.income {
	font-size: 14px;
	display: inline-block;
}
.income span {
	border-bottom: 1px #ffcd36 solid;
}
.income span i{
	color: red;
	font-weight: bold;
}
</style>
