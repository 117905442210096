module.exports = {
	common: {
		name: '仮想通貨取引所',
		welcome: 'ようこそ',
		login: 'ログイン',
		register: '登録',
		emailaddr: '電子メールアドレス',
		password: 'パスワード',
		later: '最初に閲覧',
		next: '次のステップ',
		amount: '量',
		price: '価格',
		direction: '方向',
		buy: '買う',
		sell: '売る',
		loginFirst: '最初にログイン',
		confirmFirst: '最初に認定',
		confirmNow: '今すぐ本名に行く',
		cancel: '取り消す',
		sure: '確認',
		orderDetail: '注文詳細',
		insufficient: '不十分なUSDTバランス',
		orderSuc: '購入成功',
		logout: 'サインアウト',
		logoutSuc: '終了する',
		sureChange: '改訂',
		sendCode: '検証コード',
		sendLater: '送られた',
		time: '時間',
		success: '成功',
		revoke: '取り消す',
		errReason: '失敗の理由',
		nodata: '関連データなし',
		verifyNow: '認証',
		wait: '後で',
		getWait1: 'リチャージQRコードの取得...',
		getWait2: '入金先住所の取得...',
		submiting: '現在の注文は送信中です...'
	},
	footer: {
		index: 'ホーム',
		trend: '引用',
		trade: 'トレード',
		finance: '資産',
		me: '我的'
	},
	login: {
		inputTs: '名前とパスワードを入力してください'
	},
	register: {
		emailReg: 'メール登録',
		countryTs: '国内登録後は変更できません',
		success: '電子メールを正常に送信',
		error: 'メールフォーマットエラー',
		sendSuc: '正常に送信されました',
		suc: '登録完了'
	},
	email: {
		tit: '郵便',
		check: 'メールが届かない場合は、ゴミ箱を確認してください',
		plsInput: '認証コードを入力',
		again: '再取得',
		second: '数秒で再取得'
	},
	pwd: {
		set: 'パスワード設定',
		enter: 'パスワード入力',
		length: '6〜20文字',
		again: 'もう一度パスワード',
		code: '共有コード',
		agree: '同意',
		protocol: 'ユーザー規約',
		ts1: '長さ6〜20の有効なパスワード',
		ts2: '2回異なるパスワード',
		ts3: '共有コードを入力してください'
	},
	index: {
		recharge: 'チャジ',
		second: '急速契約',
		share: 'シェア',
		online: '顧客サービス',
		buyUsdt: 'USDTを購入する',
		buyUsdtIntro: 'USDTを安全に購入するためのプラットフォーム',
		ranking: 'リーダーボード'
	},
	quotes: {
		search: '検索通貨',
		self: '収集',
		all: 'すべて',
		name: '名前',
		volume: '量',
		price: '最新価格',
		ranking: '上昇と下降'
	},
	trade: {
		coin: '通貨取引',
		second: '契約取引',
		limit: '指値価格',
		market: '市場価格',
		price: '価格',
		amount: '量',
		inputUSDT: 'USDTの金額を入力してください',
		available: '利用可能',
		all: 'すべて',
		transactionAmount: '取引金額',
		handicap: 'ディスク深さ',
		curEntrust: '当然の委託',
		historyEntrust: 'すべて',
		totalEntrust: '委託総額',
		finish: '終了',
		cost: '消費',
		income: '所得',
		revoke: 'キャンセル',
		category: '通貨',
		direction: '方向',
		sure: '確認',
		cancel: 'キャンセル',
		orderStatu1: '待つ',
		orderStatu2: '完了',
		orderStatu3: '取り消す',
		orderStatu4: '取り消された',
		addFavoriteSuc: 'オプションの成功を追加',
		removeFavoriteSuc: 'オプションを正常に削除',
		inputTs1: '正しい価格と数量を入力してください',
		inputTs2: 'USDTの正しい金額を入力してください',
		revokeOrder: '取り消す',
		sureRevokeOrder: 'この注文をキャンセルしてもよろしいですか?',
		revokeOrderSuc: '注文を正常にキャンセルしました'
	},
	trend: {
		high: '高',
		low: '低',
		volume: '量',
		turnover: '額',
		entrust: '委託する',
		deal: '対処',
		buyList: '購入',
		sellList: '販売',
		add: '追加',
		remove: '削除',
		addSuc: 'オプションの成功を追加',
		removeSuc: 'オプションを正常に削除'
	},
	second: {
		amount: '量',
		time: '時間',
		odds: 'オッズ',
		available: '取引可能なバランス',
		doLong: '購入',
		doShort: '販売',
		category: '通貨',
		openPrice: '始値',
		endPrice: '最終値',
		dealCategory: '通貨',
		dealAmount: '数量',
		dealDirection: '方向',
		dealTime: '時間',
		profit: "利益",
		sure: '確認',
		cancel: 'キャンセル',
		orderStatus1: '秒読み',
		orderStatus2: '完了',
		orderStatus3: '取り消された',
		noSupport: '現在のトランザクションは迅速な契約をサポートしておらず、自動的に切り替えられています',
		minVolume: '現在の最小賭け金は:',
		maxVolume: '現在の最大ベット額は:',
		being: '注文を送信する',
		fail: '注文の送信に失敗しました'
	},
	me: {
		tit: 'マイ',
		identity: '実名認証',
		security: 'セキュリティ設定',
		financeRecord: '財務記録',
		tradeRecord: '取引記録',
		setting: '一般設定',
		about: '私たちに関しては',
		share: '友達と分け合う',
		service: '顧客サービス',
		identityStatus1: '名前認証なし',
		identityStatus2: 'レビュー中',
		identityStatus3: '検証済み',
		identityStatus4: '不合格',
	},
	identity: {
		tit: '実名認証',
		plsInput: '識別情報を入力してください',
		oneBindTs: '1つの証明書は、実名認証を介して1つのアカウントにのみバインドできます。検証後にバインドしてください。。',
		status1: 'モデレート。承認を辛抱強く待ってください',
		status2: '承認されました。アカ​​ウントの安全性が高まりました',
		status3: 'レビューに失敗しました。情報を変更して、レビューのために再送信してください',
		errReason: '失敗の理由',
		name: '名前',
		id: 'IDカード',
		plsUp: 'アップロード',
		frontImg: 'IDの前',
		backImg: 'IDカードの裏面',
		status4: '認証を申請する',
		status5: '保留中のレビュー',
		status6: '認証に合格',
		status7: '再認定',
		requireTis: '必要とする',
		require1: '1. ID情報を表示できる必要があります',
		require2: '2. 写真の内容は本物で有効であり、変更は許可されていません',
		require3: '3. ID番号と名前を読み取れる必要があります， サポート jpg / png / gif',
		require4: '4. 最大画像サイズは10Mを超えてはなりません',
		limit: '10M以内の写真を選択してください',
		submitSuc: '正常に送信されました。承認されるまで辛抱強くお待ちください'
	},
	news: {
		list: 'アナウンスリスト',
		detail: '発表の詳細'
	},
	setting: {
		tit: '設定',
		lang: '言語',
		price: '価格設定'
	},
	share: {
		earnUsdt: '友達を招待',
		intro: '友達を招待して、各友達のリチャージ額の1％を獲得しましょう',
		code: '招待コード',
		copy: '共有リンクをコピー',
		step1: '共有',
		step2: '登録して補充する',
		step3: 'コミッションを得る',
		data: 'データ',
		register: '登録への招待',
		recharge: '充電する',
		commission: '蓄積された報酬',
		copySuc: '正常にコピー',
		copyErr: 'コピーに失敗しました。コードをスキャンしてください'
	},
	security: {
		tit: 'セキュリティ設定',
		loginPwd: 'ログインパスワード',
		assetPwd: 'ファンドのパスワード',
		change: 'パスワードを変更する',
		set: 'パスワード設定',
		setAssetPwd: 'ファンドのパスワード設定',
		forgetloginPwd: 'ログインパスワードを忘れた',
		forgetassetPwd: 'ファンドのパスワードを忘れた',
		changeloginPwd: 'ログインパスワードを変更する',
		changeassetPwd: 'ファンドのパスワードを変更する',
		oldPwd: '元のパスワード',
		newPwd: '新しいパスワード',
		code: '検証コード',
		changeloginPwdSuc: 'ログインパスワードが正常に変更されました',
		setloginPwdSuc: 'ログインパスワードが正常に設定されました',
		changeassetPwdSuc: 'ファンドのパスワードが正常に変更されました',
		setassetPwdSuc: 'ファンドのパスワードが正常に設定されました'
	},
	finance: {
		all: 'すべて',
		toUSDT: '同等の総資産',
		recharge: 'チャジ',
		withdraw: '提弊',
		available: '利用可能',
		freeze: '氷結',
		equivalence: '等価'
	},
	deposit: {
		tit: 'チャジ',
		record: '支払い記録',
		channel: '再充電チャネル',
		scan: 'QRコードをスキャンし、転送します',
		address: 'ウォレットアドレス',
		copy: 'コピーアドレス',
		min: '最小充電量は',
		wait: '再充電後、アカウントが自動的にクレジットされるまで10〜30分待ちます',
		rightChannel: '正しい充電チャネルを選択してください。そうしないと、クレジットされません。',
		copySuc: '正常にコピー',
		copyErr: 'コピーに失敗しました。手動でコピーしてください',
		channelFail: 'チャネル情報を取得できませんでした'
	},
	transfer: {
		tit: '提弊',
		record: '離脱記録',
		channel: '撤退チャネル',
		coin: '引き出し通貨',
		address: '引き出しアドレス',
		amount: '引き出し金額',
		balance: '残高',
		fee: '発掘調査料',
		counting: '計算',
		number: '数量',
		detial: '引き出しの詳細',
		assetPwd: 'ファンドのパスワード',
		password: 'パスワード',
		wrongAddr: '引き出しアドレスが正しくありません',
		rightNum: '有効な引き出し金額を入力してください',
		insufficient: '残高不足です',
		submitSuc: '撤回申請が正常に送信されました',
		min: '最小'
	},
	about: {
		tit: '私たちに関しては'
	},
	coinRecord: {
		tit: '取引記録',
		select: 'フィルター',
		type: 'の種類',
		change: 'アカウントの変更',
		all: 'すべて',
		exchange: '通貨取引',
		recharge: 'チャジ',
		withdraw: '提弊',
		system: 'システム'
	},
	financeRecord: {
		tit: '財務記録',
		recharge: 'チャジ',
		withdraw: '提弊',
		amount: '金額',
		fee: '費用',
		address: 'ウォレットアドレス',
		transaction: 'トレード',
		status: '状态',
		status1: '処理されるのを待',
		status2: '拒絶',
		status3: '取り消す',
		status4: '待つ',
		status5: '終了',
		revokeSuc: '取り消された'
	},
	platform: {
		tit: 'USDTプラットフォーム',
		ts1: '国とサポートされている法定通貨に応じてプラットフォームを選択し、アプリストアまたは対応する公式ウェブサイトでAPPをダウンロードしてUSDTを購入し、USDTの購入が完了したら、投資やその他の操作のためにこのプラットフォームに転送できます',
		ts2: 'だまされないように、偽造プラットフォームでUSDTを購入しないことを忘れないでください'
	},
	req: {
		ts1: 'ロードしようとしています...',
		ts2: 'データフォーマットエラー',
		ts3: '認証の有効期限が切れています。もう一度ログインしてください',
		ts4: '申し訳ありませんが、サーバーエラーです。しばらくしてからもう一度お試しください',
		ts5: '最初にログインしてください...'
	},
	question: {
		tit: '質問を送信する',
		type: 'タイプ',
		describe: '説明',
		submit: '参加する',
		history: '過去の質問',
		wait: '待つ',
		pls: '入力',
		suc: '成功'
	}
} 