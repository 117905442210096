<template>
	<div>
		<div class="pageNav">
			<a @click="$router.goBack()" class="fl"><i class="iconfont">&#xe607;</i></a>
		</div>
		<div class="regBox">
			<h4>{{$t('pwd.set')}}</h4>
			<p>{{$t('pwd.length')}}</p>
			<div class="loginFrom">
				<div class="inputBox">
					<input type="text" :placeholder="$t('pwd.enter')" v-model="password"/>
				</div>
				<div class="inputBox">
					<input type="text" :placeholder="$t('pwd.again')" v-model="repeatPwd"/>
				</div>
				<div class="inputBox">
					<input type="text" :placeholder="$t('pwd.code')" v-model="inviteCode"/>
				</div>
				<div class="agree">
					<label>
						<input type="checkbox" checked="agree">
						{{$t('pwd.agree')}}
					</label>
					<router-link to="/about">《{{$t('pwd.protocol')}}》</router-link>
				</div>
				<button :disabled="password.length<6 || password.length>20" class="submit" @click="register">{{$t('common.register')}}</button>
			</div>
		</div>
	</div>
</template>

<script>
import { registerByUsername } from "@/api/user.js"
import { mapActions } from 'vuex'
export default {
	name: 'Setpwd',
	data () {
		return {
			password: '',
			repeatPwd: '',
			inviteCode: '',
			agree: 'true'
		}
	},
	mounted () {
		this.inviteCode = sessionStorage.getItem('inviteCode') || ''
	},
	methods: {
		//引入登录action
		...mapActions(['LoginByUsername']),
		//注册
		register () {
			//判断密码输入
			if(this.password.length<6 || this.password.length>20) {
				this.$toast({
					message: this.$t('pwd.ts1'),
					duration: 1000
				})
				return false
			}
			//判断两次密码是否一致
			if(this.password !== this.repeatPwd) {
				this.$toast({
					message: this.$t('pwd.ts2'),
					duration: 1000
				})
				return false
			}
			//判断是否输入邀请码
			if(!this.inviteCode) {
				this.$toast({
					message: this.$t('pwd.ts3'),
					duration: 1000
				})
				return false
			}
			let data = {
				email: sessionStorage.getItem('email') || '',
				password: this.password,
				code: sessionStorage.getItem('code')  || '',
				country: sessionStorage.getItem('country')  || '',
				inviteCode: this.inviteCode
			}
			registerByUsername(data).then(() => {
				//注册成功弹窗
				this.$toast({
					message: this.$t('register.suc'),
					duration: 2000
				})
				//登录操作
				let userInfo = {
					username: sessionStorage.getItem('email'),
					password: this.password
				}
				setTimeout(() => {
					this.LoginByUsername(userInfo).then(() => {
						this.$router.push("/index")
					})
				},2000)
			}).catch(err => {
				//console.log(err)
				//注册失败弹窗
				this.$toast({
					message: err,
					duration: 2000
				})
			})
		}
	}
}
</script>

<style scoped>
.pageNav {
	height: 60px;
	padding: 0 30px;
	line-height: 60px;
}
.pageNav a {
	height: 60px;
	color: #1882d4;
}
.pageNav a i {
	color: #8c9fad;
}
.regBox {
	font-size: 16px;
	height: 80px;
	padding:30px 30px 0;
}
.regBox h4 {
	font-size: 30px;
	line-height: 40px;
	font-weight: normal;
}
.loginFrom {
	margin: 50px 0 30px;
}
.country {
	margin: 20px 0;
	font-size: 18px;
}
.inputBox {
	border-bottom: 1px solid #c5cfd5;
	margin-top: 20px;
}
.inputBox input {
	width: 100%;
	line-height:40px;
	font-size: 18px;
	color: #1f3f59;
}
.getCode {
	margin-bottom: 45px;
}
.regBox button.submit {
	width: 100%;
	height: 52px;
	font-size: 16px;
	color: #fff;
	background: #1882d4;
}
.regBox button.submit:disabled {
	background: #c5cfd5;
}
.regBox p {
	height: 28px;
	line-height: 28px;
	color: #8c9fad;
	font-size: 15px;
}
.regBox p.warning {
	color: #d14b64;
}
.agree {
	margin: 20px 0;
	color: #8c9fad;
	font-size: 16px;
}
.agree a {
	color: #1882d4;
}
</style>
