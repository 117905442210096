<template>
	<div>
		<div class="pageNav">
			<router-link to="/register" class="fr">{{$t('common.register')}}</router-link>
		</div>
		<div class="regBox">
			<h4><img :src="sitelogo" height="30px"></h4>
			<p>{{$t('common.welcome')}} {{sitename}} {{$t('common.name')}}</p>
			<div class="loginFrom">
				<div class="inputBox">
					<input type="email" :placeholder="$t('common.emailaddr')" v-model="username"/>
				</div>
				<div class="inputBox">
					<input type="password" :placeholder="$t('common.password')" v-model="password"/>
				</div>
				<button class="submit" @click="login()">{{$t('common.login')}}</button>
				<p class="later tc"><router-link to="/">{{$t('common.later')}}</router-link></p>
			</div>
		</div>
		<v-lang></v-lang>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import vLang from '@/components/Lang.vue'
export default {
	name: 'Login',
	components: {
		vLang
	},
	computed: {
		...mapGetters(['sitename','sitelogo'])
	},
	data () {
		return {
			username: '',
			password: ''
		}
	},
	methods: {
		//引入登录action
		...mapActions(['LoginByUsername']),
		login () {
			if(!this.username || !this.password) {
				this.$toast({
					message: this.$t('login.inputTs'),
					duration: 1000
				})
			}
			let userInfo = {
				username:this.username,
				password: this.password
			}
			this.LoginByUsername(userInfo).then(() => {
				this.$router.push("/")
			}).catch(err => {
				console.log(err)
				if(err == 'loading') {
					this.$indicator.open()
				}else {
					this.$toast({
						message: err,
						duration: 2000
					})
				}
				
			})
		}
	}
}
</script>

<style scoped>
	.pageNav {
		height: 60px;
		padding: 0 30px;
		line-height: 60px;
	}
	.pageNav a {
		height: 60px;
		color: #1882d4;
	}
	.regBox {
		font-size: 16px;
		height: 80px;
		padding:30px 30px 0;
	}
	.regBox h4 {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 10px;
	}
	.regBox h4 img {
		height: 30px;
	}
	.loginFrom {
		margin: 50px 0 30px;
	}
	.inputBox {
		border-bottom: 1px solid #c5cfd5;
		margin:0 0 30px;
	}
	.inputBox input {
		width: 100%;
		line-height:40px;
		font-size: 20px;
		color: #1f3f59;
	}
	.regBox button.submit {
		width: 100%;
		height: 52px;
		font-size: 16px;
		color: #fff;
		background: #1882d4;
		margin-top: 50px;
	}
	.regBox button.submit:disabled {
		background: #c5cfd5;
	}
	.regBox p {
		height: 28px;
		line-height: 28px;
		color: #8c9fad;
		font-size: 15px;
	}
	.later {
		margin-top: 15px;
	}
	.later a {
		font-size: 12px;
		color: #999;
	}
</style>
