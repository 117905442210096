<template>
	<div class="lang">
		<div class="langIcon" @click="showLangs()">
			{{langName}}
		</div>
		<mt-popup
			v-model="langTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="langOptions" value-key='displayName' @change="selectLang"></mt-picker>
		</mt-popup>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLangs} from '@/api/setting.js'
export default {
	name: 'Lang',
	computed: {
		...mapGetters(['langName', 'langId'])
	},
	data () {
		return {
			langTag: false,
			langOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}]
		}
	},
	methods: {
		//显示所有可选语言
		showLangs () {
			this.langTag = !this.langTag
		},
		//选择语言
		selectLang (picker, values){
			if(!this.langTag) {
				return
			}
			//还未获取到全部语言
			if(this.langOptions[0].values.length == 0) {
				return
			}
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			if(picker.getValues()[0]) {
				let tmpLangName = picker.getValues()[0].displayName
				let tmpLangId = picker.getValues()[0].cultureName
				//保存语言设置
				this.$store.commit("SET_SITELANGNAME", tmpLangName) //语言名称
				this.$store.commit("SET_SITELANGID", tmpLangId) //语言标识
				this.$i18n.locale = tmpLangId
			}
		},
	},
	mounted () {
		//获取所有语言赋值给picker
		getLangs().then(data => {
			this.langOptions[0].values=data.items
			//根据当前的语言找到picker默认的是哪一项从而设置defaultIndex
			let ind = this.langOptions[0].values.findIndex(item => {
				return item.displayName == this.langName
			})
			this.langOptions[0].defaultIndex = ind
		})
	}
}
</script>

<style scoped>
.langIcon {
	position: fixed;
	text-align: center;
	font-size: 12px;
	background: #1882d4;
	color: #ffffff;
	right: 10px;
	bottom: 10px;
	opacity: 0.5;
	padding: 5px 10px;
}
.mint-popup-bottom {
	width: 100%;
}
</style>
