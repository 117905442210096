<template>
	<div class="message">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('me.service')}}
		</div>
		<div class="cnt">
			<div class="tc">
				knwex@outlook.com
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Message',
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.cnt {
	padding: 40px 10px;
}
</style>
