<template>
	<div>
		<div class="headerNav">
			<a class="active">{{$t('finance.all')}}</a>
			<!-- <a>币币</a>
			<a>合约</a>
			<a>杠杆</a>
			<a>挖矿宝</a> -->
		</div>
		<div class="wrapper">
			<div class="finance-total">
				<div>{{$t('finance.toUSDT')}} ( USDT )</div>
				<div class="num">{{totalValueByUsdt.toFixed(2)}}<em> ≈ </em> <em>{{(totalValueByUsdt * exchangeRateByUSD).toFixed(4)}}</em><em> {{currency}} </em></div>
				<div class="btn">
					<button @click="$router.push('/deposit')">{{$t('finance.recharge')}}</button>
					<button @click="$router.push('/transfer')">{{$t('finance.withdraw')}}</button>
					<!-- <button>划转</button> -->
				</div>
			</div>
			<div class="finance-list">
				<div class="item" v-for='asset of assets' :key="asset.id">
					<div class="name">
						<span>
							<i class="uppercase">{{asset.coinId}}</i>
						</span>
					</div>
					<div class="detail">
						<div class="available">
							{{$t('finance.available')}}<br>
							<span>{{asset.balance}}</span>
						</div>
						<div class="freeze">
							{{$t('finance.freeze')}}<br>
							<span>{{asset.freeze}}</span>
						</div>
						<div class="equivalence">
							{{$t('finance.equivalence')}} (USDT)<br>
							<span>{{(asset.priceByUsdt).toFixed(3)}}</span>
						</div>
					</div>
				</div>
				<v-nodata v-if="assets.length == 0"></v-nodata>
				<!-- <div class="item">
					<div class="name">
						<span>
							<img src="../assets/img/coin/bitcoin.png"> <i>ETH</i>
						</span>
					</div>
					<div class="detail">
						<div class="available">
							可用<br>
							<span>0.1</span>
						</div>
						<div class="freeze">
							冻结<br>
							<span>0.1</span>
						</div>
						<div class="equivalence">
							折合 (USDT)<br>
							<span>6001</span>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<v-footer curState="finance"></v-footer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import vFooter from '@/components/Footer'
import vNodata from "@/components/Nodata.vue"
import { getCoinAccounts } from "@/api/finance.js"
export default {
	name: 'Finance',
	components: {
		vNodata,
		vFooter
	},
	data () {
		return {
			assets: [],
			totalValueByUsdt: 0, //折合USDT
		}
	},
	computed: {
		...mapGetters(['exchangeRateByUSD','currency'])
	},
	activated () {
		this.totalValueByUsdt = 0
		//获取用户资产
		getCoinAccounts().then(data => {
			console.log(data)
			this.assets = data.items
			this.assets.forEach(item => {
				this.totalValueByUsdt += item.priceByUsdt
			})
		})
	}
}
</script>

<style scoped>
	.wrapper {
		padding-top: 54px;
		padding-bottom: 60px;
	}
	.headerNav {
		position: fixed;
		top: 0;
		left: 0;
		height: 54px;
		line-height: 54px;
		width: 100%;
		box-sizing: border-box;
		padding-left: 15px;
		font-size: 16px;
		font-weight: 700;
		color: #8c9fad;
		z-index: 1;
		background-color: #fff;
	}
	.headerNav a {
		padding-right: 15px;
	}
	.headerNav a.active {
		font-size: 20px;
		color: #666;
	}
	.finance-total {
		_background-color: #1882d4;
		background-color: #f3bc19;
		color: #fff;
		margin:0 10px 10px;
		padding: 20px 10px;
		border-radius: 10px;
		font-size: 13px;
	}
	.finance-total div.num {
		font-size: 20px;
		padding: 5px 0;
	}
	.finance-total div.num em{
		font-size: 12px;
	}
	.finance-total .btn {
		display: flex;
		margin: 10px 0 0;
	}
	.finance-total .btn button:nth-child(2) {
		margin: 0 10px;
	}
	.finance-total .btn button {
		flex: 1;
		background-color: #e4ae0c;
		color: #fff;
		line-height: 30px;
		border: 1px #ffd24b solid;
		border-radius: 5px;
	}
	.finance-list {
		margin: 20px 10px;
	}
	.finance-list .item {
		border-bottom: 1px #eee solid;
		padding: 10px 0;
		margin: 5px 10px;
	}
	.finance-list .item .name {
		margin-bottom: 10px;
		font-weight: 800;
	}
	.finance-list .item .name span {
		height: 36px;
		display: inline-block;
		border-bottom: 1px #eee dashed;
	}
	.finance-list .item .name img {
		height: 30px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.finance-list .item .name i{
		font-weight: 800;
	}
	.finance-list .item .detail {
		display: flex;
		font-size: 13px;
		line-height: 20px;
	}
	.finance-list .item .detail .equivalence {
		flex: 1;
		text-align: right;
	}
	.finance-list .item .detail .available {
		width: 130px;
	}
</style>
