import router from '@/router'
import store from '@/store'
//import { Indicator } from 'mint-ui'
import { getToken } from '@/utils/auth'
import { Toast } from 'mint-ui'

const permList = ['/me','/finance'] //需要登陆页面

router.beforeEach((to, from, next) => {
    //路由跳转前，打开加载
    //Indicator.open({spinnerType:'fading-circle'})
    if(getToken()) {
        if(to.path === '/login') {
            next({path: '/'})
        } else {
            if(store.getters.name === '') { //用户账户不存在
                store.dispatch('GetUserInfo').then(() => {
                    next()
                }).catch(err => {
                    store.dispatch('LogOut').then(()=>{
                        Toast({
                            message: err || '身份认证失败，请重新登陆',
                            duration: 1500
                        })
                        next({path: '/'})
                    })
                })
            }
        }
    } else {
        if(permList.indexOf(to.path) != -1) { //需登录，直接进入
            next({path: '/login'})
        } else {
            next()
        }
    }
    next()
})

router.afterEach(() => {
    //Indicator.close()
})