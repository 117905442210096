<template>
	<div class="nft">
		<v-header></v-header>
		<div class="box">
			<div class="name">
				{{collectionName}}
			</div>
			<div class="id">
				{{nftInfo.name}}
			</div>
			<div class="pic">
				<img :src="nftInfo.imageUrl" width="100%">
			</div>
			<div class="own">
				<span>{{$t('nft.owned')}}<i class="owner">{{nftInfo.ownerName}}</i></span>
				<span><i></i>{{nftInfo.favorite}} {{$t('nft.favorite')}}</span>
			</div>
			<div class="trade">
				<div class="time" v-if="nftInfo.onSale">
					<div>
						<span>{{$t('nft.saleEnds')}}</span>
						<!-- <span>August 24, 2022 at 11:19pm GMT+8</span> -->
						<span>{{getLocalTime(nftInfo.saleExpirationTime)}}</span>
					</div>
				</div>
				<div class="buy" v-if="nftInfo.onSale">
					<div>
						<p>{{$t('nft.currentPrice')}}</p>
						<p class="price">{{nftInfo.offerPrice}} <span class="uppercase">{{paymentTokenId}}</span></p>
					</div>
					<div class="btns" v-if="nftInfo.ownerName!=='You'">
						<mt-button @click="showOrder(true)" class="buyBtn" size="large" type="primary">{{$t('nft.buyNow')}}</mt-button>
						<mt-button @click="showOffer(true)" class="offerBtn" size="large" type="default">{{$t('nft.makeOffer')}}</mt-button>
					</div>
					<div class="btns" v-else>
						<mt-button @click="cancelListing(nftInfo.id)" class="buyBtn" size="large" type="primary">{{$t('nft.cancelListing')}}</mt-button>
					</div>
				</div>
				<div class="buy" v-else>
					<div>
						<p>{{$t('collection.lastSale')}}</p>
						<p class="price">{{nftInfo.lastSalePrice || nftInfo.offerPrice}} USDT</p>
					</div>
					<div class="btns" v-if="nftInfo.ownerName!=='You'" >
						<mt-button @click="showOffer(true)"  class="offerBtn" size="large" type="default">{{$t('nft.makeOffer')}}</mt-button>
					</div>
					<div class="btns" v-else>
						<mt-button @click="showSell(true)" class="offerBtn" size="large" type="default">{{$t('nft.sell')}}</mt-button>
						<mt-button @click="transfer()" class="offerBtn" size="large" type="default">{{$t('nft.transfer')}}</mt-button>
					</div>
				</div>
			</div>
			<div class="trade offer">
				<div class="tit">
					<span class="iconfont">&#xe61c;</span>{{$t('nft.makeOffer')}}
				</div>
				<div class="list">
					<div class="scroll">
						<div class="nav">
							<span class="price">{{$t('nft.price')}}</span>
							<span class="date">{{$t('nft.expiration')}}</span>
							<span class="from">{{$t('nft.from')}}</span>
							<span class="action">{{$t('nft.action')}}</span>
						</div>
						<div class="item" v-for="(item, index) of nftInfo.offers" :key="index">
							<span class="price">{{item.price}} USDT</span>
							<span class="date">{{getLocalTime(item.expirationTime)}}</span>
							<span class="from">{{item.bidder}}</span>
							<span class="action">
								<mt-button 
								size="small" 
								type="primary" 
								v-if="item.bidder == 'You'"
								@click="cancelOffer(nftInfo.id, item.bidderId)">
								cancel
								</mt-button>
							</span>
						</div>
						<!-- <div class="item">
							<span>12891 USDT</span>
							<span>36% below</span>
							<span>jack</span>
						</div>
						<div class="item">
							<span>12891 USDT</span>
							<span>36% below</span>
							<span>jack</span>
						</div> -->
					</div>
				</div>
			</div>
			<div class="trade des">
				<div class="tit">
					<span class="iconfont">&#xe62b;</span>{{$t('nft.description')}}
				</div>
				<div class="cnt"> 
					<p>{{$t('collection.by')}} <span>RareApepesDeployer</span></p>
					<!-- <p>This Rare Apepe is an homage to Bored Ape #5719</p> -->
					<p>{{nftInfo.description}}</p>
				</div>
			</div>
			<div class="trade detail">
				<div class="tit">
					<span class="iconfont">&#xe61c;</span>{{$t('nft.details')}}
				</div>
				<div class="cnt"> 
					<!-- <p>Contract Address<span class="blue">0x321..32910</span></p> -->
					<p>{{$t('nft.tokenID')}}<span class="blue">{{nftInfo.tokenId}}</span></p>
					<p>{{$t('nft.tokenStandard')}}<span>{{tokenStandard}}</span></p>
					<p>{{$t('nft.blockchain')}}<span>{{blockchain}}</span></p>
					<p>{{$t('nft.fee')}}<span>{{fee * 100}}%</span></p>
				</div>
			</div>
			<div class="trade activity">
				<div class="tit">
					<span class="iconfont">&#xe6a8;</span>{{$t('nft.activity')}}
				</div>
				<div class="list">
					<div class="scroll2">
						<div class="nav">
							<span class="event">{{$t('nft.event')}}</span>
							<span class="price">{{$t('nft.price')}}</span>
							<span class="from">{{$t('nft.from')}}</span>
							<span class="to">{{$t('nft.to')}}</span>
							<span class="date">{{$t('nft.date')}}</span>
						</div>
						<div class="item" v-for="(item, index) of events" :key="index">
							<span class="event">{{item.type}}</span>
							<span class="price">{{item.price || '-'}} <em v-if="item.price">{{paymentTokenId}}</em></span>
							<span class="from">{{item.from}}</span>
							<span class="to">{{item.to}}</span>
							<span class="date">{{getLocalTime(item.createTime)}}</span>
						</div>
						<!-- <div class="item">
							<span>Transfer</span>
							<span>1234</span>
							<span>mikeSnft</span>
							<span>Jacke</span>
							<span>2022.08.22</span>
						</div>
						<div class="item">
							<span>Sale</span>
							<span>1234</span>
							<span>mikeSnft</span>
							<span>Jacke</span>
							<span>2022.08.22</span>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<v-footer></v-footer>
		<!-- 购买 -->
		<mt-popup
			v-model="buyVisible"
			position="bottom"
			modal="false">
			<div>
				<div class="orderInfo">
					<h1>{{$t('nft.order')}}</h1>
					<p>{{$t('nft.collectionName')}}： {{collectionName}}</p>
					<p>{{$t('nft.name')}}： {{nftInfo.name}}</p>
					<p>{{$t('nft.price')}}： {{nftInfo.offerPrice}} <span class="uppercase">{{paymentTokenId}}</span></p>
					<div class="buttons">
						<mt-button @click="buy(nftInfo.id)" type="primary">{{$t('nft.buy')}}</mt-button>
						<mt-button @click="showOrder(false)" type="default">{{$t('nft.cancel')}}</mt-button>
					</div>
				</div>
			</div>
		</mt-popup>
		<!-- offer -->
		<mt-popup
			v-model="offerVisible"
			modal="false">
			<div>
				<div class="offerInfo">
					<h1>{{$t('nft.makeOffer')}}<span @click="showOffer(false)" class="iconfont fr">X</span></h1>
					<p>{{$t('nft.price')}}<span class="balance">{{$t('nft.balance')}}：{{usdtAsset.balance}} usdt</span></p>
					<div class="oPrice">
						<span class="paymentTokenId"> <em class="uppercase">{{paymentTokenId}}</em></span>
						<input type="text" placeholder="请输入价格" v-model="offerPrice">
					</div>
					
					<p>{{$t('nft.expiration')}}</p>
					<div class="oTime">
						<select v-model="offerDay" @change="changeDate()">
							<option value="1">{{$t('nft.day1')}}</option>
							<option value="3">{{$t('nft.day3')}}</option>
							<option value="7">{{$t('nft.day7')}}</option>
							<option value="30">{{$t('nft.day30')}}</option>
						</select>
						<span class="endTime">{{$t('nft.end')}}:<i>{{offerEndTime}}</i></span>
					</div>
					<div class="buttons">
						<mt-button size="large" @click="makeOffer(nftInfo.id)" type="primary">{{$t('nft.makeOffer')}}</mt-button>
					</div>
				</div>
			</div>
		</mt-popup>
		<!-- sell -->
		<mt-popup
			v-model="sellVisible"
			modal="false">
			<div>
				<div class="offerInfo">
					<h1>{{$t('nft.sell')}}<span @click="showSell(false)" class="iconfont fr">X</span></h1>
					<p>{{$t('nft.price')}}</p>
					<div class="oPrice">
						<span class="paymentTokenId"> <em class="uppercase">{{paymentTokenId}}</em></span>
						<input type="text" :placeholder="$t('nft.inputPrice')" v-model="offerPrice">
					</div>
					
					<p>{{$t('nft.end')}}</p>
					<div class="oTime">
						<select v-model="offerDay" @change="changeDate()">
							<option value="1">{{$t('nft.day1')}}</option>
							<option value="3">{{$t('nft.day3')}}</option>
							<option value="7">{{$t('nft.day7')}}</option>
							<option value="30">{{$t('nft.day30')}}</option>
						</select>
						<span class="endTime">{{$t('nft.end')}}:<i>{{offerEndTime}}</i></span>
					</div>
					<div class="buttons">
						<mt-button size="large" @click="sell(nftInfo.id)" type="primary">{{$t('nft.sell')}}</mt-button>
					</div>
				</div>
			</div>
		</mt-popup>
	</div>
</template>

<script>
import { timestampToTime } from "@/utils/third.js"
import vHeader from '@/components/Header'
import vFooter from '@/components/Footer'
import { getCoinAccounts } from "@/api/finance.js"
import { getNftByID, nftBuy, nftSell, nftOffer, getCollectionByID, getNftEvents, nftOfferCancel, nftOfferAccept, nftCancelListing, nftTransfer} from '@/api/nft.js'
import { mapGetters } from 'vuex'
export default {
	name: 'Collection',
	components: {
		vHeader,
		vFooter
	},
	data () {
		return {
			buyVisible: false,
			offerVisible: false,
			sellVisible: false,
			nftInfo: {},
			offerDay: 1,
			offerEndTimestamp: 0,
			offerEndTime: '',
			offerPrice: 0,
			events: [],//nft事件
			assets: [],//资产
			usdtAsset: 0,//USDT资产
			
		}
	},
	computed: {
		...mapGetters(['name','tokenStandard','blockchain','fee','paymentTokenId','collectionId','collectionName'])
	},
	methods: {
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
		//选择订单过期时间
		changeDate() {
			this.offerEndTimestamp = parseInt(new Date().getTime()/1000)+ 60*60*24*this.offerDay
			this.offerEndTime = this.getLocalTime(this.offerEndTimestamp)
		},
		//显示订单详情
		showOrder(tag) {
			//没有登录
			if(!this.name) {
				this.$toast({
					message: this.$t('common.loginFirst'),
					duration: 1000
				})
				return
			}
			this.buyVisible = tag
		},
		//显示make offer
		showOffer(tag) {
			//没有登录
			if(!this.name) {
				this.$toast({
					message: this.$t('common.loginFirst'),
					duration: 1000
				})
				return
			}
			this.offerVisible = tag
			if(tag) {
				this.changeDate()
			}
		},
		//显示卖单详情
		showSell(tag) {
			this.sellVisible = tag
			if(tag) {
				this.changeDate()
			}
		},
		//buy
		buy(id) {
			let data = {
				price: this.nftInfo.offerPrice
			}
			if(this.nftInfo.offerPrice >= this.usdtAsset) {
				this.$toast({
					message: this.$t('transfer.insufficient'),
					duration: 2000
				})
				this.showOffer(false)
				return false
			}
			nftBuy(id, data).then(data => {
				console.log(data)
				this.$toast({
					message: this.$t('nft.buySuc'),
					duration: 2000
				})
				this.showOrder(false)
				this.refresh()
			}).catch(err => {
				this.showOffer(false)
				this.$toast({
					message: err,
					duration: 2000
				})
			})
		},
		//下架
		cancelListing(id) {
			nftCancelListing(id).then(data => {
				console.log(data)
				this.$toast({
					message: this.$t('nft.cancelSuc'),
					duration: 2000
				})
				this.refresh()
			})
		},
		//刷新页面
		refresh() {
			setTimeout(() => {
				history.go(0)
			},1000)
		},
		//makeOffer
		makeOffer(id) {
			if(this.nftInfo.offerPrice >= this.usdtAsset) {
				this.$toast({
					message: this.$t('transfer.insufficient'),
					duration: 2000
				})
				this.showOffer(false)
				return false
			}
			let data = {
				price: this.offerPrice,
				expirationTime: this.offerEndTimestamp
			}
			nftOffer(id, data).then(data => {
				console.log(data)
				this.showOffer(false)
				this.$toast({
					message: this.$t('nft.offerSuc'),
					duration: 2000
				})
				this.refresh()
			}).catch(err => {
				this.showOffer(false)
				this.$toast({
					message: err,
					duration: 2000
				})
			})
		},
		//sell
		sell(id) {
			let data = {
				price: this.offerPrice,
				expirationTime: this.offerEndTimestamp
			}
			nftSell(id, data).then(data => {
				console.log(data)
				this.showSell(false)
				this.$toast({
					message: this.$t('nft.sellSuc'),
					duration: 2000
				})
				this.refresh()
			}).catch(err => {
				this.showSell(false)
				this.$toast({
					message: err,
					duration: 2000
				})
			})
		},
		//transfer
		transfer() {
			this.$messagebox({
				$type: 'prompt',
				title: this.$t('nft.transfer'),
				message: this.$t('nft.transferAccount'),
				showInput: true,
				showCancelButton: true,
			}).then(({value, action}) => {
				console.log(value)
				console.log(action)
				if(action == 'confirm') {
					nftTransfer(this.nftInfo.id, value).then(data => {
						console.log(data)
						this.$toast({
							message: this.$t('nft.transferSuc'),
							duration: 2000
						})
					}).catch(err => {
						this.$toast({
							message: err,
							duration: 2000
						})
					})
				} else {
					//console.log('取消')
				}
			})
			// nftTransfer(id, email).then(data =>{
			// 	console.log(data)
			// })
		},
		//cancelOffer
		cancelOffer(id, bidderId) {
			nftOfferCancel(id, bidderId).then(data => {
				console.log(data)
				this.nftInfo = data
				this.$toast({
					message: this.$t('nft.offerCancelSuc'),
					duration: 1000
				})
				this.refresh()
			})
		},
		//acceptOffer
		acceptOffer(id, bidderId) {
			nftOfferAccept(id, bidderId).then(data => {
				console.log(data)
			})
		},
		//查询集合信息
		fetchData(collectionId) {
			getCollectionByID(collectionId).then(data => {
				console.log(typeof(data))
				this.collection = data
				this.$store.commit('SET_COLLID', this.collection.id)
				this.$store.commit('SET_COLLNAME', this.collection.name)
				this.$store.commit('SET_TOKENSTANDARD', this.collection.tokenStandard)
				this.$store.commit('SET_BLOCKCHAIN', this.collection.blockchain)
				this.$store.commit('SET_FEE', this.collection.sellFee)
				this.$store.commit('SET_PAYMENTTOKENID', this.collection.paymentTokenId)
			})
		},
		//资产筛选
		assetFilter(baseCoinId) {
			if(!this.assets.length) { //还未获得资产
				return []
			}
			return this.assets.filter(item => {
				return item.coinId == baseCoinId
			})[0]
		},
	},
	mounted () {
		let id = this.$route.query.id
		getNftByID(id).then(data => {
			console.log(data)
			this.nftInfo = data
			if(this.collectionId == '') {
				this.fetchData(this.nftInfo.collectionId)
			}
			getNftEvents(this.nftInfo.id).then(data => {
				this.events = data.items
			})
		})
		//获取用户资产
		if(this.name) { //判断是登录状态
			getCoinAccounts().then(data => {
				this.assets = data.items
				this.usdtAsset = this.assetFilter('usdt')
			})
		}
	}
}
</script>

<style scoped>
.box {
	padding:56px 10px 60px;
}
.name {
	border-top: 2px #f7f7f7 solid;
	padding: 20px 0;
	font-size: 16px;
	color: rgb(32, 129, 226)
}
.id {
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 20px;
}
.pic {
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 20px;
}
.own {
	color: rgb(112, 122, 131);
	font-size: 14px;
}
.own span {
	margin-right: 20px;
}
.own span i.owner {
	color: rgb(32, 129, 226);
}
.trade {
	margin-top: 20px;
	border-radius: 10px;
	border: 1px solid rgb(229, 232, 235);
	overflow: hidden;
}
.trade .time {
	border-bottom: 1px solid rgb(229, 232, 235);
	font-weight: 400;
	font-size: 16px;
	color: rgb(53, 56, 64);
	padding: 20px;
	background-color: rgb(255, 255, 255);
}
.buy {
	background-color: rgb(251, 253, 255);
	padding: 12px;
}
.buy .price {
	font-size: 30px;
	margin-bottom: 8px;
	line-height: 46px;
	font-weight: bold;
}
.btns button {
	height: 60px;
	line-height: 60px;
	margin-bottom: 10px;
	border-radius: 8px;
}
.offerBtn {
	border: 1px solid rgb(229, 232, 235);
	color:#26a2ff;
}
.trade .tit {
	border-bottom: 1px solid rgb(229, 232, 235);
	font-weight: 400;
	font-size: 16px;
	color: rgb(53, 56, 64);
	padding: 20px;
	background-color: rgb(255, 255, 255); 
	font-weight: bold;
}
.trade .tit span.iconfont {
	margin-right: 10px;
	font-size: 22px;
	vertical-align: middle;
}
.offer .list {
	overflow-x: scroll;
}
.offer .list .nav {
	display: flex;
	padding: 5px 16px;
	border-bottom: 1px solid rgb(229, 232, 235);
	font-size: 14px;
}
.offer .list span {
	text-align: center;
}
.offer .list .item{
	display: flex;
	padding: 18px 16px;
	border-bottom: 1px solid rgb(229, 232, 235);
	font-size: 13px;
	line-height: 34px;
}
/* .offer .list .item span {
	width: 33.3%;
} */
.offer .list span.price {
	width: 110px;
}
.offer .list span.date {
	width: 140px;
}
.offer .list span.action {
	width: 150px;
}
.offer .list span.from {
	width: 150px;
}
.des .cnt {
	padding: 30px;
	font-size: 14px;
	background-color: rgb(251, 253, 255);
}
.des .cnt p span {
	font-weight: bold;
}
.detail .cnt {
	padding: 20px 15px;
	font-size: 14px;
	background-color: rgb(251, 253, 255);
	color: rgb(53, 56, 64);
	font-weight: 500;
}
.detail .cnt p {
	line-height: 26px;
}
.detail .cnt p span {
	float: right;
}
.blue {
	color: rgb(32, 129, 226);
}
.activity .list {
	overflow-y: scroll;
}
.scroll {
	min-width: 580px;
}
.scroll2 {
	min-width: 624px;
}
.activity .list .nav {
	display: flex;
	padding: 5px 16px;
	border-bottom: 1px solid rgb(229, 232, 235);
	font-size: 14px;
}
.activity span {
	display: inline-block;
	text-align: center;
}
.activity span.event {
	width: 80px
}
.activity span.price {
	width: 100px;
}
.activity span.from {
	width: 140px
}
.activity span.to {
	width: 140px
}
.activity span.date {
	width: 130px
}
.activity .list .item{
	_display: flex;
	padding: 18px 12px;
	border-bottom: 1px solid rgb(229, 232, 235);
	font-size: 13px;
	line-height: 26px;
}
.mint-popup-bottom {
	width: 100%;
}
.orderInfo {
	padding: 20px 15px;
	position: relative;
}
.orderInfo h1 {
	margin-bottom: 10px;
}
.orderInfo p {
	line-height: 24px;
	font-size: 13px;
}
.orderInfo .buttons {
	position: absolute;
	right: 10px;
	top: 15px;
	width: 100px;
}
.orderInfo .buttons button {
	font-size: 14px;
	margin:10px 0 5px 0;
	min-width: 100px;
}
.offerInfo {
	width: 290px;
	padding: 10px 10px 10px 20px;
}
.offerInfo h1 {
	text-align: center;
	line-height: 40px;
	margin-bottom: 20px;
}
.oPrice, .oTime  {
	overflow: hidden;
	margin: 10px 0;
}
.oPrice input {
	width: 162px;
}
.oPrice input, .paymentTokenId{
	border: 1px #eee solid;
	border-radius: 5px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	float: left;
	margin-right: 10px;
	padding: 0 10px;
}
.paymentTokenId {
	font-weight: bold;
	width: 86px;
	color: #999;
	text-align: center;
}
.oTime select {
	width: 108px;
	text-align: center;
}
.oTime select, .endTime {
	border: 1px #eee solid;
	border-radius: 5px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	float: left;
	margin-right: 10px;
	padding: 0 10px;
}
.endTime {
	width: 140px;
	color: #999;
	font-size: 12px;
}
.offerInfo .buttons {
	margin-top: 40px;
}
.balance {
	font-size: 12px;
	float: right;
	padding-right: 10px;
	color: #999;
}
</style>
