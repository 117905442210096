import axios from 'axios'
import store from '@/store'
import { getToken } from "@/utils/auth"
import { Toast } from 'mint-ui'
import router from '@/router'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: localStorage.getItem('langId') || 'en',
	messages: {
		'zh-Hans': require('../assets/lang/zh'),
		'zh-Hant': require('../assets/lang/zh_fan'),
		'en': require('../assets/lang/en'),
		'ja': require('../assets/lang/jp')
	}
})

const service = axios.create({
	baseURL: 'https://fs.aiseex.com',
	//baseURL: 'https://testfs.vamnex.com',
	timeout: 5000,
	headers: {
		'Accept-Language': 'en'
	}
})

service.interceptors.request.use(
	config => {
		//设置登录请求的请求头
		config.headers['Content-Type'] = 'application/json'
		config.headers['Accept-Language'] = localStorage.getItem('langId') || 'en'
		if(store.getters.token) {
			config.headers['Authorization'] = 'bearer ' + getToken()
		}
		return config
	},err => {
		return Promise.reject(err)
	}
)

service.interceptors.response.use(
	response => {
		return response.data
	},error => {
		if(!error.response) {
			Toast({
				message: i18n.t('req.ts1'),
				duration: 2000
			})
			// 跳转到登陆页面
			//router.push('/login')
			return
		}
		const status = error.response.status
		//console.log(error.response.data.error.message)
		//格式错误
		if(status == 400) {
			let msg = error.response.data.error.validationErrors[0].message
			return Promise.reject(msg)
		}
		if(status == 415) {
			Toast({
				message: i18n.t('req.ts2'),
				duration: 2000
			})
		}
		//请求提示
		if(status == 403) {
			let msg = error.response.data.message
			return Promise.reject(msg)
		}
		//身份过期
		if(status == 401) {
			Toast({
				message: i18n.t('req.ts3'),
				duration: 2000
			})
			this.$store.dispatch('LogOut').then(()=>{
				router.push('/login')
			})
		}
		//服务器出错
		if(status == 500) {
			Toast({
				message: i18n.t('req.ts4'),
				duration: 2000
			})
		}
		return Promise.reject(error)
	}
)

export default service