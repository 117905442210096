<template>
	<div class="about">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{name}}{{$t('assets.name')}} nfts
		</div>
		<div class="list">
			<div class="item" @click="goNft(item.id)" v-for='item of assets' :key="item.id">
				<div class="img"><img :src="item.imageUrl"></div>
				<div class="detail">
					<h1>{{item.name}}</h1>
					<div class="price">
						<p>Price</p>
						<p class="num">{{item.offerPrice || '***'}} <em class="uppercase">USDT</em></p>
					</div>
					<div class="last">
						Last sale: {{item.lastSalePrice || 0}} USDT
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAssets } from '@/api/nft.js'
import { mapGetters } from 'vuex'
export default {
	name: 'Assets',
	data () {
		return {
			assets: [],//资源列表
		}
	},
	computed: {
		...mapGetters(['name'])
	},
	methods: {
		//去到某个NFT图片
		goNft(id) {
			this.$router.push({
				path: '/nft',
				query: {
					id
				}
			})
		},
	},
	mounted () {
		getAssets().then(data => {
			console.log(data)
			this.assets = data.items
		})
	}
}
</script>

<style scoped>
.back {
	text-align: center;
	height: 50px;
	line-height: 50px;
	padding-right: 60px;
}
.back span {
	float: left;
	width: 60px;
}
.list {
	overflow: hidden;
	margin-top: 18px;
	padding: 10px;
}
.list .item {
	width: 49%;
	float: left;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	box-shadow: #ddd 0px 1px 6px;
	overflow: hidden;
	box-sizing: border-box;
	margin-bottom: 10px;
}
.list .item:nth-child(2n) {
	margin-left: 1%;
}
.list .item:nth-child(2n+1) {
	margin-right: 1%;
}
.list .item img{
	margin: 5%;
	width: 90%;
}
.item .detail {
	padding: 12px;
	font-size: 14px;
}
.item .detail .price {
	margin-top: 10px;
	font-size: 12px;
	font-weight: bold;
}
.item .detail .price .num {
	font-size: 14px;
}
.item .detail .last {
	margin-top: 20px;
	color: #999;
	font-size: 13px;
}
</style>