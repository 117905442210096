import request from "@/utils/request.js"

// 用户名注册
export function registerByUsername(data) {
	return request({
		url: '/api/accounts/register',
		data,
		method: 'post',
	})
}

// 用户名登陆
// export function loginByUsername(username, password) {
// 	const data = {
// 		username,
// 		password,
// 		grant_type: 'password',
// 		client_id: 'client_app',
// 		client_secret: 'q1w2e3r4'
// 	}
// 	return request({
// 		url: '/connect/token',
// 		method: 'post',
// 		data
// 	})
// }

//获取用户信息
export function getUserInfo () {
	return request({
		url: '/api/accounts',
		method: 'get',
	})
}

//修改用户信息
export function changeUserInfo (data) {
	return request({
		url: '/api/accounts',
		method: 'put',
		data
	})
}


//用户发送邮件
export function sendEmail(email,purpose) {
	const data = {
		email,
		purpose
	}
	return request({
		url: '/api/accounts/send-email-vcode',
		method: 'post',
		data
	})
}

//获取用户实名信息
export function getIdentity() {
	return request({
		url: '/api/accounts/identity',
		method: 'get',
	})
}

//用户实名
export function identity(data) {
	return request({
		url: '/api/accounts/identity',
		method: 'post',
		data
	})
}

//设置用户登录密码,忘记登录密码
export function setLoginPassword(data) {
	return request({
		url: '/api/accounts/login-password',
		method: 'post',
		data
	})
}

//设置用户资金密码,忘记资金密码
export function setAssetPassword(data) {
	return request({
		url: '/api/accounts/asset-password',
		method: 'post',
		data
	})
}

//修改用户资金密码
export function changeAssetPassword(data) {
	return request({
		url: '/api/accounts/asset-password',
		method: 'put',
		data
	})
}

//修改用户登录密码
export function changeLoginPassword(data) {
	return request({
		url: '/api/accounts/login-password',
		method: 'put',
		data
	})
}

//用户反馈
export function feedback(data) {
	return request({
		url: '/api/feedbacks',
		method: 'post',
		data
	})
}
//获取用户反馈
export function getFeedbacks() {
	return request({
		url: '/api/feedbacks',
		method: 'get'
	})
}
//获取反馈详情
export function getFeedbackByID(id) {
	return request({
		url: '/api/feedbacks/'+id,
		method: 'get'
	})
}