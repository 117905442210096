import request from "@/utils/request.js"

//获取客户nft账户
export function getNftAccount() {
	return request({
		url: '/api/nft/account',
		method: 'get',
	})
}

//获取账户的全部asset
export function getAssets() {
	return request({
		url: '/api/nft/account/assets',
		method: 'get',
	})
}

// 获取所有的nft集合
export function getCollections (params) {
	return request({
		url: '/api/nft/collections/rankings',
		method: 'get',
		params
	})
}

// 根据ID获取nft集合信息
export function getCollectionByID (id) {
	return request({
		url: '/api/nft/collections/'+id,
		method: 'get'
	})
}

// 根据集合ID获取集合的全部nft元素
export function getAssetsByID (data) {
	return request({
		url: '/api/nft/assets/query',
		method: 'post',
		data
	})
}

// 根据ID获取单个nft图片信息
export function getNftByID (id) {
	return request({
		url: '/api/nft/assets/'+id,
		method: 'get'
	})
}

// 根据ID获取单个nft的事件信息
export function getNftEvents (id) {
	return request({
		url: '/api/nft/assets/'+id+'/events',
		method: 'get'
	})
}

//卖nft
export function nftSell(id, data) {
	return request({
		url: '/api/nft/assets/'+ id +'/sell',
		method: 'post',
		data
	})
}

//取消nft卖单
export function nftCancelListing(id) {
	return request({
		url: '/api/nft/assets/'+ id +'/cancel-listing',
		method: 'post'
	})
}

//买nft
export function nftBuy(id, data) {
	return request({
		url: '/api/nft/assets/'+ id +'/buy',
		method: 'post',
		data
	})
}

//offer某nft
export function nftOffer(id, data) {
	return request({
		url: '/api/nft/assets/'+ id +'/offer',
		method: 'post',
		data
	})
}

//接收offer
export function nftOfferAccept(id, bidderId) {
	return request({
		url: '/api/nft/assets/'+ id +'/accept-offer/' + bidderId,
		method: 'post'
	})
}

//取消offer
export function nftOfferCancel(id, bidderId) {
	return request({
		url: '/api/nft/assets/'+ id +'/cancel-offer/' + bidderId,
		method: 'post'
	})
}

//转移nft
export function nftTransfer(id, email) {
	return request({
		url: '/api/nft/assets/'+ id +'/transfer/' + email,
		method: 'post'
	})
}