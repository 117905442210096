<template>
	<div class="share">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('share.earnUsdt')}}
		</div>
		<div class="share-cnt">
			<p class="intro">{{$t('share.intro')}}</p>
			<div class="code-box">
				<div class="code" id="qrcode" ref="qrcode">
					<!-- <img src="../assets/img/coin/bitcoin.png"> -->
				</div>
				<div class="inviteCode">{{$t('share.code')}}:<span>{{inviteCode}}</span></div>
				<mt-button type="primary" size="large"
				v-clipboard:copy="shareLink"
				v-clipboard:success="onCopy"
				v-clipboard:error="onError">{{$t('share.copy')}}</mt-button>
			</div>
			<div class="steps">
				<div>
					<span>01</span>
					<p>{{$t('share.step1')}}</p>
				</div>
				<p class="dot">...</p>
				<div>
					<span>02</span>
					<p>{{$t('share.step2')}}</p>
				</div>
				<p class="dot">...</p>
				<div>
					<span>03</span>
					<p>{{$t('share.step3')}}</p>
				</div>
			</div>
			<div class="info">
				<h1>{{$t('share.data')}}</h1>
				<div>
					<div>
						<p>{{$t('share.register')}}</p>
						<p class="num">{{registerData.count}}</p>
					</div>
					<div>
						<p>{{$t('share.recharge')}}</p>
						<p class="num">{{rechargeData.count}}</p>
					</div>
					<div>
						<p>{{$t('share.commission')}}</p>
						<p class="num">{{registerData.passedAmount+rechargeData.passedAmount}} USDT</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getShare } from '@/api/share.js' 
import QRCode from 'qrcodejs2'
export default {
	name: 'Share',
	data () {
		return {
			shareLink: '',//分享链接
			registerData: {},//注册数据
			rechargeData: {},//充值数据
		}
	},
	computed: {
		...mapGetters(['inviteCode'])
	},
	methods: {
		//生产二维码
		qrcodeFunc (url) {
			this.qr = new QRCode('qrcode', {
				width: 150, // 设置宽度，单位像素
				height: 150, // 设置高度，单位像素
				text: url // 设置二维码内容或跳转地址
			})
		},
		//复制
		onCopy () {
			this.$toast({
				message: this.$t('share.copySuc'),
				duration: 1000
			})
		},
		//复制出错
		onError () {
			this.$toast({
				message: this.$t('share.copyErr'),
				duration: 1000
			})
		},
	},
	mounted () {
		setTimeout(() => {
			this.shareLink = document.domain +'/#/register?in=' + this.inviteCode
			this.qrcodeFunc(this.shareLink)
		},100)
		//获取邀请注册数据
		getShare('invite_register').then(data => {
			this.registerData = data
			//console.log(this.registerData)
		})
		//获取邀请充值数据
		getShare('subuser_recharge').then(data => {
			this.rechargeData = data
			//console.log(this.rechargeData)
		})
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.share-cnt {
	margin: 20px 15px;
	text-align: center;
}
.share-cnt p.intro {
	font-size: 12px;
}
.code-box {
	border: 1px #edede6 solid;
	border-radius: 5px;
	padding: 20px;
	margin-top: 12px;
}
.code {
	text-align: center;
	width: 150px;
	margin: 10px auto 5px;
	border: 5px #fff solid;
}
.inviteCode {
	font-size: 13px;
	margin-bottom: 20px;
}
.steps {
	display: flex;
	margin-top: 20px;
	font-size: 14px;
	border-bottom: 1px #eee dashed;
	padding-bottom: 10px;
}
.steps div{
	flex: 1;
}
.steps p.dot {
	font-size: 30px;
}
.steps div span {
	display: inline-block;
	width: 36px;
	height: 36px;
	background-color: red;
	border-radius: 50%;
	line-height: 36px;
	background-color: #fdf745;
	margin-bottom: 5px;
}
.info {
	border: 1px #eee solid;
	margin: 20px 5px;
	padding: 20px;
	border-radius: 5px;
}
.info h1 {
	font-size: 15px;
	margin-bottom: 20px;
}
.info >div {
	display: flex;
	font-size: 13px;
}
.info >div div{
	flex: 1;
}
.info >div div p {
	border-right: 1px #ddd dashed;
}
.info >div div:nth-child(3) p {
	border-right: none;
}
p.num {
	font-size: 12px;
	color: #999;
}

</style>
