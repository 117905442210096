<template>
	<div class="news">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('platform.tit')}}
		</div>
		<div class="intro">
			<div>
				<span>*</span>
				<p>{{$t('platform.ts1')}}</p>
			</div>
			<div>
				<span>*</span>
				<p>{{$t('platform.ts2')}}</p>
			</div>
		</div>
		<div class="list">
			<!-- <div class="item">
				<div class="num">1</div>
				<div class="img"><img src="../assets/img/coin/bitcoin.png"></div>
				<div class="cnt">
					<h1>币安</h1>
					<p>国际领先的区块链数字资产国际站,目前用户覆盖全球180多个国家和地区</p>
				</div>
			</div>
			<div class="item">
				<div class="num">2</div>
				<div class="img"><img src="../assets/img/coin/bitcoin.png"></div>
				<div class="cnt">
					<h1>币安</h1>
					<p>国际领先的区块链数字资产国际站,目前用户覆盖全球180多个国家和地区</p>
				</div>
			</div> -->
			<div class="item" v-for="(item, index) of plotforms" :key="index">
				<div class="num">{{index+1}}</div>
				<div class="img"><img :src="item.resourceUrl"></div>
				<div class="cnt">
					<h1>{{item.title}}</h1>
					<p>{{item.subTitle}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getPlatform } from '@/api/setting.js'
export default {
	name: 'Platform',
	data () {
		return {
			plotforms: [],//平台列表
		}
	},
	methods: {

	},
	mounted () {
		getPlatform().then(data => {
			this.plotforms = data.items.reverse()
			//console.log(data)
		})
	}
}
</script>

<style scoped>
.back {
	text-align: center;
	height: 50px;
	line-height: 50px;
	padding-right: 60px;
}
.back span {
	float: left;
	width: 60px;
}
.intro {
	margin: 10px 10px 0;
	padding: 10px;
	border:1px #eee dashed;
	font-size: 12px;
}
.intro div {
	display: flex;
}
.intro div p {
	flex: 1;
}
.intro div span {
	width: 16px;
	color: red;
	font-size: 16px;
	line-height: 24px;
}
.list {
	padding: 5px 10px 10px;
}
.item {
	display: flex;
	border-bottom: 1px #eee solid;
	padding: 15px 10px;
}
.item .num {
	line-height: 60px;
	width: 28px;
	font-size:20px;
	font-style: italic;
}
.item .img {
	width: 60px;
	line-height: 60px;
}
.item .img img {
	width: 40px;
	height: 40px;
}
.item .cnt {
	flex: 1;
}
.item .cnt h1{
	font-size: 14px;
}
.item .cnt p{
	font-size: 12px;
	color: #999;
}
</style>
