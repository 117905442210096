<template>
	<div>
		<div class="pageNav">
			<a @click="$router.goBack()" class="fl"><i class="iconfont">&#xe607;</i></a>
		</div>
		<div class="regBox">
			<h4>{{$t('email.tit')}}</h4>
			<p>{{$t('email.check')}}</p>
			<div class="loginFrom">
				<div class="wrap"></div>
				<div class="inputBox">
					<input type="text" :placeholder="$t('email.plsInput')" v-model="code"/>
				</div>
				<p class="getCode fr"><span v-if="second <= 0" @click="getEmailCode()">{{$t('email.again')}} </span> <span v-if="second > 0">{{second}} {{$t('email.second')}}</span></p>
				<button :disabled="!code" class="submit" @click="next()">{{$t('common.next')}}</button>
			</div>
		</div>
	</div>
</template>

<script>
import { sendEmail } from "@/api/user.js"
export default {
	name: 'Email',
	data () {
		return {
			email: '',
			countryId: '',
			code: '',
			second: 0,//发送完验证码之后的秒数
		}
	},
	activated () {
		this.email = this.$route.query.email,
		this.countryId =this.$route.query.countryId
		//console.log(this.email, this.countryId)
	},
	methods: {
		//注册
		next() {
			sessionStorage.setItem('code', this.code)
			this.$router.push({
				path: '/setpwd',
			})
		},
		//获取验证码
		getEmailCode() {
			sendEmail(sessionStorage.getItem('email'), 'register').then(() => {
				this.$toast({
					message: this.$t('email.sendSuc'),
					duration: 1000
				})
				sessionStorage.setItem('second', 60)
				this.second = 60
				let timer = setInterval(() => {
					this.second --
					sessionStorage.setItem('second', this.second)
					if(this.second <= 0) {
						clearInterval(timer)
					}
				},1000)
			})
		}
	},
	mounted () {
		//获取发送验证码的秒数
		this.second = sessionStorage.getItem('second')
		//alert(this.second)
		if(this.second) {
			let timer = setInterval(() => {
				this.second --
				sessionStorage.setItem('second', this.second)
				if(this.second <= 0) {
					clearInterval(timer)
				}
			},1000)
		}
	}
}
</script>

<style scoped>
	.pageNav {
			height: 60px;
			padding: 0 30px;
			line-height: 60px;
	}
	.pageNav a {
		height: 60px;
		color: #1882d4;
	}
	.pageNav a i {
		color: #8c9fad;
	}
	.regBox {
			font-size: 16px;
			height: 80px;
			padding:30px 30px 0;
	}
	.regBox h4 {
		font-size: 30px;
		font-weight: normal;
		line-height: 40px;
	}
	.loginFrom {
		margin: 50px 0 30px;
	}
	.country {
		margin: 20px 0;
		font-size: 18px;
	}
	.inputBox {
		border-bottom: 1px solid #c5cfd5;
		margin-top: 30px;
	}
	.inputBox input {
		width: 100%;
		line-height:40px;
		font-size: 20px;
		font-weight: bold;
		color: #1f3f59;
	}
	.getCode {
		margin-bottom: 45px;
	}
	.regBox button.submit {
		width: 100%;
		height: 52px;
		font-size: 16px;
		color: #fff;
		background: #1882d4;
	}
	.regBox button.submit:disabled {
		background: #c5cfd5;
	}
	.regBox p {
		height: 28px;
		line-height: 28px;
		color: #8c9fad;
		font-size: 15px;
	}
	.regBox p.warning {
		color: #d14b64;
	}
	.warning i {
		font-size: 20px;
		vertical-align: middle;
	}
	.arrow {
		top: -3px;
		left:3px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}
	.arrow:before {
		content: "";
		position: absolute;
	}
	.arrow:before {
		top: 0;
		left: 0;
		border-top: 6px solid #c5cfd5;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
	}
	.wrap {
		padding-top: 1px;
	}
</style>
