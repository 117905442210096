<template>
	<div class="news">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('news.detail')}}
		</div>
		<div class="box">
			<div class="tit">{{notice.title}}</div>
			<div class="time">{{getLocalTime(notice.creationTime)}}</div>
			<div class="cnt" v-html="cntHtml"></div>
		</div>
	</div>
</template>

<script>
import { timestampToTime } from "@/utils/third.js"
import { getNotices, getHtml } from '@/api/setting.js'
export default {
	name: 'New',
	data () {
		return {
			notice: {} ,//消息内容
			cntHtml: '',//内容HTML
		}
	},
	methods: {
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS-60*60*24*365*1.5)
		},
	},
	mounted () {
		let id = this.$route.query.id
		getNotices().then(data => {
			this.notice = data.items.filter(item => {
				return item.id == id
			})[0]
			
			getHtml(this.notice.resourceUrl).then(data => {
				console.log(data)
				this.cntHtml = data
			})
		})
	}
}
</script>

<style scoped>
.back {
	text-align: center;
	height: 50px;
	line-height: 50px;
	padding-right: 60px;
}
.back span {
	float: left;
	width: 60px;
}
.box {
	padding: 20px 15px;
	text-align: center;
}
.box .tit {
	font-size: 20px;
}
.time {
	font-size: 12px;
	margin-top: 8px;
	color: #999;
}
.box .cnt {
	margin-top: 20px;
	font-size: 14px;
}
</style>
