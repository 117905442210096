import request from "@/utils/request2.js"
import { getFreshToken } from "@/utils/auth"
import qs from 'qs'
// 用户名登陆
export function loginByUsername(username, password) {
	const data = qs.stringify({
		username,
		password,
		grant_type: 'password',
		client_id: 'client_app',
		client_secret: 'q1w2e3r4'
	})
	return request({
		url: '/connect/token',
		method: 'post',
		data
	})
}

//更新token
export function refreshToken() {
	const data = qs.stringify({
		grant_type: 'refresh_token',
		client_id: 'client_app',
		client_secret: 'q1w2e3r4',
		refresh_token: getFreshToken()
	})
	return request({
		url: '/connect/token',
		method: 'post',
		data
	})
}
