<template>
	<div class="news">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('nfts.tit')}}<!-- (collectibles) -->
		</div>
		<div class="intro">
			<div>
				{{$t('nfts.des')}}
				<!-- （Discover, collect, and sell extraordinary NFTs） -->
			</div>
			<p>{{siteName}}{{$t('nfts.detail')}}
			<!-- Vceex is It is a large and complete NFT marketplace -->
			</p>
		</div>
		<div class="list">
			<div class="item" @click="goCollection(item.name,item.id)" v-for="(item, index) of collections" :key="item.id">
				<div class="num">{{index+1}}</div>
				<div class="img"><img :src="item.logoImageUrl"></div>
				<div class="cnt">
					<h1>{{item.name}}</h1>
					<p>Floor Price: {{item.floorPrice}} <em class="uppercase">{{item.paymentTokenId}}</em></p>
				</div>
				<div class="inc">
					<h1 class="green">{{(item.rankingChange*100).toFixed(2)}}%</h1>
					<p>{{parseInt(item.rankingVolume)}} {{item.paymentTokenId}}</p>
				</div>
			</div>
			<!-- <div class="item">
				<div class="num">2</div>
				<div class="img"><img src="../assets/img/nft-ape.png"></div>
				<div class="cnt">
					<h1>Bored Ape Yacht Club</h1>
					<p>Floor Price: 80000 u</p>
				</div>
				<div class="inc">
					<h1 class="green">+54.12%</h1>
					<p>128374 u</p>
				</div>
			</div>
			<div class="item">
				<div class="num">3</div>
				<div class="img"><img src="../assets/img/nft-ape.png"></div>
				<div class="cnt">
					<h1>Bored Ape Yacht Club</h1>
					<p>Floor Price: 80000 u</p>
				</div>
				<div class="inc">
					<h1 class="green">+54.12%</h1>
					<p>128374 u</p>
				</div>
			</div>
			<div class="item">
				<div class="num">4</div>
				<div class="img"><img src="../assets/img/nft-ape.png"></div>
				<div class="cnt">
					<h1>Bored Ape Yacht Club</h1>
					<p>Floor Price: 80000 u</p>
				</div>
				<div class="inc">
					<h1 class="green">+54.12%</h1>
					<p>128374 u</p>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import { getCollections, getNftAccount } from '@/api/nft.js'
import { mapGetters } from 'vuex'
export default {
	name: 'Nfts',
	data () {
		return {
			collections: [],//nft集合
		}
	},
	computed: {
		...mapGetters(['sitename'])
	},
	methods: {
		//获取所有nft集合排行榜
		fetchData(params) {
			getCollections(params).then(data => {
				console.log(data)
				this.collections = data.items
			})
		},
		//进入集合页面
		goCollection(name, id) {
			this.$router.push({
				path: '/collection',
				query: {
					name,
					id
				}
			})
		}
	},
	mounted () {
		let params = {
			category: 'Day7',
			count: 10
		}
		this.fetchData(params)
		getNftAccount().then(data=> {
			console.log(data)
		})
	}
}
</script>

<style scoped>
.back {
	text-align: center;
	height: 50px;
	line-height: 50px;
	padding-right: 60px;
}
.back span {
	float: left;
	width: 60px;
}
.intro {
	margin: 10px 10px 0;
	padding: 20px 10px;
	border:1px #eee dashed;
	font-size: 12px;
	text-align: center;
}
.intro div {
	font-size: 18px;
	font-weight: bold;
}
.intro p {
	font-size: 13px;
	margin-top: 12px;
}
.list {
	padding: 5px 10px 10px;
}
.item {
	display: flex;
	border-bottom: 1px #eee solid;
	padding: 15px 10px;
}
.item .num {
	line-height: 60px;
	width: 28px;
	font-size:16px;
	font-style: normal;
	font-weight: bold;
}
.item .img {
	width: 70px;
	line-height: 60px;
}
.item .img img {
	width: 54px;
	height: 54px;
}
.item .cnt {
	flex: 1;
	padding: 10px 0;
}
.item .cnt h1{
	font-size: 14px;
}
.item .cnt p{
	margin-top: 5px;
	font-size: 14px;
	color: #999;
}
.inc {
	padding: 10px 0;
}
.inc h1 {
	font-size: 15px;
	font-weight: normal;
	text-align: right;
}
.inc p {
	margin-top: 5px;
	font-size: 15px;
	color: #999;
	font-weight: bold;
}
</style>
