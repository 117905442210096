<template>
	<div class="about">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('about.tit')}}
		</div>
		<div class="list">
			<div class="item" 
			v-for="about of abouts" 
			@click="toAbout(about.id)"
			:key="about.id">
				{{about.title}}<span class="iconfont fr">&#xe62a;</span>
			</div>
			<!-- <div class="item">公告1<span class="iconfont fr">&#xe62a;</span></div> -->
		</div>
	</div>
</template>

<script>
import { getAbout } from '@/api/setting.js'
export default {
	name: 'About',
	data () {
		return {
			abouts: [],//关于我们
		}
	},
	methods: {
		toAbout(id) {
			this.$router.push({
				path: '/about-detail',
				query: {
					id
				}
			})
		}
	},
	mounted () {
		getAbout().then(data => {
			this.abouts = data.items.reverse()
		})
	}
}
</script>

<style scoped>
.back {
	text-align: center;
	height: 50px;
	line-height: 50px;
	padding-right: 60px;
}
.back span {
	float: left;
	width: 60px;
}
.list {
	padding: 20px 15px;
}
.list .item {
	border-bottom: 1px #eee solid;
	line-height: 40px;
}
</style>