import { render, staticRenderFns } from "./Identity2.vue?vue&type=template&id=9afa0c44&scoped=true&"
import script from "./Identity2.vue?vue&type=script&lang=js&"
export * from "./Identity2.vue?vue&type=script&lang=js&"
import style0 from "./Identity2.vue?vue&type=style&index=0&id=9afa0c44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9afa0c44",
  null
  
)

export default component.exports