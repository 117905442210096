<template>
	<div class="container">
		<div class="headerNav">
			<div class="search">
				<i class="iconfont">&#xe615;</i>
				<input type="text" :placeholder="$t('quotes.search')" v-model="matchName" @input="matchFunc()">
			</div>
			<div class="cat">
				<ul class="tc">
					<li :class="{'cho': type == 1}" @click="changeType(1)">
						<span>{{$t('quotes.self')}}</span>
					</li>
					<li :class="{'cho': type == 2}" @click="changeType(2)">
						<span>{{$t('quotes.all')}}</span>
					</li>
					<!-- <li>
						<span>合约</span>
					</li> -->
				</ul>
			</div>
			<div class="listNav">
				<div :class="nameStyle" @click="rangFunc('name')">
					<span>{{$t('quotes.name')}}/</span>
					<span>{{$t('quotes.volume')}}</span>
					<span class="range">
						<i class="iconfont up">&#xe603;</i>
						<i class="iconfont down">&#xe605;</i>
					</span>
				</div>
				<div :class="priceStyle" class="tc" @click="rangFunc('price')">
					<span>{{$t('quotes.price')}}</span>
					<span class="range">
						<i class="iconfont up">&#xe603;</i>
						<i class="iconfont down">&#xe605;</i>
					</span>
				</div>
				<div :class="changeStyle" class="tr" @click="rangFunc('change')">
					<span>{{$t('quotes.ranking')}}</span>
					<span class="range">
						<i class="iconfont up">&#xe603;</i>
						<i class="iconfont down">&#xe605;</i>
					</span>
				</div>
			</div>
		</div>
		<div class="wrapper">
			<div class="topList bgc">
				<div 
				@click="symbolClick(symbol.id, symbol.name)" 
				class="item"
				v-show="!symbol.showTag"
				v-for="symbol of symbolsRang" 
				:key="symbol.id">
					<div>
						<h2 class="uppercase"><em>{{symbol.baseCoinId}}</em><i>/{{symbol.quoteCoinId}}</i></h2>
						<p>24HVol {{(symbol.market.turnover).toFixed(2)}}</p>
					</div>
					<div class="tc">
						<h2>{{symbol.market.latestPrice}}</h2>
						<p>{{(symbol.market.latestPrice * exchangeRateByUSD).toFixed(2)}} <i>{{currency}}</i></p>
					</div>
					<span :class="symbol.market.change>=0 ? 'bggreen':'bgred'">{{toRateFunc(symbol.market.change)}}</span>
				</div>
				<!-- <div class="item">
					<div>
						<h2>ETH <i>/usdt</i></h2>
						<p>24HVol 9019201</p>
					</div>
					<div>
						<h2>64033.86</h2>
						<p>¥409281</p>
					</div>
					<span class="up">+2.19%</span>
				</div> -->
			</div>
			<v-nodata v-if="symbolsRang.length == 0"></v-nodata>
		</div>
		<v-footer curState="quotes"></v-footer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import vFooter from '@/components/Footer'
import { getSymbols, getSymbolsDayMarket, getFavorites } from '@/api/symbols.js'
import { toRate }from "@/utils/third.js"
import vNodata from "@/components/Nodata"
export default {
	name: 'Quotes',
	components: {
		vNodata,
		vFooter
	},
	data () {
		return {
			matchName: '',//搜索框匹配
			symbols: [] , //交易对
			symbolsRang: [],//交易对排序
			symbolsIDs: [], //交易对ID
			nameSort: 0,//名称排序
			priceSort: 0,//名称排序
			changeSort: 0,// 涨跌幅排序
			curSortTag: 'name',//当前是按什么排序,,默认是按名称排序
			type: 2, //自选1,现货全部2
			favoriteIdArr: [],//自选的交易对ID数组
		}
	},
	computed: {
		...mapGetters(['name','currency','exchangeRateByUSD']),
		nameStyle () {
			if(this.curSortTag == 'name') {
				return this.nameSort == 1 ? 'range1' : this.nameSort == -1 ? 'range2' : ''
			}else {
				return ''
			}
		},
		priceStyle () {
			if(this.curSortTag == 'price') {
				return this.priceSort == 1 ? 'range1' : this.priceSort == -1 ? 'range2' : ''
			}else {
				return ''
			}
		},
		changeStyle () {
			if(this.curSortTag == 'change') {
				return this.changeSort == 1 ? 'range1' : this.changeSort == -1 ? 'range2' : ''
			}else {
				return ''
			}
		}
	},
	methods: {
		//搜索框匹配
		matchFunc () {
			let tag = this.matchName.trim()
			this.symbolsRang.forEach((item,index) => {
				if(tag == '') {
					this.symbolsRang[index].showTag = 0
				}
				else if(item.baseCoinId.indexOf(tag) == -1) {
					this.symbolsRang[index].showTag = 1
				}else {
					this.symbolsRang[index].showTag = 0
				}
				this.$set(this.symbolsRang,index,this.symbolsRang[index])
			})
		},
		//切换选择类型
		changeType(type) {
			if(type == this.type ){
				return
			}
			this.type = type
			if(this.type == 1 && this.name) {
				this.selectFavoriteFunc()
			} else {
				this.symbolsRang = this.symbols.sort(() => {
					return 0
				})
			}
		},
		//筛选自选
		selectFavoriteFunc() {
			this.symbolsRang = this.symbolsRang.filter(item => {
				return this.favoriteIdArr.indexOf(item.id) > -1
			})
		},
		//获取自选交易对
		getFavoritesFunc() {
			if(this.name) { //判断是登录状态
				getFavorites().then( data => {
					data.items.forEach(item => {
						this.favoriteIdArr.push(item.symbolId)
					})
				})
			}else {
				return false
			}
		},
		//小数换成百分比
		toRateFunc (num) {
			return toRate(num)
		},
		//点击交易对,进入走势界面
		symbolClick(id, name) {
			this.$router.push({
				path: '/trend',
				query: {
					id,
					name
				}
			})
		},
		//排序
		rangFunc (tag) {
			this.curSortTag = tag
			if(tag == 'name') {
				this.nameSort = this.nameSort == 1 ? -1 : this.nameSort == -1 ? 0: 1
				this.symbolsRang = this.symbols.sort((a1, b1) => {
					if(this.nameSort == 0) {
						return a1.sort - b1.sort
					}
					let a = a1.name
					let b = b1.name
					if (a < b) return -1 * this.nameSort;  
					if (a > b) return 1 * this.nameSort; 
				})
				//console.log(this.symbolsRang)
			}
			if(tag == 'price') {
				this.priceSort = this.priceSort == 1 ? -1 : this.priceSort == -1 ? 0: 1
				this.symbolsRang = this.symbols.sort((a1, b1) => {
					if(this.priceSort == 0) {
						return a1.sort - b1.sort
					}
					let a = a1.market.latestPrice
					let b = b1.market.latestPrice
					if (a < b) return 1 * this.priceSort;  
					if (a > b) return -1 * this.priceSort; 
				})
				//console.log(this.symbolsRang)
			}
			if(tag == 'change') {
				this.changeSort = this.changeSort == 1 ? -1 : this.changeSort == -1 ? 0: 1
				this.symbolsRang = this.symbols.sort((a1, b1) => {
					if(this.changeSort == 0) {
						return a1.sort - b1.sort
					}
					let a = a1.market.change
					let b = b1.market.change
					if (a < b) return 1 * this.changeSort;  
					if (a > b) return -1 * this.changeSort; 
				})
				//console.log(this.symbolsRang)
			}
		},
		//订阅行情
		subscribeMarketFunc(ids) {
			//订阅行情
			console.log("---------订阅行情------------")
			this.$tradeHub.subscribeMarketEvent(ids)
			//接收实时行情数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolDayMarketChangedAsync, (data) =>{
				//console.log("---------接收行情数据------------")
				//console.log(data)
				let index = this.symbols.findIndex(symbol => symbol.id == data.symbolId)
				this.symbols[index].market = data.market
				this.$set(this.symbols,index,this.symbols[index])
				this.symbolsRang = this.symbols.sort(() => {
					return 0
				})
				if(this.type == 1) {
					this.selectFavoriteFunc()
				}
			})
		},
		//取消订阅行情
		unsubscribeMarketFunc(ids) {
			//取消订阅行情
			//console.log("---------取消订阅行情------------")
			this.$tradeHub.subscribeMarketEvent(ids, false)
		}
	},
	activated () {
		this.symbolsIDs = []
		//获取交易对信息
		getSymbols({category: 1, MaxResultCount: 20}).then(data => {
			this.symbols = data.items
			this.symbols.forEach(item => {
				this.symbolsIDs.push(item.id)
				item.market = {
					change: 0,
					highestPrice: 0,
					latestPrice: 0,
					lowestPrice: 0,
					turnover: 0,
					updateTime: 0,
					volume: 0,
				}
			})
			//首次进入初始化行情
			getSymbolsDayMarket({symbols:this.symbolsIDs.toString()}).then(data => {
				//console.log("---------首次进入初始化行情数据------------")
				//console.log(data)
				//console.log(this.symbols)
				data.items.forEach((item,index) => {
					this.symbols[index].market = item.market
					this.$set(this.symbols,index,this.symbols[index])
				})
				//排序
				this.symbolsRang = this.symbols.sort(() => {
					return 0
				})
			})
			//订阅行情
			this.subscribeMarketFunc(this.symbolsIDs)
		})
		//获取自选交易对
		this.getFavoritesFunc()
	},
	//销毁组件取消行情的订阅
	deactivated() {
		//console.log("beforeDestroy")
		if(this.symbolsIDs) {
			//取消订阅行情
			this.unsubscribeMarketFunc(this.symbolsIDs)
		}
	},
}
</script>

<style scoped>
.wrapper {
	padding-bottom: 70px;
}
.search {
	margin: 10px 10px 0;
	display: flex;
	background-color: #eee;
	border-radius: 10px;
	height: 30px;
	line-height: 30px;
}
.search i {
	padding: 0 10px;
	font-size: 13px;
	color: #999;
}
.search input {
	flex: 1;
}
.cat ul {
	display: flex;
	margin-bottom: 10px;
	height: 43px;
}
.cat ul li {
	flex: 1;
	height: 40px;
	line-height: 40px;
}
.cat ul li span {
	display: inline-block;
	height: 100%;
	font-size: 15px;
	color: #666;
}
.cat ul li.cho span {
	color: #333;
	border-bottom: 3px #ffcd36 solid;
}
.listNav {
	display: flex;
	font-size: 13px;
	padding: 5px 20px;
	color: #999;
}
.listNav div:nth-child(1) {
	flex: 1;
}
.listNav div:nth-child(2) {
	flex: 1;
}
.listNav div:nth-child(3) {
	width: 90px;
}

.topList .item {
	display: flex;
	border-bottom: 1px solid #f7f7fb;
	padding: 0 20px;
	white-space: nowrap;
}
.topList .item>div {
	flex: 1;
	padding: 10px 0;
}
.topList .item>div h2 {
	font-size: 15px;
	font-weight: normal;
	height: 26px;
	line-height: 26px;
}
.topList .item>div h2 i {
	_font-size: 14px;
	color: #999;
}
.topList .item>div p {
	font-size: 13px;
	color: #999;
}
.topList .item span{
	text-align: center;
	width: 90px;
	height: 40px;
	font-size: 16px;
	line-height: 40px;
	color: #fff;
	margin-top: 14px;
	border-radius: 3px;
	font-weight: bold;
}
.range {
	display: inline-block;
	height: 14px;
	width: 14px;
	margin-left: 3px;
	position: relative;
}
.range i {
	display: block;
	font-size: 12px;
	position: absolute;
	color: #ddd;
	transform: scale(0.7);
	-webkit-transform: scale(0.7);
}
.range i.up {
	top: -4px;
}
.range i.down {
	top: 3px;
}
.range1 .up {
	color: #666;
}
.range2 .down {
	color: #666;
}
</style>
