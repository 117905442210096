module.exports = {
	common: {
		name: '交易所',
		welcome: '歡迎來到',
		login: '登錄',
		register: '註冊',
		emailaddr: '郵箱地址',
		password: '密碼',
		later: '稍後登錄，先逛逛',
		next: '下一步',
		amount: '數量',
		price: '價格',
		direction: '方向',
		buy: '買入',
		sell: '賣出',
		loginFirst: '請先登錄',
		confirmFirst: '請先認證',
		confirmNow: '馬上去實名認證',
		cancel: '取消',
		sure: '確認',
		orderDetail: '訂單詳情',
		insufficient: '可交易USDT餘額不足',
		orderSuc: '下單成功',
		logout: '退出登錄',
		logoutSuc: '退出成功',
		sureChange: '確認修改',
		sendCode: '發送驗證碼',
		sendLater: '已發送，請稍後重試',
		time: '時間',
		success: '成功',
		revoke: '撤銷',
		errReason: '失敗原因',
		nodata: '加載中...暫無相關數據',
		verifyNow: '認證',
		wait: '稍後',
		getWait1: '正在獲取充值二維碼，請耐心等待...',
		getWait2: '正在獲取充值地址，請耐心等待...',
		submiting: '當前一個訂單正在提交中，請稍後'
	},
	footer: {
		index: '首頁',
		trend: '行情',
		trade: '交易',
		finance: '資金',
		me: '我的'
	},
	login: {
		inputTs: '請輸入用戶名和密碼'
	},
	register: {
		emailReg: '郵箱註冊',
		countryTs: '國家註冊後不可修改',
		success: '發送郵件成功',
		error: '郵箱格式不正確',
		sendSuc: '發送郵件成功',
		suc: '註冊成功'
	},
	email: {
		tit: '郵箱',
		check: '若未收到郵件，請檢查郵箱垃圾箱',
		plsInput: '請輸入驗證碼',
		again: '重新獲取驗證碼',
		second: '秒後重新獲取'
	},
	pwd: {
		set: '設置密碼',
		enter: '輸入密碼',
		length: '6-20位字符',
		again: '請再次輸入密碼',
		code: '邀請碼',
		agree: '同意',
		protocol: '用戶協議',
		ts1: '請輸入長度6-20的有效密碼',
		ts2: '兩次密碼不一致',
		ts3: '請輸入邀請碼'
	},
	index: {
		recharge: '充值',
		second: '秒合約',
		share: '分享賺傭',
		online: '在線客服',
		buyUsdt: '購買USDT',
		buyUsdtIntro: '10多個全球主流平台，可安全購買USDT',
		ranking: '漲幅榜'
	},
	quotes: {
		search: '搜索幣種',
		self: '自選',
		all: '現貨',
		name: '名稱',
		volume: '成交量',
		price: '最新價',
		ranking: '24h漲跌'
	},
	trade: {
		coin: '幣幣交易',
		second: '秒合約交易',
		limit: '限價',
		market: '市價',
		price: '價格',
		amount: '數量',
		inputUSDT: '請輸入要花費的USDT的數量',
		available: '可用',
		all: '全倉',
		transactionAmount: '交易額',
		handicap: '盤口',
		curEntrust: '當前委託',
		historyEntrust: '歷史委託',
		totalEntrust: '委託總量',
		finish: '已經完成',
		cost: '消費',
		income: '收入',
		revoke: '撤單',
		category: '幣種',
		direction: '方向',
		sure: '確認訂單',
		cancel: '取消訂單',
		orderStatu1: '等待成交',
		orderStatu2: '已完成',
		orderStatu3: '正在撤單',
		orderStatu4: '已撤單',
		addFavoriteSuc: '加自選成功',
		removeFavoriteSuc: '刪自選成功',
		inputTs1: '請輸入正確的價格和數量',
		inputTs2: '請輸入正確的USDT數量',
		revokeOrder: '撤銷訂單',
		sureRevokeOrder: '確定撤銷該訂單嗎?',
		revokeOrderSuc: '撤銷訂單成功'
	},
	trend: {
		high: '高',
		low: '低',
		volume: '量',
		turnover: '額',
		entrust: '委託',
		deal: '成交',
		buyList: '買盤',
		sellList: '賣盤',
		add: '加自選',
		remove: '刪自選',
		addSuc: '加自選成功',
		removeSuc: '刪自選成功'
	},
	second: {
		amount: '數量',
		time: '時間',
		odds: '賠率',
		available: '可交易餘額',
		doLong: '買漲',
		doShort: '買跌',
		category: '幣種',
		openPrice: '開倉價格',
		endPrice: '截止價格',
		dealCategory: '開倉幣種',
		dealAmount: '開倉數量',
		dealDirection: '開倉方向',
		dealTime: '開倉時間',
		profit: "盈利",
		sure: '確認訂單',
		cancel: '取消訂單',
		orderStatus1: '倒計時',
		orderStatus2: '已完成',
		orderStatus3: '已取消',
		noSupport: '當前交易暫時不支持秒合約，已自動切換交易對',
		minVolume: '當前最小下注金額為:',
		maxVolume: '當前最大下注金額為:',
		being: '正在提交訂單',
		fail: '訂單提交失敗'
	},
	me: {
		tit: '個人中心',
		identity: '身份認證',
		security: '安全設置',
		financeRecord: '財務記錄',
		tradeRecord: '交易記錄',
		setting: '通用設置',
		about: '關於我們',
		share: '分享好友',
		service: '聯繫客服',
		identityStatus1: '未認證',
		identityStatus2: '實名審核中',
		identityStatus3: '已認證',
		identityStatus4: '審核失敗',
	},
	identity: {
		tit: '身份認證',
		plsInput: '請輸入身份信息',
		oneBindTs: '一個證件只能實名認證綁定一個賬戶，請您核實無誤後進行綁定。',
		status1: '等待審核中， 請耐心等待審核通過',
		status2: '審核通過, 您的賬號現在更加安全',
		status3: '審核失敗，請修改信息信息重新提交審核',
		errReason: '失敗原因',
		name: '姓名',
		id: '身份證',
		plsUp: '請上傳',
		frontImg: '您的身份證人像面',
		backImg: '您的身份證國徽面',
		status4: '申請認證',
		status5: '等待審核中',
		status6: '認證通過',
		status7: '重新認證',
		requireTis: '上傳要求',
		require1: '1. 必須能看清楚身份證信息',
		require2: '2. 照片內容真實有效，不得做任何修改',
		require3: '3. 必須能看清證件號和姓名， 支持jpg / png / gif',
		require4: '4. 圖片最大不超過10M',
		limit: '請選擇10M以內的圖片',
		submitSuc: '提交成功，請耐心等待審核通過'
	},
	news: {
		list: '公告列表',
		detail: '公告詳情'
	},
	setting: {
		tit: '設置',
		lang: '語言',
		price: '計價方式'
	},
	share: {
		earnUsdt: '邀請好友賺取USDT',
		intro: '成功邀請好友，獲取好友每筆充值金額的1%',
		code: '邀請碼',
		copy: '複製分享鏈接',
		step1: '分享給好友',
		step2: '好友註冊充值',
		step3: '獲取佣金',
		data: '分享數據',
		register: '邀請註冊',
		recharge: '充值用戶',
		commission: '累積佣金',
		copySuc: '複製成功',
		copyErr: '複製失敗，請掃碼'
	},
	security: {
		tit: '安全設置',
		loginPwd: '登錄密碼',
		assetPwd: '資金密碼',
		change: '修改密碼',
		set: '設置密碼',
		setAssetPwd: '設置資金密碼',
		forgetloginPwd: '忘記登錄密碼',
		forgetassetPwd: '忘記資金密碼',
		changeloginPwd: '修改登錄密碼',
		changeassetPwd: '修改資金密碼',
		oldPwd: '原始密碼',
		newPwd: '新密碼',
		code: '郵箱驗證碼',
		changeloginPwdSuc: '登錄密碼修改成功',
		setloginPwdSuc: '登錄密碼設置成功',
		changeassetPwdSuc: '資金密碼修改成功',
		setassetPwdSuc: '資金密碼設置成功'
	},
	finance: {
		all: '總覽',
		toUSDT: '總資產折合',
		recharge: '充幣',
		withdraw: '提幣',
		available: '可用',
		freeze: '凍結',
		equivalence: '折合'
	},
	deposit: {
		tit: '充值',
		record: '充值記錄',
		channel: '充值通道',
		scan: '掃描二維碼, 轉入',
		address: '錢包地址',
		copy: '複製地址',
		min: '最小充值數為',
		wait: '充值之後等待10到30分鐘自動到賬',
		rightChannel: '請選擇正確的充值通道，否則將不會到賬',
		copySuc: '複製成功',
		copyErr: '複製失敗，請手動複製',
		channelFail: '獲取通道信息失敗'
	},
	transfer: {
		tit: '提幣',
		record: '提幣記錄',
		channel: '提幣通道',
		coin: '提取幣種',
		address: '提幣地址',
		amount: '提幣金額',
		balance: '餘額',
		fee: '曠工費',
		counting: '計算中',
		number: '數量',
		detial: '提幣詳情',
		assetPwd: '資金密碼',
		password: '密碼',
		wrongAddr: '提幣地址不正確',
		rightNum: '請輸入有效的提幣數量',
		insufficient: '餘額不足',
		submitSuc: '提幣申請提交成功',
		min: '最小提幣'
	},
	about: {
		tit: '關於我們'
	},
	coinRecord: {
		tit: '交易記錄',
		select: '篩選',
		type: '類型',
		change: '賬戶變化',
		all: '全部',
		exchange: '幣幣交易',
		recharge: '充值',
		withdraw: '提款',
		system: '系統'
	},
	financeRecord: {
		tit: '財務記錄',
		recharge: '充值',
		withdraw: '提款',
		amount: '金額',
		fee: '手續費',
		address: '地址',
		transaction: '交易',
		status: '狀態',
		status1: '等待處理',
		status2: '拒絕提款',
		status3: '提款撤銷',
		status4: '等待轉賬',
		status5: '轉賬完成',
		revokeSuc: '撤銷成功'
	},
	platform: {
		tit: '購買USDT平台',
		ts1: '可根據自己所在國家，以及支持的法幣選擇平台，在應用商店下載APP或者對應的官方網頁進行購買USDT，完成USDT購買之後，可以轉移到本平台進行投資等操作',
		ts2: '切記不要在山寨平台購買USDT，以防上當受騙'
	},
	req: {
		ts1: '正在努力加載中...',
		ts2: '數據格式錯誤',
		ts3: '身份認證已過期，請重新登陸',
		ts4: '抱歉，服務器錯誤，請稍後再試',
		ts5: '請先登錄...'
	},
	question: {
		tit: '提交問題',
		type: '類型',
		describe: '描述',
		submit: '提交留言',
		history: '以往問題',
		wait: '等待回复',
		pls: '請輸入問題內容',
		suc: '問題提交成功'
	}
} 