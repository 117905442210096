import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from "@/views/Index"
import Quotes from "@/views/Quotes"
import Trade from "@/views/Trade"
import Second from "@/views/Second"
import Trend from "@/views/Trend"
import Login from "@/views/login/Login"
import Login2 from "@/views/login/Login2"
import Register from "@/views/login/Register"
import Email from "@/views/login/Email"
import Setpwd from "@/views/login/Setpwd"
import Finance from "@/views/Finance"
import Me from "@/views/Me"
import Deposit from '@/views/Deposit'
import Transfer from '@/views/Transfer'
import News from "@/views/News"
import New from "@/views/New"
import Identity from "@/views/Identity"
import Identity2 from "@/views/Identity2"
import FinanceRecord from '@/views/FinanceRecord'
import Security from '@/views/Security'
import CoinRecord from '@/views/CoinRecord'
import Setting from '@/views/Setting'
import Share from '@/views/Share'
import About from '@/views/About'
import AboutDetail from '@/views/AboutDetail'
import Platform from '@/views/Platform'
import Message from '@/views/Message'
import Message2 from '@/views/Message2'
import Assets from '@/views/Assets'
// nft
import Nfts from '@/views/Nfts'
import Collection from '@/views/Collection'
import Nft from '@/views/Nft'

Vue.use(VueRouter)

VueRouter.isBack = false
VueRouter.prototype.goBack = function() {
	this.isBack = true
	window.history.go(-1)
}

export default new VueRouter({
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	routes: [
		{
			path: '/',
			name: 'Ind',
			component: Index
		},
		{
			path: '/index',
			name: 'Index',
			component: Index
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/login2',
			name: 'Login2',
			component: Login2
		},
		{
			path: '/register',
			name: 'Register',
			component: Register
		},
		{
			path: '/email',
			name: 'Email',
			component: Email
		},
		{
			path: '/setpwd',
			name: 'Setpwd',
			component: Setpwd
		},
		{
			path: '/quotes',
			name: 'Quotes',
			component: Quotes
		},
		{
			path: '/trade',
			name: 'Trade',
			component: Trade
		},
		{
			path: '/second',
			name: 'Second',
			component: Second
		},
		{
			path: '/trend',
			name: 'Trend',
			component: Trend
		},
		{
			path: '/finance',
			name: 'Finance',
			component: Finance
		},
		{
			path: '/finance-record',
			name: 'FinanceRecord',
			component: FinanceRecord
		},
		{
			path: '/coin-record',
			name: 'CoinRecord',
			component: CoinRecord
		},
		{
			path: '/me',
			name: 'Me',
			component: Me
		},
		{
			path: '/security',
			name: 'Security',
			component: Security
		},
		{
			path: '/setting',
			name: 'Setting',
			component: Setting
		},
		{
			path: '/deposit',
			name: 'Deposit',
			component: Deposit
		},
		{
			path: '/transfer',
			name: 'Transfer',
			component: Transfer
		},
		{
			path: '/news',
			name: 'News',
			component: News
		},
		{
			path: '/new',
			name: 'New',
			component: New
		},
		{
			path: '/message',
			name: 'Message',
			component: Message
		},
		{
			path: '/message2',
			name: 'Message2',
			component: Message2
		},
		{
			path: '/identity',
			name: 'Identity',
			component: Identity
		},
		{
			path: '/identity2',
			name: 'Identity2',
			component: Identity2
		},
		{
			path: '/share',
			name: 'Share',
			component: Share
		},
		{
			path: '/about',
			name: 'About',
			component: About
		},
		{
			path: '/about-detail',
			name: 'AboutDetail',
			component: AboutDetail
		},
		{
			path: '/platform',
			name: 'Platform',
			component: Platform
		},
		{
			path: '/nfts',
			name: 'Nfts',
			component: Nfts
		},
		{
			path: '/collection',
			name: 'Collection',
			component: Collection
		},
		{
			path: '/nft',
			name: 'Nft',
			component: Nft
		},
		{
			path: '/assets',
			name: 'Assets',
			component: Assets
		}
	]
})