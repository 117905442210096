<template>
  <div id="app">
    <transition :name="transitionName">
		<keep-alive include='Index,Quotes,Trade,Second,Finance,Deposit,About'>
			<router-view class="Router"></router-view>
		</keep-alive>
	</transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSite, getCurrencies } from '@/api/setting.js'
import jwt_decode from "jwt-decode"
import { refreshToken } from '@/api/login.js'
import { setToken, setFreshToken } from '@/utils/auth'
export default {
  name: 'App',
  computed: {
	...mapGetters(['langName', 'langId', 'token', 'idConfirmed','currency'])
  },
  data () {
	return {
		transitionName: 'slide-right'
	}
  },
  mounted () {
	getSite().then(data => {
		this.$store.commit('SET_SITENAME', data.siteName)
		this.$store.commit('SET_SITELOGO', data.siteLogo)
		this.$store.commit('SET_SITESERVICE', data.siteServiceLinkUrl)
	})
	//初始化设置语言
	let tmpLangName = localStorage.getItem('langName')
	let tmpLangId =  localStorage.getItem('langId')
	if(tmpLangName && tmpLangId) {
		this.$store.commit("SET_SITELANGNAME", tmpLangName) //语言名称
		this.$store.commit("SET_SITELANGID", tmpLangId) //语言标识
	}
	this.$i18n.locale = this.langId
	
	//判断是否认证通过了，需要更新token
	if(this.token == '' || this.token == undefined) {
		return
	}
	let decoded = jwt_decode(this.token);
	//console.log(decoded)
	//console.log(this.idConfirmed)
	if(decoded.identity_verified !== 'True' && this.idConfirmed) { //已认证，但是token没有更新
		refreshToken().then(data => {
			this.$store.commit('SET_TOKEN', data.access_token)
			this.$store.commit('SET_FRESHTOKEN', data.refresh_token)
			setToken(data.access_token)
			setFreshToken(data.refresh_token)
		})
	}
  },
  watch: {
	idConfirmed(val) {
		//判断是否认证通过了，需要更新token
		if(this.token == '' || this.token == undefined) {
			return
		}
		let decoded = jwt_decode(this.token);
		//console.log(decoded)
		//console.log(this.idConfirmed)
		if(decoded.identity_verified !== 'True' && val) { //已认证，但是token没有更新
			refreshToken().then(data => {
				this.$store.commit('SET_TOKEN', data.access_token)
				this.$store.commit('SET_FRESHTOKEN', data.refresh_token)
				setToken(data.access_token)
				setFreshToken(data.refresh_token)
			})
		}
	},
	//计价单位变化，汇率变化
	currency (val) {
		getCurrencies().then(data => {
			let currencies = data.items
			//根据当前的语言找到picker默认的是哪一项从而设置defaultIndex
			let ind = currencies.findIndex(item => {
				return item.displayName == val
			})
			if(ind > -1) {
				this.$store.commit("SET_RATEBYUSD", currencies[ind]['exchangeRateByUSD']) //计价
			}
		})
	}
  }
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}
html,body,#app {
	height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.Router {
	position: absolute;
	width: 100%;
	transition: all 0.3s ease;
	top: 0;
	bottom: 0;
}

.slide-left-enter,
.slide-right-leave-active {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active,
.slide-right-enter {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}
</style>
