<template>
	<div class="me">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('me.tit')}}
		</div>
		<div class="info">
			<div class="account">
				<p class="email">{{name}}</p>
				<p class="id">ID: {{id}}</p>
			</div>
			<div class="verify">
				{{verifyStatus | verify(this)}}
			</div>
		</div>
		<div class="list">
			<p><router-link to="/identity"><span class="iconfont">&#xe74a;</span>{{$t('me.identity')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/security"><span class="iconfont">&#xec4d;</span>{{$t('me.security')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/finance-record"><span class="iconfont">&#xe765;</span>{{$t('me.financeRecord')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/coin-record"><span class="iconfont">&#xe60d;</span>{{$t('me.tradeRecord')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/setting"><span class="iconfont">&#xe64b;</span>{{$t('me.setting')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/about"><span class="iconfont">&#xe649;</span>{{$t('me.about')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/share"><span class="iconfont">&#xebab;</span>{{$t('me.share')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
			<p><router-link to="/message"><span class="iconfont">&#xe88f;</span>{{$t('me.service')}}<span class="iconfont fr">&#xe62a;</span></router-link></p>
		</div>
		<div class="logout">
			<button class="submit" @click="logOut()">
				{{$t('common.logout')}}
			</button>
		</div>
		
	</div>
</template>

<script>
import { getIdentity } from '@/api/user.js'
import { mapGetters } from 'vuex'
export default {
	name: 'Me',
	data () {
		return {
			verifyStatus: '',//认证状态
		}
	},
	filters: {
		verify (value, vueThis) {
			if(value == 0) {
				return vueThis.$t('me.identityStatus1')
			}
			if(value == 1) {
				return vueThis.$t('me.identityStatus2')
			}
			if(value == 2) {
				return vueThis.$t('me.identityStatus3')
			}
			if(value == 3) {
				return vueThis.$t('me.identityStatus4')
			}
		}
	},
	computed: {
		...mapGetters(['name','id','siteService','idConfirmed'])
	},
	methods: {
		logOut () {
			this.$store.dispatch('LogOut').then(()=>{
				this.$toast({
					message: this.$t('common.logoutSuc'),
					duration: 1500
				})
				this.$router.push('/')
			})
		},
		service () {
			window.location.href = this.siteService
		}
	},
	mounted () {
		//已经认证
		if(this.idConfirmed) {
			this.verifyStatus = 2 //已认证
			return
		}
		getIdentity().then(data => {
			//console.log(data)
			this.verifyStatus = data.status.id
		}).catch(err => {
			console.log(err)
			this.verifyStatus = 0
		})
	}
}
</script>

<style scoped>
	.header {
		height: 54px;
		line-height: 54px;
		padding: 0 50px 0 10px;
		border-bottom: 1px #f0f0f0 solid;
	}
	.header .iconfont {
		padding-right: 40px;
	}
	.info {
		padding: 20px 0 20px 20px;
		border-bottom: 8px #f0f0f0 solid;
	}
	.account {
		flex: 1;
	}
	.email {
		font-size: 20px;
	}
	.id {
		font-size: 12px;
		color: #999;
		margin-top: 10px;
	}
	.verify {
		background-color: #efefef;
		height: 30px;
		line-height: 30px;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		font-size: 12px;
		padding-left: 20px;
		padding-right: 5px;
		color: #35d418;
	}
	.list p{
		line-height: 48px;
		padding: 0 10px;
	}
	.list p a {
		display: block;
	}
	.list p span {
		margin-right: 10px;
	}
	.logout {
		padding: 10px;
	}
	button.submit {
		width: 100%;
		height: 48px;
		font-size: 16px;
		color: #fff;
		background: #1882d4;
		border-radius: 5px;
	}
	.info {
		display: flex;
	}
</style>
