<template>
	<div class="footerNav tc bgwhite">
		<ul>
			<li>
				<router-link to="/" :class="{cho: curState== 'index'}">
					<i class="iconfont">&#xe8b9;</i>
					<p>{{$t('footer.index')}}</p>
				</router-link>
			</li>
			<li>
				<router-link to="/quotes" :class="{cho: curState== 'quotes'}">
					<i class="iconfont">&#xe641;</i>
					<p>{{$t('footer.trend')}}</p>
				</router-link>
			</li>
			<li>
				<!-- 默认进入到BTC的交易界面 -->
				<router-link :to="tradelink" :class="{cho: curState== 'trade'}">
					<i class="iconfont">&#xe694;</i>
					<p>{{$t('footer.trade')}}</p>
				</router-link>
			</li>
			<li>
				<router-link to="/finance" :class="{cho: curState== 'finance'}">
					<i class="iconfont">&#xe617;</i>
					<p>{{$t('footer.finance')}}</p>
				</router-link>
			</li>
			<li>
				<router-link to="/me">
					<i class="iconfont">&#xe612;</i>
					<p>{{$t('footer.me')}}</p>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		props: {
			curState: {
				default: "index"
			}
		},
		data () {
			return {
				tradeName: '',
				tradeID: '',
				tradelink: ''
			}
		},
		activated () {
			this.tradeName = localStorage.getItem("tradeName") || 'BTC/USDT'
			this.tradeID = localStorage.getItem("tradeID") || 'coin_btc_usdt'
			this.tradelink = '/trade?id='+ this.tradeID +'&name=' + this.tradeName
		}
	}
</script>

<style scoped>
	.footerNav {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	.footerNav ul li {
		width: 20%;
		float: left;
	}
	.footerNav ul li a {
		display: block;
		height: 50px;
		padding-top: 10px;
		color: #666;
	}
	.footerNav ul li a.cho {
		color: #ffcd36;
	}
	.footerNav ul li a i {
		font-size: 22px;
	}
	.footerNav ul li a p {
		font-size:12px;
		line-height: 20px;
	}
</style>
