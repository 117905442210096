module.exports = {
	common: {
		name: 'Exchange',
		welcome: 'Welcome to',
		login: 'Log in',
		register: 'Sign up',
		emailaddr: 'Email address',
		password: 'Password',
		later: 'Later',
		next: 'Next Step',
		amount: 'Amount',
		price: 'Price',
		direction: 'Direction',
		buy: 'Buy',
		sell: 'Sell',
		loginFirst: 'please log in first',
		confirmFirst: 'Please authenticate first',
		confirmNow: 'Get certified now',
		cancel: 'Cancel',
		sure: 'Sure',
		orderDetail: 'Order detail',
		insufficient: 'Insufficient USDT balance',
		orderSuc: 'Successed',
		logout: 'Sign out',
		logoutSuc: 'Logged out',
		sureChange: 'Change',
		sendCode: 'Send code',
		sendLater: 'Sent, please try again later',
		time: 'Time',
		success: 'Success',
		revoke: 'Revoke',
		errReason: 'Reason',
		nodata: 'Loading... No relevant data yet',
		verifyNow: 'Verify',
		wait: 'Later',
		getWait1: 'Getting the recharge QR code, please wait...',
		getWait2: 'Getting the deposit address, please wait...',
		submiting: 'The current order is being submitted, please wait'
	},
	footer: {
		index: 'Index',
		trend: 'Market',
		trade: 'Trade',
		finance: 'Assets',
		me: 'Me'
	},
	login: {
		inputTs: 'Please enter Email address and password'
	},
	register: {
		emailReg: 'Email',
		countryTs: 'The country cannot be changed after registration',
		success: 'Email sent successfully',
		error: 'Incorrect E-mail',
		sendSuc: 'Email sent successfully',
		suc: 'Registered'
	},
	email: {
		tit: 'Email',
		check: "If you haven't received the email, please check your email trash",
		plsInput: 'Enter verification code',
		again: 'Reacquire',
		second: 's later Reacquire'
	},
	pwd: {
		set: 'Login Password',
		enter: 'Enter password',
		length: '6-20 characters',
		again: 'Enter password again',
		code: 'Referral Code',
		agree: 'agree',
		protocol: 'Privacy Policy',
		ts1: 'Please enter a valid password of length 6-20',
		ts2: 'The two entered passwords do not match',
		ts3: 'Please Enter Referral Code'
	},
	index: {
		recharge: 'Deposit',
		second: 'Second',
		share: 'Share',
		online: 'Service',
		buyUsdt: 'BUY USDT',
		buyUsdtIntro: 'Global platforms for safe purchase of USDT',
		ranking: 'Top Gainers'
	},
	quotes: {
		search: 'Search coin',
		self: 'Favotites',
		all: 'Spot',
		name: 'Crypto',
		volume: 'Turnover',
		price: 'Last Price',
		ranking: '24h Change'
	},
	trade: {
		coin: 'Spots',
		second: 'Second',
		limit: 'Limit',
		market: 'Market',
		price: 'Price',
		amount: 'Amount',
		inputUSDT: 'Enter the amount of USDT to spend',
		available: 'Avail',
		all: 'All',
		transactionAmount: 'Total',
		handicap: 'No.',
		curEntrust: 'Open Orders',
		historyEntrust: 'Order History',
		totalEntrust: 'Total',
		finish: 'Finished',
		cost: 'Spent',
		income: 'Got',
		revoke: 'Undo',
		category: 'Coin',
		direction: 'Direction',
		sure: 'Confirm',
		cancel: 'Cancel',
		orderStatu1: 'waiting',
		orderStatu2: 'Finished',
		orderStatu3: 'Canceling',
		orderStatu4: 'Revoked',
		addFavoriteSuc: 'Added successfully',
		removeFavoriteSuc: 'Deleted successfully',
		inputTs1: 'Please enter the correct price and quantity',
		inputTs2: 'Please enter the correct amount of USDT',
		revokeOrder: 'Undo order',
		sureRevokeOrder: 'Are you sure you want to cancel this order?',
		revokeOrderSuc: 'Cancelled order successfully'
	},
	trend: {
		high: 'high',
		low: 'low',
		volume: 'vol',
		turnover: 'vol(usdt)',
		entrust: 'Order book',
		deal: 'Last trades',
		buyList: 'Buy',
		sellList: 'Sell',
		add: 'Add',
		remove: 'Delete',
		addSuc: 'Added',
		removeSuc: 'Deleted'
	},
	second: {
		amount: 'Amount',
		time: 'Time',
		odds: 'Odds',
		available: 'Avail',
		doLong: 'DoLong',
		doShort: 'DoShort',
		category: 'Coin',
		openPrice: 'Purchase price',
		endPrice: 'Cut-off price',
		dealCategory: 'Coin',
		dealAmount: 'Amount',
		dealDirection: 'Direction',
		dealTime: 'Opening time',
		profit: "Profit",
		sure: 'Sure',
		cancel: 'Cancel',
		orderStatus1: 'Count down',
		orderStatus2: 'Finished',
		orderStatus3: 'Canceled',
		noSupport: 'The current transaction does not support, the trading pair has been automatically switched',
		minVolume: 'Minimum bet amount:',
		maxVolume: 'Maximum bet amount:',
		being: 'Submitting order',
		fail: 'Order submission failed'
	},
	me: {
		tit: 'Personal center',
		identity: 'Authentication',
		security: 'Security',
		financeRecord: 'Financial records',
		tradeRecord: 'Transaction Record',
		setting: 'General settings',
		about: 'About Us',
		share: 'Share',
		service: 'Customer Service',
		identityStatus1: 'not certified',
		identityStatus2: 'Reviewing',
		identityStatus3: 'verified',
		identityStatus4: 'Failed review',
	},
	identity: {
		tit: 'Authentication',
		plsInput: 'Please enter identity information',
		oneBindTs: 'One certificate can only be bound to one account through real-name authentication. Please bind it after verification.',
		status1: 'Waiting for review, please wait patiently for the review to pass',
		status2: 'Approved, your account is now more secure',
		status3: 'Review failed, please modify the information and resubmit for review',
		errReason: 'Reason',
		name: 'Name',
		id: 'ID card',
		plsUp: 'Please upload',
		frontImg: 'Front of your ID',
		backImg: 'Back of your ID',
		status4: 'Apply for certification',
		status5: 'Pending review',
		status6: 'Certification passed',
		status7: 'Recertify',
		requireTis: 'Upload request',
		require1: '1. Must be able to see ID information',
		require2: '2. The content of the photo is authentic and valid, no modification is allowed',
		require3: '3. Must be able to read ID number and name， Support jpg / png / gif',
		require4: '4. The maximum image size should not exceed 10M',
		limit: 'Please select a picture within 10M',
		submitSuc: 'Submitted successfully, please wait patiently for approval'
	},
	news: {
		list: 'News',
		detail: 'Detail'
	},
	setting: {
		tit: 'Settings',
		lang: 'Language',
		price: 'Currency'
	},
	share: {
		earnUsdt: 'Invite friends to earn USDT',
		intro: "Successfully invite friends, get 1% of each friend's recharge amount",
		code: 'Invitation code',
		copy: 'Copy',
		step1: 'Share with friends',
		step2: 'Registration and recharge',
		step3: 'Get commission',
		data: 'Share data',
		register: 'Register',
		recharge: 'Recharge',
		commission: 'Total',
		copySuc: 'Successed',
		copyErr: 'Copy failed, please scan the code'
	},
	security: {
		tit: 'Security Settings',
		loginPwd: 'Login password',
		assetPwd: 'Fund password',
		change: 'Modify',
		set: 'Set up',
		setAssetPwd: 'Set up fund password',
		forgetloginPwd: 'Forgot Login password',
		forgetassetPwd: 'Forgot funds password',
		changeloginPwd: 'Modify login password',
		changeassetPwd: 'Modify funds password',
		oldPwd: 'Original',
		newPwd: 'New',
		code: 'E-mail code',
		changeloginPwdSuc: 'Login password changed successfully',
		setloginPwdSuc: 'Login password set successfully',
		changeassetPwdSuc: 'Fund password changed successfully',
		setassetPwdSuc: 'Fund password set successfully'
	},
	finance: {
		all: 'Overview',
		toUSDT: 'Total USDT',
		recharge: 'Deposit',
		withdraw: 'Withdraw',
		available: 'Available',
		freeze: 'Freezing',
		equivalence: 'equiv.'
	},
	deposit: {
		tit: 'Deposit crypto',
		record: 'Deposit records',
		channel: 'Deposit network',
		scan: 'Scan QR code',
		address: 'Deposit Address',
		copy: 'Copy address',
		min: 'Minimum deposit amount:',
		wait: 'After deposited, wait for 10 to 30 minutes to automatically arrive at the account, which is determined according to the current block confirmation speed',
		rightChannel: "Make sure you selected the same network on the platform where you are withdrawing funds for this deposit. Otherwise, you'll lose your assets",
		copySuc: 'Copied',
		copyErr: 'Copy failed, please copy manually',
		channelFail: 'Failed to get deposit network'
	},
	transfer: {
		tit: 'Withdraw',
		record: 'Records',
		channel: 'Withdrawal Channel',
		coin: 'Withdrawal Coin',
		address: 'Address',
		amount: 'Amount',
		balance: 'balance',
		fee: 'Miner fee',
		counting: 'calculating',
		number: 'Amount',
		detial: 'Detail',
		assetPwd: 'Asset Password',
		password: 'Password',
		wrongAddr: 'Wrong Address',
		rightNum: 'Please enter a valid amount',
		insufficient: 'Insufficient balance',
		submitSuc: 'Submitted successfully',
		min: 'Minimum withdrawal'
	},
	about: {
		tit: 'About us'
	},
	coinRecord: {
		tit: 'Records',
		select: 'Select',
		type: 'Type',
		change: 'Change',
		all: 'ALL',
		exchange: 'Trade',
		recharge: 'Deposit',
		withdraw: 'Withdraw',
		system: 'System'
	},
	financeRecord: {
		tit: 'Records',
		recharge: 'Deposit',
		withdraw: 'Withdraw',
		amount: 'Amount',
		fee: 'Fee',
		address: 'Address',
		transaction: 'Transaction',
		status: 'Status',
		status1: 'Processing',
		status2: 'Withdrawal refused',
		status3: 'Withdrawal revocation',
		status4: 'Waiting for transfer',
		status5: 'Transfer completed',
		revokeSuc: 'Revoked'
	},
	platform: {
		tit: 'Buy USDT platform',
		ts1: 'You can choose the platform according to your country and the supported legal currency, download the APP in the app store or the corresponding official website to buy USDT, after completing the USDT purchase, you can transfer to this platform for investment and other operations',
		ts2: 'Remember not to buy USDT on counterfeit platforms to avoid being deceived'
	},
	req: {
		ts1: 'trying to load...',
		ts2: 'data format error',
		ts3: 'Authentication has expired, please log in again',
		ts4: 'Sorry, server error, please try again later',
		ts5: 'please log in first...'
	},
	question: {
		tit: 'Submit question',
		type: 'Type',
		describe: 'content',
		submit: 'Submit',
		history: 'history',
		wait: 'waiting for reply',
		pls: 'Please enter the content',
		suc: 'Submitted successfully'
	},
	nfts: {
		tit: 'collectibles',
		des: 'Discover, collect, and sell extraordinary NFTs',
		detail: ' is a large and complete NFT marketplace',
	},
	collection: {
		by: 'By',
		items: 'Items',
		owners: 'Owners',
		totalVolume: 'Total volume',
		floorPrice: 'Floor price',
		bestOffer: 'Best offer',
		filters: 'Filters',
		sort: 'Sort',
		price: 'Price',
		lastSale: 'Last sale',
		clearAll: 'Clear All',
		done: 'Done',
		buyNow: 'Buy Now',
		onAuction: 'On Auction',
		pricelth: 'Price low to high',
		pricehtl: 'Price high to low',
		favorited: 'Most favorited',
		all: 'All'
	},
	nft: {
		owned: 'Owned By ',
		favorite: 'favorite',
		saleEnds: 'Sale ends ',
		currentPrice: 'Current price',
		buyNow: 'Buy Now',
		makeOffer: 'Make Offer',
		cancelListing: 'cancel',
		sell: 'Sell',
		transfer: 'Transfer',
		price: 'Price',
		expiration: 'Expiration',
		from: 'From',
		action: 'Action',
		description: 'Description',
		details: 'Details',
		tokenID: 'Token ID',
		tokenStandard: 'Token Standard',
		blockchain: 'Blockchain',
		fee: 'Fee',
		activity: 'Items Activity',
		event: 'Event',
		to: 'To',
		date: 'Date',
		order: 'Order',
		collectionName: 'Collection',
		name: 'Name',
		buy: 'Buy',
		cancel: 'Cancel',
		balance: 'Balance',
		day1: '1 Day',
		day3: '3 Day',
		day7: '7 Day',
		day30: '30 Day',
		inputPrice: 'Please input price',
		end: 'End',
		buySuc: 'Succeed',
		cancelSuc: 'Canceled',
		offerSuc: 'Succeed',
		sellSuc: 'Succeed',
		transferSuc: 'Transfered',
		offerCancelSuc: 'Canceled',
		transferAccount: 'Input the account you want transfer'
	},
	assets: {
		de: ' \'s '
	}
} 