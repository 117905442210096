<template>
	<div>
		<div class="pageNav">
			<router-link to="/login" class="fr">{{$t('common.login')}}</router-link>
		</div>
		<div class="regBox">
			<h4>{{$t('register.emailReg')}}</h4>
			<p class="warning"><i class="iconfont">&#xe600;</i>{{$t('register.countryTs')}}</p>
			<div class="loginFrom">
				<div class="country" @click="showCountries()">
					{{country}}<span class="arrow"></span>
				</div>
				<div class="inputBox">
					<input type="email" :placeholder="$t('common.emailaddr')" v-model="email"/>
				</div>
				<button :disabled="!email" class="submit" @click="next()">{{$t('common.next')}}</button>
			</div>
			<p class="later tc"><router-link to="/">{{$t('common.later')}}</router-link></p>
		</div>
		<mt-popup
			v-model="countryTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="countryOptions" value-key='displayName' @change="selectCountry"></mt-picker>
		</mt-popup>
		<v-lang></v-lang>
	</div>
</template>

<script>
import { isEmail } from "@/utils/verify.js"
import { sendEmail } from "@/api/user.js"
import { getCountries } from "@/api/setting.js"
import vLang from '@/components/Lang.vue'
export default {
	name: 'Register',
	components: {
		vLang
	},
	data () {
		return {
			countryTag: false, //国家选择弹窗
			email: '', //邮箱
			countryOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 1
			}],
			country: '',//当前选择的国家
			countryId: '',//国家ID
		}
	},
	methods: {
		//显示所有可注册国家
		showCountries () {
			this.countryTag = !this.countryTag
		},
		//选择国家
		selectCountry (picker, values){
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			if(picker.getValues()[0]) {
				this.country = picker.getValues()[0].displayName
				this.countryId = picker.getValues()[0].id
			}
		},
		//下一步
		next () {
			if(isEmail(this.email)) {
				sendEmail(this.email, 'register').then(() => {
					this.$toast({
						message: this.$t('register.success'),
						duration: 1000
					})
					setTimeout(()=> {
						sessionStorage.setItem('email', this.email)
						sessionStorage.setItem('country', this.countryId)
						this.$router.push({
							path: '/email',
						})
						sessionStorage.setItem('second', 60) 
					}, 500)
				})
			} else {
				this.$toast({
					message: this.$t('register.error'),
					duration: 1000
				})
			}
		}
	},
	mounted () {
		//获取可注册国家
		getCountries().then(data => {
			this.countryOptions[0].values=data.items
		})
		//获取邀请码,保存起来
		if(this.$route.query.in) {
			sessionStorage.setItem('inviteCode', this.$route.query.in)
		}
	}
}
</script>

<style scoped>
	.pageNav {
		height: 60px;
		padding: 0 30px;
		line-height: 60px;
	}
	.pageNav a {
		height: 60px;
		color: #1882d4;
	}
	.regBox {
			font-size: 16px;
			height: 80px;
			padding:30px 30px 0;
	}
	.regBox h4 {
		font-size: 30px;
		font-weight: normal;
		line-height: 40px;
	}
	.loginFrom {
		margin: 50px 0 30px;
	}
	.country {
		margin: 20px 0;
		font-size: 18px;
	}
	.inputBox {
		border-bottom: 1px solid #c5cfd5;
		margin: 30px 0 45px;
	}
	.inputBox input {
		width: 100%;
		line-height:40px;
		font-size: 20px;
		font-weight: bold;
		color: #1f3f59;
	}
	.regBox button.submit {
		width: 100%;
		height: 52px;
		font-size: 16px;
		color: #fff;
		background: #1882d4;
	}
	.regBox button.submit:disabled {
		background: #c5cfd5;
	}
	.regBox p {
		height: 28px;
		line-height: 28px;
		color: #8c9fad;
		font-size: 15px;
	}
	.regBox p.warning {
		color: #d14b64;
	}
	.warning i {
		font-size: 20px;
		vertical-align: middle;
	}
	.arrow {
		top: -3px;
		left:3px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}
	.arrow:before {
		content: "";
		position: absolute;
	}
	.arrow:before {
		top: 0;
		left: 0;
		border-top: 6px solid #c5cfd5;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
	}
	.mint-popup-bottom {
		width: 100%;
	}
	.later {
		margin-top: -10px;
	}
	.later a {
		font-size: 12px;
		color: #999;
	}
</style>
