<template>
	<div class="deposit">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('deposit.tit')}}
			<a @click="record()" class="fr history">{{$t('deposit.record')}}</a>
		</div>
		<div class="token">
			<a :class="{'cho':curDepositCoin.name == item.name}" 
				v-for="(item, index) of canDepositCoins" 
				:key="item.id"
				@click="getChannel(item.id, index)">
				{{item.name}}
			</a>
		</div>
		<div class="channels">
			<h2>{{$t('deposit.channel')}}：</h2>
			<div>
				<span 
				v-for="item of channels" 
				:key="item.id"
				@click="getToken(item.id, curchannel.name == item.name)"
				class="lowercase"
				:class="{'cho':curchannel.name == item.name}">
				{{item.name}}
				</span>
			</div>
		</div>
		<div class="code-box">
			<p class="tit">{{$t('deposit.scan')}}{{curDepositCoin.name}}</p>
			<div class="code" id="qrcode" ref="qrcode">
				<!-- <img src="../assets/img/coin/bitcoin.png"> -->
			</div>
			<div class="wait tc" v-if="!switchBtn">
				{{$t('common.getWait1')}}
			</div>
			<p class="tit">{{$t('deposit.address')}}</p>
			<p class="address"  v-if="switchBtn">{{curRechargeToken.address}}</p>
			<div class="wait tc" v-if="!switchBtn">
				{{$t('common.getWait2')}}
			</div>
			<p class="copy"
			v-clipboard:copy="curRechargeToken.address"
			v-clipboard:success="onCopy"
			v-clipboard:error="onError">
			{{$t('deposit.copy')}}</p>
		</div>
		<div class="tips">
			<p>{{$t('deposit.min')}} {{curDepositCoin.minRechargeVolume}} {{curDepositCoin.name}}</p>
			<p>{{$t('deposit.wait')}}</p>
			<p>{{$t('deposit.rightChannel')}}</p>
		</div>
	</div>
</template>

<script>
import { getCoins, getCoinById, getRechargeTokenById } from '@/api/coin'
import QRCode from 'qrcodejs2'
export default {
	name: 'Deposit',
	data () {
		return {
			canDepositCoins: [] ,//可充值的币种
			channels: [],//充值的通道
			curDepositCoin: {},//当前充值币种
			curchannel: {},//当前的充值通道
			curRechargeToken: {},//当前充值的token信息
			switchBtn: true,//按钮开个，防止多次点击
		}
	},
	methods: {
		//通过ID获取可充值币种的通道信息
		getChannel(id, ind) {
			if(!this.switchBtn) {
				return
			}
			this.curDepositCoin = this.canDepositCoins[ind]
			this.switchBtn = false
			document.getElementById("qrcode").innerHTML = ""
			getCoinById(id).then(data => {
				this.curDepositCoin = data
				this.channels = data.channels.filter(item => {
					return item.enabled
				})
				//console.log(this.channels)
				this.curchannel = this.channels[0]
				this.getToken(this.curchannel.id, false)
			})
		},
		//通过tokenId获取当前充值的token信息
		getToken(tokenId, bool) {
			if(bool) {
				return
			}
			this.switchBtn = false
			this.curchannel = this.channels.filter(item => {
				return item.id == tokenId
			})[0]
			document.getElementById("qrcode").innerHTML = ""
			getRechargeTokenById(tokenId).then(data => {
				this.curRechargeToken = data
				//console.log(this.curRechargeToken)
				this.qrcodeFunc(this.curRechargeToken.address)
				this.switchBtn = true
			}).catch(err=> {
				this.curRechargeToken = {}
				console.log(err)
				this.$toast({
					message: this.$t('deposit.channelFail'),
					duration: 1000
				})
			})
		},
		//生产二维码
		qrcodeFunc (url) {
			this.qr = new QRCode('qrcode', {
				width: 170, // 设置宽度，单位像素
				height: 170, // 设置高度，单位像素
				text: url // 设置二维码内容或跳转地址
			})
		},
		//复制
		onCopy () {
			this.$toast({
				message: this.$t('deposit.copySuc'),
				duration: 1000
			})
		},
		//复制出错
		onError () {
			this.$toast({
				message: this.$t('deposit.copyErr'),
				duration: 1000
			})
		},
		//充值记录
		record() {
			this.$router.push({
				path: '/finance-record',
				query: {
					type: 'recharge'
				}
			})
		}
	},
	activated () {
		getCoins ().then(data => {
			//console.log(data)
			this.canDepositCoins = data.items.filter(item => {
				return item.canRecharge
			}).reverse()
			//默认第一个可充值币种为选中状态
			this.curDepositCoin = this.canDepositCoins[0]
			//console.log(this.canDepositCoins)
			//console.log(this.curDepositCoin)
			//默认获取第一个可充值币种的通道信息
			this.getChannel(this.curDepositCoin.id, 0)
			
		})
	}
	
}
</script>

<style scoped>
	.back {
		text-align: center;
		height: 50px;
		line-height: 50px;
		padding-right: 10px;
	}
	.back span {
		float: left;
		width: 58px;
	}
	.history {
		font-size: 12px;
		color: #9f9f28;
	}
	.channels {
		margin: 20px 10px;
		border: 1px #eee solid;
		padding: 10px;
	}
	.channels h2 {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.channels div {
		display: flex;
		box-sizing: border-box;
	}
	.channels span {
		flex: 1;
		text-align: center;
		background-color: #eee;
		padding: 10px 30px;
		margin: 2px;
	}
	.channels span.cho {
		background-color: #ecec97;
		color: #333;
	}
	.code-box {
		text-align: center;
		background-color: #fff;
		margin: 15px 20px;
		border-radius: 10px;
		color: #333;
		font-size: 12px;
		overflow: hidden;
	}
	.code-box .tit {
		color: darkgray;
	}
	.address {
		padding: 0 20px;
		word-break: break-word;
	}
	.code {
		text-align: center;
		width: 170px;
		margin: 10px auto 20px
	}
	.copy {
		height: 44px;
		line-height: 44px;
		background-color: #ecec97;
		margin-top: 20px;
	}
	.token {
		text-align: center;
	}
	.token a {
		display: inline-block;
		margin: 10px;
		border: 1px #eee solid;
		padding:5px 10px;
		width: 80px;
		box-sizing: border-box;
		border-radius: 5px;
	}
	.token a.cho {
		background: yellow;
		color: red;
		border: 1px #b9b906 solid;
	}
	.tips {
		margin: 20px;
		font-size: 12px;
		background-color: #eee;
		padding: 10px;
		border-radius: 5px;
	}
	.wait {
		padding: 10px 0;
	}
</style>
