<template>
	<div class="container bgwhite">
		<div class="headerNav">
			<img :src="sitelogo">
		</div>
		<div class="wrapper">
			<div class="topNav">
				<router-link :to="'/trade?id='+curSymbol.id" class="active">{{$t('trade.coin')}}</router-link>
				<router-link :to="'/second?id='+curSymbol.id">{{$t('trade.second')}}</router-link>
			</div>
			<div class="trade-symbol">
				<p>
					<i class="iconfont">&#xe684;</i>
					<span @click="showSymbols()">{{curSymbol.name}}</span>
					<span class="actions fr">
						<i v-show="!favoriteTag" class="iconfont" @click="addFavorite('add')">&#xe634;</i>
						<i v-show="favoriteTag" class="iconfont active" @click="addFavorite('remove')">&#xe634;</i>
						<i class="iconfont" @click="toTrend()">&#xe631;</i>
					</span>
				</p>
			</div>
			<div class="trade-panel bgc">
				<div class="trade-panel-title">
					<button @click="choDirection(1)" type="button" class="buy" :class="{'cur':direction==1}">{{$t('common.buy')}}</button>
					<button @click="choDirection(2)" type="button" class="sell" :class="{'cur':direction==2}">{{$t('common.sell')}}</button>
					<div class="select-box">
						<select id="trader-type" v-model="type" @change="selectType()">
							<option value="1">{{$t('trade.limit')}}</option>
							<option value="2">{{$t('trade.market')}}</option>
						</select>
						<i class="arrow"></i>
					</div>
				</div>
				<div class="trade-panel-content">
					<div class="trade-panel-form">
						<div class="input-box">
							<input v-if="type==1" @input="priceInput()" type="number" :placeholder="$t('trade.price')" v-model="price">
							<input v-if="type==2" type="number" :placeholder="$t('trade.market')" disabled="disabled">
						</div>
						<div class="equivalent">
							<span>≈<em id="price-equivalent">{{(price * exchangeRateByUSD).toFixed(2)}} <i>{{currency}}</i></em></span>
						</div>
						<div class="input-box">
							<input type="number" :placeholder="$t('trade.amount')" v-model="volume">
							<p v-show="type==2 && direction ==1" class="equivalent">{{$t('trade.inputUSDT')}}</p>
						</div>
						<div class="equivalent">
							<span>{{$t('trade.available')}} 
								<em id="price-equivalent">{{direction == 1 ? usdtAsset.balance : curAsset.balance}}</em>
								<i class="uppercase fr">{{direction == 1 ? 'usdt' : curAsset.coinId}}</i>
							</span>
						</div>
						<div class="btn-group">
							<button :class="{'cho': ratio == 1/4}" @click="ratioChoose(1/4)">1/4</button>
							<span></span>
							<button :class="{'cho': ratio == 1/2}" @click="ratioChoose(1/2)">1/2</button>
							<span></span>
							<button :class="{'cho': ratio == 1}" @click="ratioChoose(1)">{{$t('trade.all')}} </button>
						</div>
						<div class="total">
							<span>{{$t('trade.transactionAmount')}} <em>{{sum}}</em> <em>USDT</em></span>
						</div>
						<!-- 买入卖出按钮 -->
						<button @click="showOrder()" v-show='direction==1' class="bggreen submit">{{$t('common.buy')}} <span>{{curSymbol.baseCoinId}}</span></button>
						<button @click="showOrder()" v-show='direction==2' class="bgred submit">{{$t('common.sell')}} <span>{{curSymbol.baseCoinId}}</span></button>
						<button @click="login()" v-show="!name" class="login submit">{{$t('common.login')}}</button>
					</div>
					<div class="handicap">
						<div class="handicap-title">
							<span class="index">{{$t('trade.handicap')}}</span>
							<span class="price">{{$t('trade.price')}}</span>
							<span class="amount fr">{{$t('trade.amount')}}</span>
						</div>
						<div class="handicap-sell">
							<dl @click="choPrice(order.price)" v-for="(order,index) of sell.slice(0, 5).reverse()" :key="index">
								<dt class="fl">{{index+1}}</dt>
								<dd class="fl">{{order.price}}</dd>
								<dd class="fr">{{order.totalVolume}}</dd>
							</dl>
							<!-- <dl>
								<dt class="fl">1</dt>
								<dd class="fl">63202.18</dd>
								<dd class="fr">0.12129</dd>
							</dl> -->
						</div>
						<div class="handicap-price color-down">
							<em id="nowprice" @click="choPrice(market.latestPrice)">{{market.latestPrice}}</em> 
							<b class="rate">{{toRateFunc(market.change)}}</b>
							<span>≈<b id="now-price-equivalent">{{(market.latestPrice * exchangeRateByUSD).toFixed(2)}} <i>{{currency}}</i></b></span>
						</div>
						<div class="handicap-buy">
							<dl @click="choPrice(order.price)" v-for="(order,index) of buy.slice(0, 5)" :key="index">
								<dt class="fl">{{index+1}}</dt>
								<dd class="fl">{{order.price}}</dd>
								<dd class="fr">{{order.totalVolume}}</dd>
							</dl>
							<!-- <dl>
								<dt class="fl">1</dt>
								<dd class="fl">63202.18</dd>
								<dd class="fr">0.12129</dd>
							</dl> -->
						</div>
					</div>
				</div>
			</div>
			<div class="trade-order">
				<div class="trade-order-title">
					<span @click="changeOrderType(1)" :class="{'cur':orderType==1}">{{$t('trade.curEntrust')}}</span>
					<span @click="changeOrderType(2)" :class="{'cur':orderType==2}">{{$t('trade.historyEntrust')}}</span>
				</div>
				<div class="list">
					<div class="item" v-for="order of curOrders" :key="order.id">
						<div class="name">{{curSymbol.name}}<span class="fr time">{{getLocalTime(order.createdTime)}}</span></div>
						<div>{{order.type.id ==1 ? $t('trade.limit') : $t('trade.market')}} / <span :class="[order.direction.id == 1 ? 'green':'red']">{{order.direction.id == 1 ? $t('common.buy'):$t('common.sell')}}</span>
						&nbsp;&nbsp;&nbsp;{{$t('trade.price')}}：{{order.averagePrice > 0 ? order.averagePrice : order.price}}
						</div>
						<div>{{$t('trade.totalEntrust')}}：<span>{{order.remainVolume + order.tradingVolume}}</span></div>
						<div>{{$t('trade.finish')}}：
						<span>{{order.tradingVolume}}</span>
						<span :class="order.status.id | orderStatusColor" class="fr">{{orderStatus(order.status.id)}}</span>
						</div>
						<div v-show='order.direction.id == 1'>{{$t('trade.cost')}}：{{order.turnover}} USDT</div>
						<div v-show='order.direction.id == 2'>{{$t('trade.income')}}：{{order.turnover}} USDT</div>
						<a class="revoke" v-show="order.status.id==1" @click="revoke(order.id)">{{$t('trade.revoke')}}</a>
					</div>
					<!-- <div class="item">
						<div class="name">ETH/USDT<span class="fr time">2022-02-12</span></div>
						<div>限价/<span>买入</span></div>
						<div>委托总量：<span>10</span></div>
						<div>已经成交：<span>10</span></div>
						<a class="revoke">撤单</a>
					</div> -->
				</div>
			</div>
		</div>
		<v-footer curState="trade"></v-footer>
		<mt-popup
			v-model="symbolsTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="symbolsOptions" value-key='name' @change="selectSymbol"></mt-picker>
		</mt-popup>
		<mt-popup
			v-model="orderTag"
			position="bottom"
			modal="false">
			<div class="orderInfo">
				<h1>{{$t('common.orderDetail')}}</h1>
				<p>{{$t('trade.category')}}：{{curSymbol.name}}</p>
				<p>
					{{$t('trade.direction')}}：{{type == 1 ? $t('trade.limit') : $t('trade.market')}} / <span :class="[direction == 1 ? 'green' : 'red']">{{direction == 1 ? $t('common.buy') : $t('common.sell')}}</span>
					, {{$t('trade.price')}}：<span class="green">{{type == 1 ? '' : '≈'}}{{price}}</span>
				</p>
				<!-- 市价买入，数量是USDT数量，需要转换成币种数量 -->
				<p>{{$t('trade.amount')}}：{{this.type == 2 && this.direction == 1 ? '≈ ' + (volume/price).toFixed(6) : volume}} <span class="uppercase">({{curSymbol.baseCoinId}})</span></p>
				<p>{{$t('trade.transactionAmount')}}：{{sum}} USDT</p>
				<div class="btns">
					<mt-button @click="coinTrade()" type="primary">{{$t('trade.sure')}}</mt-button>
					<mt-button @click="cancelTrade()" type="default">{{$t('trade.cancel')}}</mt-button>
				</div>
			</div>
		</mt-popup>
	</div>
	
</template>

<script>
import { mapGetters } from 'vuex'
import { addFavorite, removeFavorite, getFavorites } from '@/api/symbols.js'
import { timestampToTime, toRate } from "@/utils/third.js"
import { getSymbols, coinOrder, getOrders, deleteOrder } from '@/api/symbols.js'
import { getCoinAccounts } from "@/api/finance.js"
import vFooter from '@/components/Footer'
export default {
	name: 'Trade',
	components: {
		vFooter,
	},
	filters: {
		// orderStatus (orderStatusId, vueThis) {
		// 	switch (orderStatusId) {
		// 		case 1: return vueThis.$t('trade.orderStatu1');
		// 		case 2: return vueThis.$t('trade.orderStatu2');
		// 		case 3: return vueThis.$t('trade.orderStatu3');
		// 		case 4: return vueThis.$t('trade.orderStatu4');
		// 	}
		// },
		orderStatusColor (orderStatusId) {
			switch (orderStatusId) {
				case 1: return 'yellow';
				case 2: return 'green';
				case 3: return 'red';
				case 4: return 'red';
			}
		}
	},
	data () {
		return {
			//vueThis: this,
			switchBtn: true, //购买按钮
			symbolsTag: false, //交易对弹窗
			orderTag: false,//订单详情弹窗
			curSymbol: {},// 当前交易对
			symbols: [] , //交易对
			symbolsOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}],
			direction: 1, //买入方向. 1买入， 2卖出
			price: 0,//交易价格
			volume: 0,//买入的数量
			type: 1, //1是限价，2是市价
			buy: [{}], //盘口数据 -买盘
			sell: [{}], //盘口数据-卖盘
			orders: [], //订单列表
			orderType: 1,//订单类型，当前委托 1，历史委托 2
			curOrders: [],//当前筛选的订单
			market: {
				change: 0,
				highestPrice: 0,
				latestPrice: 0,
				lowestPrice: 0,
				turnover: 0,
				updateTime: 0,
				volume: 0
			},//行情数据
			assets: [], //用户资产
			curAsset: {},//当前币种资产
			usdtAsset: {
				balance: 0
			},//usdt资产
			ratio: 0,// 投资仓位比例
			first: 1, //第一次进入页面
			favoriteTag: false,//是否自选
		}
	},
	computed: {
		...mapGetters(['name','sitelogo','currency','exchangeRateByUSD','idConfirmed']),
		//sum:usdt交易额
		sum () {
			//市价买入
			if(this.type == 2 && this.direction == 1) {
				return this.volume
			}
			else {
				return (this.price * this.volume).toFixed(4)
			}
		}
	},
	methods: {
		orderStatus (orderStatusId) {
			switch (orderStatusId) {
				case 1: return this.$t('trade.orderStatu1');
				case 2: return this.$t('trade.orderStatu2');
				case 3: return this.$t('trade.orderStatu3');
				case 4: return this.$t('trade.orderStatu4');
			}
		},
		//加自选
		addFavorite(tag) {
			if(tag == 'add') { //加自选
				addFavorite(this.curSymbol.id).then(() => {
					this.$toast({
						message: this.$t('trade.addFavoriteSuc'),
						duration: 1000
					})
					this.favoriteTag = true
				})
			}
			if(tag == 'remove') {
				removeFavorite(this.curSymbol.id).then(() => {
					this.$toast({
						message: this.$t('trade.removeFavoriteSuc'),
						duration: 1000
					})
					this.favoriteTag = false
				})
			}
		},
		//判断是否自选
		judgeFavorite() {
			if(this.name) { //判断是登录状态
				getFavorites().then(data => {
					let ind = data.items.findIndex(item => {
						return item.symbolId == this.curSymbol.id
					})
					if(ind > -1) {
						this.favoriteTag = true
					}else {
						this.favoriteTag = false
					}
				})
			}
		},
		//去登录页面
		login() {
			this.$router.push({
				path: '/login',
				query: {
				}
			})
		},
		//去到走势页面
		toTrend() {
			this.$router.push({
				path: '/trend',
				query: {
					id: this.curSymbol.id,
					name: this.curSymbol.name
				}
			})
		},
		//小数换成百分比
		toRateFunc (num) {
			return toRate(num)
		},
		//显示所有交易对
		showSymbols () {
			this.symbolsTag = !this.symbolsTag
		},
		//资产筛选
		assetFilter(baseCoinId) {
			if(!this.assets.length) { //还未获得资产
				return []
			}
			return this.assets.filter(item => {
				return item.coinId == baseCoinId
			})[0]
		},
		//选择买入卖出
		choDirection(direction) {
			this.direction = direction
			this.volume = 0
			this.ratio = 0
			this.price = this.market.latestPrice
			//卖出情况下
			if(direction == 2) {
				this.curAsset = this.assetFilter(this.curSymbol.baseCoinId)
			}
		},
		//选择价格
		choPrice(price) {
			this.price = price
			this.volume = 0
			this.ratio = 0
		},
		//价格变动
		priceInput () {
			this.volume = 0
			this.ratio = 0
		},
		//选择限价市价
		selectType () {
			//交易量和仓位设置为0
			this.volume = 0
			this.ratio = 0
		},
		//投资比例选择
		ratioChoose(ratio) {
			this.ratio = ratio
			//没有登录
			if(!this.name) {
				this.$toast({
					message: this.$t('common.loginFirst'),
					duration: 1000
				})
				return
			}
			//买入
			if(this.direction == 1) {
				//限价
				if(this.type == 1) {
					this.volume = (this.usdtAsset.balance * ratio / this.price).toFixed(4)
				}
				//市价
				if(this.type == 2) {
					this.volume = (this.usdtAsset.balance * ratio).toFixed(2)
				}
			}
			//卖出
			if(this.direction == 2) {
				this.volume = this.curAsset.balance * ratio
			} 
		},
		//订单详情
		showOrder() {
			//没有登录
			if(!this.name) {
				this.$toast({
					message: this.$t('common.loginFirst'),
					duration: 1000
				})
				return
			}
			//没有认证
			if(!this.idConfirmed) {
				this.$messagebox({
					title: this.$t('common.confirmFirst'),
					message: this.$t('common.confirmNow'),
					confirmButtonText: this.$t('common.verifyNow'),
					cancelButtonText: this.$t('common.wait'),
					showCancelButton: true
				}).then(action => {
					if(action == 'confirm') {
						this.$router.push('/identity')
					} else {
						//console.log('取消')
					}
				})
				return
			}
			if(this.price == 0 || this.volume == 0) {
				this.$toast({
					message: this.$t('trade.inputTs1'),
					duration: 1000
				})
				return
			}
			//市价买入，数量输入的是USDT， 免得客户误解，提示USDT数量不能小于1
			if(this.direction==1 && this.type == 2 && this.volume < 1) {
				this.$toast({
					message: this.$t('trade.inputTs2'),
					duration: 1000
				})
				return
			}
			this.orderTag = true
		},
		//选择交易对
		async selectSymbol (picker, values){
			if(!this.symbolsTag) {
				return
			}
			//第一次进入页面，并且不是默认第一个picker(defaultIndex =0)默认值，则跳过一次执行，赋值之后会再次执行
			// if(this.first && this.symbolsOptions[0].defaultIndex != 0) {
			// 	this.first = 0
			// 	return
			// }
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			//订阅盘口和行情
			if(this.curSymbol.id) {
				//判断当前币种是否自选
				this.judgeFavorite()
				//console.log('-------selectSymbol-----')
				//console.log(this.curSymbol.name)
				//切换交易对先取消订阅盘口取消订阅行情
				await this.unsubscribeOrderbookFunc(this.curSymbol.id)
				await this.unsubscribeMarketFunc(this.curSymbol.id)
				await this.unsubscribeTradeFunc(this.curSymbol.id)
				//切换交易对之后设置行情价格和买入价格为0
				this.price = 0
				this.volume = 0
				this.ratio = 0
				this.market.latestPrice = 0
				// this.buy = []
				// this.sell = []
				//设置当前交易对为选中的交易对
				//console.log(picker.getValues()[0])
				if(picker.getValues()[0]) {
					this.curSymbol = picker.getValues()[0]
				}
				//console.log('-------selectSymbol2-----')
				//console.log(this.curSymbol.name)
				//订阅盘口 订阅行情
				await this.subscribeMarketFunc(this.curSymbol.id)
				await this.subscribeOrderbookFunc(this.curSymbol.id)
				await this.subscribeTradeFunc(this.curSymbol.id)
				//保存当前的选择币种
				localStorage.setItem('tradeName', this.curSymbol.name)
				localStorage.setItem('tradeID', this.curSymbol.id)
				//alert('getOrdersFunc')
				//加载订单
				if(this.name) { //判断是登录状态
					this.getOrdersFunc()
				}
				//卖出情况下更新当前币种资产
				if(this.direction == 2) {
					this.curAsset = this.assetFilter(this.curSymbol.baseCoinId)
				}
			}
		},
		//取消订单
		cancelTrade () {
			this.orderTag = false
		},
		//币币交易，买入卖出
		coinTrade() {
			this.orderTag = false
			if(!this.switchBtn) {
				this.$toast({
					message: this.$t('common.submiting'),
					duration: 500
				})
				return
			}
			this.switchBtn = false
			let data = {
				"symbolId": this.curSymbol.id,
				"direction": this.direction,
				"type": this.type, //1是限价，2是市价
				"volume": this.volume, //交易量
				"price": this.type == 1 ? this.price : 0 ,//价格
			}
			coinOrder(data).then(data => {
				console.log(data)
				//获取全部订单
				this.getOrdersFunc()
				this.switchBtn = true
				this.$toast({
					message: this.$t('common.orderSuc'),
					duration: 1000
				})
			}).catch(err => {
				this.$toast({
					message: err.message,
					duration: 1000
				})
			})
		},
		//撤单
		revoke(id) {
			this.$messagebox({
				title: this.$t('trade.revokeOrder'),
				message: this.$t('trade.sureRevokeOrder'),
				confirmButtonText: this.$t('common.sure'),
				cancelButtonText: this.$t('common.cancel'),
				showCancelButton: true
			}).then(action => {
				if(action == 'confirm') {
					//console.log('撤销')
					deleteOrder(id).then(data => {
						console.log(data)
						this.$toast({
							message: this.$t('trade.revokeOrderSuc'),
							duration: 1500
						})
						//订单列表里面删除该订单的显示
						this.curOrders = this.curOrders.filter((item) => {
							return item.id !== id
						})
					})
				} else {
					//console.log('取消')
				}
			})
		},
		//获取订单
		getOrdersFunc () {
			let params = {
				"symbolId": this.curSymbol.id,
				"MaxResultCount": 100
			}
			getOrders(params).then(data => {
				//console.log(data)
				this.orders = data.items.reverse()
				this.curOrders = this.orders.filter((item) => {
					if(this.orderType == 1) {
						return item.status.id == this.orderType
					} else {
						return item.status.id > 1
					}
					
				})
			})
		},
		//切换订单类型
		changeOrderType(type) {
			this.orderType = type
			this.curOrders = this.orders.filter((item) => {
				if(this.orderType == 1) {
					return item.status.id == this.orderType
				} else {
					return item.status.id > 1
				}
				
			})
			//console.log(this.curOrders)
		},
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
		//订阅盘口信息
		async subscribeOrderbookFunc(id) {
			//订阅盘口
			//console.log("---------订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id)
			//接收盘口更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderbookUpdatedAsync, (data) =>{
				//console.log("---------接收盘口更新------------")
				//console.log(data)
				//this.orderbook = data.orderbook
				this.buy = data.orderbook.buy
				this.sell = data.orderbook.sell.reverse()
				if(this.buy.length == 0) {
					this.buy = [{price:0, totalVolume:0, volume:0}]
				}
				if(this.sell.length == 0) {
					this.sell = [{price:0, totalVolume:0, volume:0}]
				}
				this.$set(this.buy, 0, this.buy[0])
				this.$set(this.sell, 0, this.sell[0])
				if(this.price == 0 && this.market.latestPrice == 0) {
					this.price = this.buy[0].price
				}
				if(this.market.latestPrice == 0) {
					this.market.latestPrice = this.buy[0].price
				}
			})
		},
		//订阅行情信息
		async subscribeMarketFunc(id) {
			//订阅行情
			//console.log("---------订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id])
			//接收行情更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolDayMarketChangedAsync, (data) =>{
				console.log("---------接收行情更新------------")
				console.log(data)
				this.market = data.market
				//首次进入初始化买入卖出价格为行情价格
				if(this.price == 0) {
					this.price = this.market.latestPrice
				}
			})
		},
		//订阅订单信息
		async subscribeTradeFunc(id) {
			//订阅行情
			//console.log("---------订阅订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id])
			//接收订单更新数据
			this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolOrderTradedAsync, (data) =>{
				//console.log("---------接收订单信息------------")
				let quoteCoinDecimals = this.curSymbol.quoteCoinDecimals
				this.market.latestPrice = data.price.toFixed(quoteCoinDecimals)
				// if(this.orders.length>16) {
				// 	this.orders.length = 16
				// 	// if(this.orderType == 1) { //委托挂单界面，那么不再订阅订单信息，节约性能
				// 	// 	this.unsubscribeTradeFunc(this.symbolId)
				// 	// }
				// }
				// console.log(this.orders)
			})
		},
		//取消订阅盘口信息
		async unsubscribeOrderbookFunc(id) {
			//console.log("---------取消订阅盘口------------")
			await this.$tradeHub.subscribeOrderbookEvent(id, false)
		},
		//取消订阅行情信息
		async unsubscribeMarketFunc(id) {
			//console.log("---------取消订阅行情------------")
			await this.$tradeHub.subscribeMarketEvent([id], false)
		},
		//取消订阅订单信息
		async unsubscribeTradeFunc(id) {
			//console.log("---------取消订单信息------------")
			await this.$tradeHub.subscribeTradeEvent([id], false)
		},
	},
	activated () {
		//初始化设置行情价格和买入价格为0
		this.price = 0
		this.volume = 0
		this.ratio = 0
		this.market.latestPrice = 0
		//通过地址栏获取交易对ID 
		let tmpId = this.$route.query.id
		//通过地址栏获取是买入还是卖出
		let tmpDirection =this.$route.query.direction
		if(tmpDirection) {
			this.direction = tmpDirection
		}
		//获取交易对信息 
		getSymbols({category: 1, MaxResultCount: 20}).then(async (data) => {
			this.symbols = data.items
			if(tmpId && tmpId !== 'undefined') { //如果地址栏有交易对ID，则根据id选择出当前的交易对信息
				this.curSymbol = this.symbols.filter(item => {
					return item.id == tmpId
				})[0]
			} else { //如果地址栏没有交易对ID则默认第一个交易对信息
				this.curSymbol = this.symbols[0]
			}
			//设置picker的values
			this.symbolsOptions[0].values = this.symbols
			//当前交易对赋值给picker默认选项
			let ind = this.symbols.findIndex(item => {
				return item.id ==this.curSymbol.id
			})
			//console.log("--------this.symbolsOptions-----------")
			//console.log(this.symbolsOptions[0])
			//console.log(ind)
			this.symbolsOptions[0].defaultIndex = ind
			//this.symbolsOptions[0].defaultIndex = 0
			//订阅行情和盘口
			await this.subscribeOrderbookFunc(this.curSymbol.id)
			await this.subscribeMarketFunc(this.curSymbol.id)
			await this.subscribeTradeFunc(this.curSymbol.id)
			//获取用户资产
			if(this.name) { //判断是登录状态
				getCoinAccounts().then(data => {
					this.assets = data.items
					this.usdtAsset = this.assetFilter('usdt')
					//console.log(this.assets)
					//console.log("-----usdt------", this.usdtAsset)
				})
				this.getOrdersFunc()
			}
		})
		//获取全部订单
		//this.getOrdersFunc()
		
	},
	//离开组件执行								
	deactivated() {
		//console.log('destroyed')
		this.unsubscribeOrderbookFunc(this.curSymbol.id)
		this.unsubscribeMarketFunc(this.curSymbol.id)
		this.unsubscribeTradeFunc(this.curSymbol.id)
	}
}
</script>

<style scoped>
.wrapper {
	padding-top: 54px;
	padding-bottom: 60px;
}
.headerNav {
	position: fixed;
	top: 0;
	left: 0;
	height: 54px;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid #e7ebee;
	padding-left: 15px;
	padding-right: 20px;
	z-index: 1;
	background-color: #fff;
}
.headerNav img {
	_width: 150px;
	height: 30px;
	margin-top: 15px;
}
.topNav {
	padding: 0 20px;
	border-bottom: 1px solid #eff2f4;
}
.topNav a {
	color: #8c9fad;
	display: inline;
	font-size: 22px;
	font-weight: 600;
	line-height: 56px;
}
.topNav a.active {
	color: #333;
}
.topNav a.active {
	
}
.topNav a+a {
	margin-left: 30px;
}
.trade-symbol {
	padding-left: 15px;
	height: 50px;
	font-size: 16px;
	font-weight: 600;
	line-height: 50px;
	position: relative;
}
.trade-symbol p {
	margin-right: 20px;
}
.actions {
}
.actions i {
	margin-left: 10px;
	font-size: 18px;
}
.actions i:first-child {
	color: #d8d8d8;
}
.actions i.active {
	color: #333;
}
.trade-panel-title {
	height: 46px;
	padding-left: 15px;
	width: 200px;
	padding-top: 10px;
}
.trade-panel-title button {
	font-size: 20px;
	height: 40px;
	line-height: 46px;
	margin-right: 25px;
	padding-bottom: 10px;
	border-bottom: 2px solid transparent;
	font-weight: 700;
	float: left;
}
.trade-panel-title button.cur:nth-child(1) {
	border-bottom-color: #03ad8f;
}
.trade-panel-title button.cur:nth-child(2) {
	border-bottom-color: #d14b64;
}
.trade-panel-content {
	padding-left: 15px;
	border-bottom: 20px solid #f7f7fb;
	padding-bottom: 20px;
	box-sizing: border-box;
	display: flex;
	width: 100%;
}
.trade-panel-content:after {
	display: block;
	visibility: hidden;
	height: 0;
	clear: both;
	content: ".";
}
.trade-panel-form {
	width: 200px;
	padding-top: 10px;
}
.trade-panel-form .input-box input {
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	border: 1px solid #c8cfd5;
	font-size: 17px;
	font-weight: 600;
	padding-left: 12px;
	padding-right: 12px;
}
.select-box {
	position: relative;
	float: right;
	white-space: nowrap;
}
.select-box select {
	border: none;
	outline: none;
	background: 0 0;
	-webkit-appearance: none;
	color: rgba(31,63,89,.5);
	font-size: 14px;
	line-height: 28px;
	padding-right: 30px;
	width: 100%;
	padding: 10px 20px 10px 0;
}
.arrow {
	top: -3px;
	left:-15px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}
.arrow:before {
	content: "";
	position: absolute;
}
.arrow:before {
	top: 0;
	left: 0;
	border-top: 6px solid #c5cfd5;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}
.equivalent {
	color: #c5cfd5;
	font-size: 10px;
	padding-top: 4px;
	box-sizing: border-box;
	height: 32px;
}
.btn-group {
	border: 1px solid #c8cfd5;
	height: 30px;
	box-sizing: border-box;
}
.btn-group button {
	color: #8c9fad;
	width: 65px;
	height: 28px;
	line-height: 28px;
	font-size: 12px;
	float: left;
}
.btn-group button.cho {
	background-color: #ffcd36;
	color: #000;
}
.btn-group span {
	float: left;
	width: 1px;
	height: 16px;
	margin-top: 6px;
	background: #e7ebee;
}
.total {
	white-space: nowrap;
	height: 30px;
	padding-top: 30px;
	color: #8c9fad;
	font-size: 13px;
}
.trade-panel-form .submit {
	width: 200px;
	height: 46px;
	font-size: 16px;
	line-height: 46px;
	font-weight: 700;
	color: #fff;
}
.trade-panel-form .submit span {
	text-transform: uppercase;
}
/* .trade-panel-form.sell .submit {
	background: #d14b64;
}
.trade-panel-form.buy .submit {
	background: #03ad8f;
} */
.login {
	background-color: #f9f9f9;
	color: #999 !important;
	border:1px #eee solid;
	border-radius: 5px;
	margin-top: 5px;
}
.handicap {
	margin-top: -30px;
	flex: 1;
	margin-left: 20px;
	margin-right: 6px;
	overflow: hidden;
}
.handicap-title {
	font-size: 13px;
	color: #c5cfd5;
	height: 28px;
}
.handicap-title .index {
	margin-right: 20px;
}
.handicap-sell {
	font-size: 12px;
	color: #d14b64;
}
.handicap-buy  {
	font-size: 12px;
	color: #03ad8f;
}
.handicap-sell dl,.handicap-buy dl {
	height: 26px;
	line-height: 26px;
	overflow: hidden;
}
.handicap-sell dl dt,.handicap-buy dl dt {
	width: 25px;
	text-align:left;
}
.handicap-sell dl dd+dd,.handicap-buy dl dd+dd {
	color: #999;
}
.handicap-price {
	padding-top: 6px;
	padding-bottom: 6px;
}
.handicap-price em {
	height: 30px;
	line-height: 30px;
	font-size: 20px;
	font-weight: bold;
}
.handicap-price b.rate {
	font-size: 10px;
	margin-left: 5px;
}
.handicap-price span {
	color: #c5cfd5;
	font-size: 10px;
	line-height: 15px;
	display: block;
}
.trade-order-title {
	height: 60px;
	line-height: 60px;
	font-size: 16px;
	padding: 0 15px;
}
.trade-order-title span {
	display: inline-block;
	line-height: 28px;
	margin-right: 20px;
}
.trade-order-title span.cur{
	color: #ffcd36;
	border-bottom: 1px #ffcd36 solid;
}
.mint-popup-bottom {
	width: 100%;
}
.list .item{
	padding: 5px 0;
	margin: 0 15px;
	font-size: 12px;
	position: relative;
	border-bottom: 1px #eee solid;
}
a.revoke {
	position: absolute;
	right: 0;
	bottom: 29px;
	display: inline-block;
	line-height: 30px;
	width: 60px;
	text-align: center;
	background-color: #ddd;
	border-radius: 5px;
}
.orderInfo {
	padding: 20px 15px;
	position: relative;
}
.orderInfo h1 {
	margin-bottom: 10px;
}
.orderInfo p {
	line-height: 24px;
	font-size: 13px;
}
.orderInfo .btns {
	position: absolute;
	right: 10px;
	top: 25px;
	width: 100px;
}
.orderInfo .btns button {
	font-size: 14px;
	margin:10px 0 10px 0;
	min-width: 100px;
}
</style>
