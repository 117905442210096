import request from "@/utils/request.js"

// 获取所有币种
export function getCoins () {
	return request({
		url: '/api/coins',
		method: 'get',
	})
}

//通过ID获取币种信息
export function getCoinById (id) {
	return request({
		url: '/api/coins/' + id,
		method: 'get',
	})
}

//获取充币地址
export function getRechargeTokenById(tokenId) {
	return request({
		url: '/api/recharge/' + tokenId,
		method: 'get',
	})
}

//提币
export function drawmoney(data) {
	return request({
		url: '/api/drawmoney-requests',
		method: 'post',
		data
	})
}


//交易记录
export function getCoinAccountsRecords (params) {
	return request({
		url: '/api/coin-accounts/records',
		method: 'get',
		params
	})
}

//获取币币账户
export function getCoinAccounts () {
	return request({
		url: '/api/coin-accounts',
		method: 'get',
	})
}