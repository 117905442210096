import Cookies from 'js-cookie'

const TokenKey = 'jys'
const FreshToken = 'fresh'

//设置token
export function getToken () {
	return Cookies.get(TokenKey)
}

export function setToken (token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken () {
	return Cookies.remove(TokenKey)
}

//设置fresh token
export function getFreshToken () {
	return Cookies.get(FreshToken)
}

export function setFreshToken (token) {
	return Cookies.set(FreshToken, token)
}

export function removeFreshToken () {
	return Cookies.remove(FreshToken)
}

