<template>
	<div class="transfer">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('transfer.tit')}}
			<a @click="record()" class="fr history">{{$t('transfer.record')}}</a>
		</div>
		<div class="token">
			<a :class="{'cho':curDrawCoin.name == item.name}"
				v-for="item of canDrawCoins" 
				:key="item.name"
				@click="getChannel(item.id)">
				{{item.name}}
			</a>
		</div>
		<div class="channels">
			<h2>{{$t('transfer.channel')}}：</h2>
			<div>
				<span 
				v-for="item of channels" 
				:key="item.id"
				@click="getToken(item.id, curchannel.name == item.name)"
				class="lowercase"
				:class="{'cho':curchannel.name == item.name}">
				{{item.name}}
				</span>
			</div>
		</div>
		<div class="transfer-box">
			<p class="tit">{{$t('transfer.coin')}} :</p>
			<input type="text" placeholder="name" :value="curDrawCoin.name" disabled="disabled">
			<p class="tit">{{$t('transfer.address')}} :</p>
			<input type="text" placeholder="" v-model="toAddress">
			<p class="tit">{{$t('transfer.amount')}} <em class="red small">({{$t('transfer.min')}} {{curDrawCoin.minDrawmoneyVolume}})</em> :<span>{{$t('transfer.balance')}}：{{curAsset.balance}}</span></p>
			<input type="text" placeholder="0" v-model="amount">
			<div class="fee">
				{{$t('transfer.fee')}}<span>{{curchannel.drawmoneyFee > 0 ? curchannel.drawmoneyFee : $t('transfer.counting') }} {{curDrawCoin.name}}</span>
			</div>
		</div>
		<div class="next">
			<button class="active" @click="firStep()">{{$t('common.next')}}</button>
		</div>
		<mt-popup
		v-model="orderTag"
		position="bottom">
			<!-- 弹出订单 -->
			<div v-show="step==1">
				<div class="order-detail">
					<h1><span @click="showOrder(false)">X</span>{{$t('transfer.detial')}}</h1>
					<h2>{{$t('transfer.number')}}： {{amount}} {{curDrawCoin.name}}</h2>
					<div class="info">
						<p><span class="tit">{{$t('transfer.coin')}}</span><em class="cnt">{{curDrawCoin.name}}</em>&nbsp;&nbsp;<span class="cnt  lowercase">({{$t('transfer.channel')}}:{{curchannel.name}}) </span></p>
						<p class="nobot"><span class="tit">{{$t('transfer.address')}}</span><span class="cnt">{{toAddress}}</span></p>
						<p class="nobot"><span class="tit">{{$t('transfer.fee')}}</span><span class="cnt">{{curchannel.drawmoneyFee+' '+curDrawCoin.name}}</span></p>
					</div>
				</div>
				<div class="next">
					<button class="active" @click="secStep()">{{$t('common.next')}}</button>
				</div>
			</div>
			<!-- 弹出密码输入框 -->
			<div v-show="step==2">
				<div class="order-detail">
					<h1><span @click="stepBack()" class="iconfont fl">&#xe607;</span>{{$t('transfer.assetPwd')}}</h1>
					<div class="info">
						<input class="pwd-input" v-model="passwrod" type="password" :placeholder="$t('transfer.password')">
					</div>
				</div>
				<div class="next">
					<button class="active" @click="submit()">{{$t('common.sure')}}</button>
				</div>
			</div>
		</mt-popup>
	</div>
</template>

<script>
import { getCoins, getCoinById, drawmoney } from '@/api/coin'
import { getCoinAccounts } from '@/api/finance.js'
import { mapGetters } from 'vuex'
export default {
	name: 'Transfer',
	data () {
		return {
			canDrawCoins: [],//可以的提币列表
			curDrawCoin: {},//当前要提取的币种
			channels: [],//充值的通道
			curchannel: {},//当前的通道信息
			assets: [],//客户所有资产
			curAsset: {
				balance: 0,
				coinId: "",
				freeze: 0,
				id: "",
				priceByUsdt: 0
			},//当前选择的资产
			toAddress: '', //收款地址
			amount: 0, // 转账金额
			orderTag: false, //订单详情弹窗
			step: 1,//0,1 弹出订单,2弹出密码
			passwrod: '',//密码
		}
	},
	computed: {
		...mapGetters(['idConfirmed'])
	},
	methods: {
		//通过ID获取币种的提币通道信息
		async getChannel(id) {
			await getCoinById(id).then(data => {
				this.curDrawCoin = data //获取当前要提取的币种信息
				this.channels = data.channels //获取当前提取币种的通道
				this.curchannel = this.channels[0] //获取当前的默认第一个通道
			})
			//获取当前要提取的币种资产
			this.selectAsset()
		},
		//通过tokenId获取当前充值的token信息
		getToken(tokenId, bool) {
			if(bool) {
				return
			}
			//筛选当前充币通道
			this.curchannel = this.channels.filter(item => {
				return item.id == tokenId
			})[0]
		},
		//获取币种的资产
		selectAsset() {
			this.curAsset =  this.assets.filter(item => {
				return item.coinId == this.curDrawCoin.id
			})[0]
		},
		//订单详情的显示与否
		showOrder(tag) {
			this.orderTag = tag
		},
		//订单详情
		firStep() {
			//没有认证
			if(!this.idConfirmed) {
				this.$messagebox({
					title: this.$t('common.confirmFirst'),
					message: this.$t('common.confirmNow'),
					confirmButtonText: this.$t('common.verifyNow'),
					cancelButtonText: this.$t('common.wait'),
					showCancelButton: true
				}).then(action => {
					if(action == 'confirm') {
						this.$router.push('/identity')
					} else {
						//console.log('取消')
					}
				})
				return
			}
			//收款地址初步判断
			if(this.toAddress.length < 10) {
				this.$toast({
					message: this.$t('transfer.wrongAddr'),
					duration: 1000
				})
				return false
			}
			//金额初步判断
			if(this.amount <= 0) {
				this.$toast({
					message: this.$t('transfer.rightNum'),
					duration: 1000
				})
				return false
			}
			//金额小于最小提币金额
			if(this.amount < this.curDrawCoin.minDrawmoneyVolume) {
				this.$toast({
					message: this.$t('transfer.min') + ' : ' + this.curDrawCoin.minDrawmoneyVolume,
					duration: 1000
				})
				return false
			}
			//金额初步判断
			if(this.amount > this.curAsset.balance) {
				this.$toast({
					message: this.$t('transfer.insufficient'),
					duration: 1000
				})
				return false
			}
			this.step = 1
			this.showOrder(true)
		},
		//输入密码
		secStep () {
			this.step = 2
		},
		//输入密码界面的返回事件
		stepBack () {
			this.step = 1
		},
		//确认订单
		submit () {
			let data = {
				"coinId": this.curDrawCoin.id,
				"channelId": this.curchannel.id,
				"address": this.toAddress,
				"amount": this.amount,
				"password": this.passwrod
			}
			drawmoney(data).then(data=>{
				console.log(data)
				this.$toast({
					message: this.$t('transfer.submitSuc'),
					duration: 2000
				})
				this.step = 0
				this.showOrder(false)
			}).catch(err=>{
				this.$toast({
					message: err,
					duration: 2000
				})
				this.showOrder(false)
			})
		},
		//转账记录
		record() {
			this.$router.push({
				path: '/finance-record',
				query: {
					type: 'draw'
				}
			})
		}
	},
	mounted () {
		//获取所有币种筛选出可以提取的币种
		getCoins().then(async data => {
			this.canDrawCoins = data.items.filter(item => {
				return item.canDrawmoney
			}).reverse()
			this.curDrawCoin = this.canDrawCoins[0]
			
			//获取用户资产
			await getCoinAccounts().then(data => {
				this.assets = data.items
			})
			
			//默认获取第一个提取币种的通道信息
			this.getChannel(this.curDrawCoin.id)
			
			
		})
	}
}
</script>

<style scoped>
	.container {
		padding-bottom: 50px;
		min-height: 100%;
	}
	.back {
		text-align: center;
		height: 50px;
		line-height: 50px;
		padding-right: 10px;
	}
	.back span {
		float: left;
		width: 58px;
	}
	.history {
		font-size: 12px;
		color: #9f9f28;
	}
	.channels {
		margin: 20px 10px 10px;
		border: 1px #eee solid;
		padding: 10px;
	}
	.channels h2 {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.channels div {
		display: flex;
		box-sizing: border-box;
	}
	.channels span {
		flex: 1;
		text-align: center;
		background-color: #eee;
		padding: 10px 30px;
		margin: 2px;
	}
	.channels span.cho {
		background-color: #ecec97;
		color: #333;
	}
	.transfer-box {
		padding: 15px;
	}
	p.tit {
		font-size: 13px;
		margin-bottom: 10px;
	}
	p.tit span {
		float: right;
		color: #999;
	}
	.transfer-box input {
		display: block;
		box-sizing: border-box;
		width: 100%;
		height: 50px;
		border: 1px solid #c8cfd5;
		font-size: 17px;
		font-weight: 600;
		padding-left: 12px;
		padding-right: 12px;
		margin-bottom: 20px;
	}
	.fee {
		background-color: #fff;
		height: 46px;
		line-height: 46px;
		border-radius: 5px;
		/* padding: 0 15px; */
	}
	.fee span {
		float: right;
		font-size: 13px;
		color: #999;
	}
	.next {
		position: absolute;
		left: 10px;
		bottom: 10px;
		right: 10px;
		height: 40px;
	}
	.next button {
		width: 100%;
		line-height: 40px;
		border-radius: 5px;
	}
	.next button.active {
		background-color: #57a2ff;
		color: #fff;
	}
	.mint-popup-bottom {
		width: 100%;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
	.order-detail {
		padding-bottom: 60px;
	}
	.order-detail h1 {
		text-align: center;
		line-height: 30px;
		font-size: 14px;
		padding-right: 40px;
		border-bottom: 1px #f7f7f7 solid
	}
	.order-detail h1 span {
		width: 40px;
		float: left;
	}
	.order-detail h1 span img{
		width: 16px;
		height: 16px;
	}
	.order-detail h2 {
		text-align: center;
		line-height: 60px;
		font-size: 20px;
	}
	.info {
		font-size: 12px;
		padding: 5px 10px 10px 20px;
	}
	.info p {
		padding: 10px 0;
		border-bottom: 1px #f7f7f7 solid;
		display: flex;
	}
	.info p span.tit {
		width: 100px;
		display: block;
	}
	.info p span.cnt {
		flex: 1;
		word-break: break-word;
		line-height: 16px;
	}
	input.pwd-input {
		width: 100%;
		height: 40px;
		margin-bottom: 80px;
		background-color: #eee;
		color: #333;
		margin-top: 15px;
		border: 1px #e1e1e1 solid;
		border-radius: 5px;
		text-indent: 10px;
	}
	.nobot {
		border-bottom: none !important;
	}
	.token {
		text-align: center;
	}
	.token a {
		display: inline-block;
		margin: 10px;
		border: 1px #eee solid;
		padding:5px 10px;
		width: 80px;
		box-sizing: border-box;
		border-radius: 5px;
	}
	.token a.cho {
		background: yellow;
		color: red;
		border: 1px #b9b906 solid;
	}
</style>
