const collection = {
	state: {
		collectionId: '',
		collectionName: '',
		tokenStandard: '',
		blockchain:'',
		fee: '',
		paymentTokenId: ''
	},
	mutations: {
		//设置collectionId
		SET_COLLID: (state, collectionId) => {
			state.collectionId = collectionId
		},
		//设置collectionName
		SET_COLLNAME: (state, collectionName) => {
			state.collectionName = collectionName
		},
		//设置tokenStandard
		SET_TOKENSTANDARD: (state, tokenStandard) => {
			state.tokenStandard = tokenStandard
		},
		//设置blockchain
		SET_BLOCKCHAIN: (state, blockchain) => {
			state.blockchain = blockchain
		},
		//设置fee
		SET_FEE: (state, fee) => {
			state.fee = fee
		},
		//设置支付币种tokenID
		SET_PAYMENTTOKENID: (state, paymentTokenId) => {
			state.paymentTokenId = paymentTokenId
		}
	}
}

export default collection