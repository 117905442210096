<template>
    <div class="noData">
        <span class="iconfont">&#xe642;</span>
        <p>{{$t('common.nodata')}}</p>
    </div>
</template>

<script>
export default {
  name: 'Nodata'
}
</script>

<style scoped>
    .noData {
        text-align: center;
    }
    .noData span {
        padding-top: 80px;
        display: inline-block;
		font-size: 68px;
		color: #999;
    }
    .noData p {
        font-size: 12px;
        padding: 20px 0;
        color: #999;
    }
</style>
