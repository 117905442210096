//小数百分比兑换
export const toRate = (num) => {
	let rate = (num * 100).toFixed(2)
	if(num<0) {
		return rate + '%'
	}else {
		return '+' + rate + '%'
	}
};

export const timestampToTime = (timestamp) => {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate() : date.getDate()) + ' ';
        var h = date.getHours() + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y+M+D+h+m+s;
}