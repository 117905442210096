<template>
	<div class="record">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('financeRecord.tit')}}
		</div>
		<div class="type">
			<a :class="{'cho': type=='recharge'}" @click="changeType('recharge')">{{$t('financeRecord.recharge')}}</a>
			<a :class="{'cho': type=='draw'}" @click="changeType('draw')">{{$t('financeRecord.withdraw')}}</a>
		</div>
		<div class="list" v-if="type=='recharge'">
			<div class="item" v-for="item of rechargeRecords" :key="item.id">
				<div>
					<span class="uppercase">{{item.coinId}}</span>
					<span class="fr amount">{{item.amount}}</span>
				</div>
				<!-- <div v-show="item.hash">交易HASH：<span>{{item.hash}}</span></div> -->
				<div>
					<span class="time">{{getLocalTime(item.creationTime)}}</span>
					<span class="fr green">{{$t('common.success')}}</span>
				</div>
			</div>
			<v-nodata v-if="rechargeRecords.length == 0"></v-nodata>
		</div>
		<div class="list" v-if="type=='draw'">
			<div class="item" v-for="(item, index) of drawRecords" :key="item.id">
				<div>
					<span class="uppercase">{{$t('financeRecord.amount')}} : </span>
					<span class="amount">{{item.amount}} {{item.coinId}}</span>
					<span class="time fr">{{getLocalTime(item.creationTime)}}</span>
				</div>
				<div>{{$t('financeRecord.fee')}}：<span class="address">{{item.fee}} {{item.coinId}}</span></div>
				<div>{{$t('financeRecord.address')}}：<span class="address">{{item.address}}</span></div>
				<div class="address" v-show="item.hash">{{$t('financeRecord.transaction')}}HASH：<span>{{item.hash}}</span></div>
				<div>
					{{$t('financeRecord.status')}}：
					<span :class="item.status.id | colorFilter">{{statusFunc(item.status.id)}}</span>
					<mt-button @click="revokeDrawFunc(item.id, index)" v-if="item.status.id==1" type="primary" size="small" class="fr">{{$t('common.revoke')}}</mt-button>
				</div>
				<div v-if="item.error" class="reason red">{{$t('common.errReason')}}： {{item.error}}</div>
			</div>
			<v-nodata v-if="drawRecords.length == 0"></v-nodata>
		</div>
	</div>
</template>

<script>
import { timestampToTime } from "@/utils/third.js"
import { getRechargeRecords, getDrawRecords, revokeDraw } from '@/api/finance.js'
import vNodata from "@/components/Nodata.vue"
export default {
	name: 'FinanceRecord',
	components: {
		vNodata
	},
	data () {
		return {
			type: 'recharge' ,//充值 、提款
			records: [],
			rechargeRecords: [],//充值记录
			drawRecords: [],//提款记录
		}
	},
	filters: {
		colorFilter (id) {
			switch(id) {
				case 1: return 'blue';
				case 2: return 'red';
				case 3: return 'red';
				case 4: return 'yellow';
				case 5: return 'green';
				default: return 'blue'
			}
		}
	},
	methods: {
		//订单状态
		statusFunc (id) {
			switch(id) {
				case 1: return this.$t('financeRecord.status1');
				case 2: return this.$t('financeRecord.status2');
				case 3: return this.$t('financeRecord.status3');
				case 4: return this.$t('financeRecord.status4');
				case 5: return this.$t('financeRecord.status5');
			}
		},
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
		//切换记录类型
		changeType (type) {
			this.type = type
			if(this.type=="recharge") {
				if(this.rechargeRecords.length) {
					return
				} else {
					this.getRechargeFunc()
				}
			}
			if(this.type=="draw") {
				if(this.drawRecords.length) {
					return
				} else {
					this.getDrawFunc()
				}
			}
			
		},
		//获取充值记录
		getRechargeFunc () {
			getRechargeRecords().then(data => {
				this.rechargeRecords = data.items.reverse()
			})
		},
		//获取提款记录
		getDrawFunc () {
			getDrawRecords().then(data => {
				console.log(data)
				this.drawRecords = data.items.reverse()
			})
		},
		//撤销提款
		revokeDrawFunc(id, ind) {
			revokeDraw(id).then(() => {
				this.$toast({
					message: this.$t('financeRecord.revokeSuc'),
					duration: 1500
				})
				this.drawRecords.splice(ind, 1)
			})
		}
		
	},
	mounted () {
		//获取类型
		this.type = this.$route.query.type || 'recharge'
		if(this.type == 'draw') {
			this.getDrawFunc()
		}else {
			this.type = 'recharge'
			//获取充值记录
			this.getRechargeFunc()
		}
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.type {
	text-align: center;
	margin: 10px 0;
}
.type a {
	display: inline-block;
	width: 100px;
	padding: 5px 20px;
	_background-color: blue;
	border: 1px #eee solid;
	margin: 0 10px;
	font-size: 12px;
	border-radius: 5px;
}
.type a.cho {
	background-color: yellow;
}
.list {
	padding: 10px 20px;
	font-size: 14px;
}
.list .item {
	padding: 5px;
	border-bottom: 1px #eee dashed;
}
.list .item div {
	margin-bottom: 5px;
	line-height: 26px;
}
.amount {
	font-weight: bold;
}
.time {
	font-size: 12px;
	color: #999;
}
.address,.reason {
	font-size: 12px;
	word-break: break-all;
}
.mint-button--small {
	font-size: 12px;
	height: 26px;
	padding: 0 15px;
	margin-bottom: 5px;
}
</style>
