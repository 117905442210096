import request from "@/utils/request3.js"

// 上传图片
export function uploadIdentity(file, type) {
	return request({
		url: '/files/base64/user_profile/images/identity-verifies',
		data: {
			data: file,
			type,
		},
		method: 'post',
	})
}