<template>
	<div>
		<v-header></v-header>
		<div class="container">
			<div class="banner">
				<mt-swipe :auto="4000">
					<mt-swipe-item v-for="banner of banners" :key="banner.id">
						<a :href="banner.link"><img :src="banner.resourceUrl"></a>
					</mt-swipe-item>
					<!-- <mt-swipe-item>
						<img src="../assets/img/banner.png" width="100%">
					</mt-swipe-item> -->
				</mt-swipe>
			</div>
			<div class="notice">
				<i class="iconfont">&#xe628;</i>
				<marquee scrollamount="3" behavior="scroll">
					<a @click="toNew(notice.id)" v-for="notice of notices" :key="notice.id">{{notice.title}}</a>
				</marquee>
				<a @click="toNews()" class="more iconfont">&#xe614;</a>
			</div>
			<div class="nav tc bgwhite">
				<ul>
					<li>
						<router-link to="/deposit">
							<i><img src="../assets/img/recharge_icon.png"></i>
							{{$t('index.recharge')}}
						</router-link>
					</li>
					<li>
						<router-link to="/second">
							<i><img src="../assets/img/contract_icon.png"></i>
							{{$t('index.second')}}
						</router-link>
					</li>
					<!-- <li>
						<a>
							<i><img src="../assets/img/join_icon.png"></i>
							合买大厅
						</a>
					</li> -->
					<!-- <li>
						<a>
							<i><img src="../assets/img/mining_icon.png"></i>
							挖矿宝
						</a>
					</li> -->
					<li>
						<router-link to="/share">
							<i><img src="../assets/img/share_icon.png"></i>
							{{$t('index.share')}}
						</router-link>
					</li>
					<!-- <li>
						<a>
							<i><img src="../assets/img/help_icon.png"></i>
							帮助中心
						</a>
					</li> -->
					<li>
						<a @click="service()">
							<i><img src="../assets/img/service_icon.png"></i>
							{{$t('index.online')}}
						</a>
					</li>
					<!-- <li>
						<a>
							<i><img src="../assets/img/game_icon.png"></i>
							趣味游戏
						</a>
					</li> -->
				</ul>
			</div>
			<div class="buyu" @click="toPlatform()">
				<span><img src="../assets/img/shop_icon.png"></span>
				<div>
					<h2>{{$t('index.buyUsdt')}}</h2>
					<p>{{$t('index.buyUsdtIntro')}}</p>
				</div>
				<i class="arrow iconfont">&#xe604;</i>
			</div>
			<div class="top3 tc bgwhite">
				<ul>
					<li @click="symbolClick(symbol.id, symbol.name)" v-for="symbol of topThree" :key="symbol.id">
						<h1>{{symbol.name}}</h1>
						<h2 :class="[symbol.market.change > 0 ? 'green' : 'red']">{{symbol.market.latestPrice}}</h2>
						<p>{{toRateFunc(symbol.market.change)}}</p>
					</li>
					<!-- <li>
						<h1>BTC/USDT</h1>
						<h2 class="red">64033.86</h2>
						<p>+2.91%</p>
					</li>
					-->
				</ul>
			</div>
			<div class="topList bgwhite">
				<h1 class="tit">{{$t('index.ranking')}}</h1>
				<div @click="symbolClick(symbol.id, symbol.name)" class="item" v-for="symbol of symbolsRang" :key="symbol.id">
					<div>
						<h2>{{symbol.baseCoinId}}<i>/{{symbol.quoteCoinId}}</i></h2>
						<p>24HVol {{symbol.market.turnover.toFixed(0)}}</p>
					</div>
					<div>
						<h2>{{symbol.market.latestPrice}}</h2>
						<p>{{(symbol.market.latestPrice * exchangeRateByUSD).toFixed(2)}} <i>{{currency}}</i></p>
					</div>
					<span :class="symbol.market.change>=0 ? 'bggreen':'bgred'">{{toRateFunc(symbol.market.change)}}</span>
				</div>
				<!-- <div class="item">
					<div>
						<h2>ETH <i>/usdt</i></h2>
						<p>24HVol 9019201</p>
					</div>
					<div>
						<h2>64033.86</h2>
						<p>¥409281</p>
					</div>
					<span class="bggreen">+2.19%</span>
				</div> -->
			</div>
		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import vHeader from '@/components/Header'
import vFooter from '@/components/Footer'
import { getSymbols, getSymbolsDayMarket } from '@/api/symbols.js'
import { toRate }from "@/utils/third.js"
import { getBanner, getNotices } from '@/api/setting.js'
export default {
  name: 'Index',
  components: {
	vHeader,
	vFooter,
  },
  computed: {
	...mapGetters(['siteService','currency','exchangeRateByUSD'])
  },
  data () {
	return {
		symbols: [] , //交易对
		symbolsRang: [],//交易对排序
		symbolsIDs: [], //交易对ID
		banners: [],//banner
		notices: [],//notices
		topThree: [],//前三列
	}
  },
  methods: {
	//去往购买USDT平台列表
	toPlatform() {
		this.$router.push("/platform")
	},
	//去往新闻列表
	toNews () {
		this.$router.push("/news")
	},
	//去往新闻详情页
	toNew (id) {
		this.$router.push({
			path: '/new',
			query: {
				id
			}
		})
	},
	//获取banner
	getBannerFunc () {
		getBanner().then(data => {
			//console.log('-----获取banner-------')
			//console.log(data)
			this.banners = data.items
		})
	},
	//获取notice
	getNoticesFunc () {
		getNotices().then(data => {
			//console.log('-----获取notice-------')
			//console.log(data)
			this.notices = data.items
		})
	},
	//客服
	service () {
		this.$router.push("/message")
	},
	//小数换成百分比
	toRateFunc (num) {
		return toRate(num)
	},
	//点击交易对,进入走势界面
	symbolClick(id, name) {
		this.$router.push({
			path: '/trend',
			query: {
				id,
				name
			}
		})
	},
	//订阅行情
	subscribeMarketFunc(ids) {
		//订阅行情
		//console.log("---------订阅行情------------")
		this.$tradeHub.subscribeMarketEvent(ids)
		//接收实时行情数据
		this.$tradeHub.onReceived(this.$tradeHub.CallbackEvents.OnSymbolDayMarketChangedAsync, (data) =>{
			//console.log("---------接收行情数据------------")
			//console.log(data)
			let index = this.symbols.findIndex(symbol => symbol.id == data.symbolId)
			this.symbols[index].market = data.market
			this.$set(this.symbols,index,this.symbols[index])
			this.symbolsRang = this.symbols.concat([]).sort((a,b) => {
				return b.market.change - a.market.change
			})
			this.topThree = this.symbols.slice(0,3)
		})
	},
	//取消订阅行情
	unsubscribeMarketFunc(ids) {
		//取消订阅行情
		//console.log("---------取消订阅行情------------")
		this.$tradeHub.subscribeMarketEvent(ids, false)
	}
  },
  //销毁组件取消行情的订阅
  deactivated() {
	//console.log("beforeDestroy")
	if(this.symbolsIDs) {
		//取消订阅行情
		this.unsubscribeMarketFunc(this.symbolsIDs)
	}
  },
  mounted() {
	this.getBannerFunc()
	this.getNoticesFunc()
  },
  activated() {
	this.symbolsIDs = []
	//获取交易对信息
	getSymbols({category: 1}).then(data => {
		this.symbols = data.items
		this.symbols.forEach(item => {
			this.symbolsIDs.push(item.id)
			item.market = {
				change: 0,
				highestPrice: 0,
				latestPrice: 0,
				lowestPrice: 0,
				turnover: 0,
				updateTime: 0,
				volume: 0,
			}
		})
		//首次进入初始化行情
		getSymbolsDayMarket({symbols:this.symbolsIDs.toString()}).then(data => {
			//console.log("---------首次进入初始化行情数据------------")
			//console.log(data)
			data.items.forEach((item,index) => {
				this.symbols[index].market = item.market
				this.$set(this.symbols,index,this.symbols[index])
			})
			this.symbolsRang = this.symbols.concat([]).sort((a,b) => {
				return b.market.change - a.market.change
			})
			this.topThree = this.symbols.slice(0,3)
			//console.log(this.symbols)
		})
		//调用订阅行情函数
		this.subscribeMarketFunc(this.symbolsIDs)
	})
  }
}
</script>

<style scoped>
.container {
	padding-top: 54px;
}
.banner {
	padding: 0 10px;
	border-radius: 5px;
	height: 150px;
	line-height: 0;
}
.banner img {
	height: 100%;
	width: 100%;
	border-radius: 7px;
}
.notice {
	display: flex;
	padding: 10px 15px;
	margin-top: 5px;
	height: 30px;
	line-height: 30px;
}
marquee {
	margin-right: 10px;
}
marquee a{
	font-size: 14px !important;
	margin-right: 30px;
}
.notice i {
	vertical-align: middle;
	font-size: 20px;
	margin-right: 10px;
}
.notice p {
	flex: 1;
	font-size: 14px;
}
.nav {
	padding: 10px 0;
	overflow: hidden;
	margin:2px 10px 0;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.nav ul li {
	width: 25%;
	float: left;
}
.nav ul li a {
	display: block;
	font-size: 12px;
	margin: 8px;
}
.nav ul li a i {
	display: block;
	font-size: 25px;
	color: #f1d724;
	line-height: 30px;
}
.nav ul li a img {
	height: 30px;
	display: inline-block;
}
.buyu {
	display: flex;
	height: 40px;
	padding: 10px;
}
.buyu span {
	vertical-align: middle;
	margin-right: 10px;
	line-height: 40px;
}
.buyu span img {
	height: 40px;
	margin: 0 5px;
}
.buyu div {
	flex:1;
}
.buyu div h2 {
	font-size: 15px;
	margin-bottom: 5px;
}
.buyu div p {
	font-size: 12px;
	color: #666;
}
.buyu .arrow {
	display: inline-block;
	width: 24px;
	height: 24px;
	background: #eee;
	border-radius: 50%;
	text-align: center;
	line-height: 24px;
	margin-top: 7px;
}
.top3 {
	padding: 15px 10px;
}
.top3 ul li{
	width: 33.3%;
	float: left;
}
.top3 ul li h1 {
	font-size: 12px;
	color: #666;
	font-weight: normal;
}
.top3 ul li h2 {
	font-size: 18px;
	margin: 5px 0;
}
.top3 ul li p {
	font-size: 12px;
}
.topList h1.tit {
	font-size: 18px;
	height: 48px;
	line-height: 48px;
	padding-left: 10px;
	border-bottom: 1px #f5f5f5 solid;
}
.topList .item {
	display: flex;
	border-bottom: 1px solid #f7f7fb;
	padding: 0 20px;
	white-space: nowrap;
}
.topList .item>div {
	flex: 1;
	padding: 10px 0;
}
.topList .item>div h2 {
	font-size: 15px;
	font-weight: normal;
	height: 26px;
	line-height: 26px;
	text-transform: uppercase
}
.topList .item>div h2 i {
	_font-size: 14px;
	color: #999;
	_text-transform: lowercase
}
.topList .item>div p {
	font-size: 13px;
	color: #999;
}
.topList .item span{
	text-align: center;
	width: 90px;
	height: 40px;
	font-size: 16px;
	line-height: 40px;
	margin-top: 14px;
	border-radius: 3px;
	font-weight: bold;
	color: #fff;
}
.mint-swipe {
	height: 150px;
}
.mint-swipe img {
	height: 100%;
}
</style>
