<template>
	<div class="news">
		<div class="back">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			<!-- {{$t('about.tit')}} -->
			{{about.title}}
		</div>
		<div class="box">
			<div class="tit">{{about.subTitle}}</div>
			<!-- <div class="time">{{getLocalTime(about.creationTime)}}</div> -->
			<div class="cnt" v-html="cntHtml"></div>
		</div>
	</div>
</template>

<script>
import { timestampToTime } from "@/utils/third.js"
import { getAbout, getHtml } from '@/api/setting.js'
export default {
	name: 'AboutDetail',
	data () {
		return {
			about: {} ,//关于我们的内容
			cntHtml: '',//内容HTML
		}
	},
	methods: {
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
	},
	mounted () {
		let id = this.$route.query.id
		getAbout().then(data => {
			this.about = data.items.filter(item => {
				return item.id == id
			})[0]
			
			getHtml(this.about.resourceUrl).then(data => {
				//console.log(data)
				this.cntHtml = data
			})
		})

	}
}
</script>

<style scoped>
	.back {
		text-align: center;
		height: 50px;
		line-height: 50px;
		padding-right: 60px;
	}
	.back span {
		float: left;
		width: 60px;
	}
	.box {
		padding: 20px 15px;
		text-align: center;
	}
	.box .tit {
		font-size: 20px;
	}
	.time {
		font-size: 12px;
		margin-top: 8px;
		color: #999;
	}
	.box .cnt {
		text-align: left;
		margin-top: 20px;
		font-size: 14px;
	}
</style>
