import request from "@/utils/request.js"


//获取用户资产
export function getCoinAccounts () {
	return request({
		url: '/api/coin-accounts',
		method: 'get',
	})
}

//获取用户对应ID的资产
export function getCoinAccountByID (id) {
	return request({
		url: '/api/coin-accounts/'+ id,
		method: 'get',
	})
}

//获取充值记录
export function getDrawRecords (params) {
	return request({
		url: '/api/drawmoney-requests',
		method: 'get',
		params
	})
}

//获取提款记录
export function getRechargeRecords (params) {
	return request({
		url: '/api/recharge/records',
		method: 'get',
		params
	})
}

//撤销提款
export function revokeDraw (id) {
	return request({
		url: '/api/drawmoney-requests/'+id,
		method: 'delete'
	})
}
