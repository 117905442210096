import request from "@/utils/request.js"

//获取站点信息
export function getSite () {
	return request({
		url: '/api/settings/site',
		method: 'get',
	})
}

//获取国家信息
export function getCountries () {
	return request({
		url: '/api/settings/countries',
		method: 'get',
	})
}

//获取汇率信息
export function getCurrencies () {
	return request({
		url: '/api/settings/currencies',
		method: 'get',
	})
}

//获取语言信息
export function getLangs () {
	return request({
		url: '/api/settings/languages',
		method: 'get',
	})
}

//获取服务器时间
export function getServerTime () {
	return request({
		url: '/api/settings/timestamp',
		method: 'get',
	})
}

//获取滚动banner等
export function getBanner () {
	let params = {
		CategoryId: 'home_banner'
	}
	return request({
		url: '/api/settings/news',
		method: 'get',
		params
	})
}

//获取站点公告
export function getNotices () {
	let params = {
		CategoryId: 'notice'
	}
	return request({
		url: '/api/settings/news',
		method: 'get',
		params
	})
}

//获取购买USDT平台列表
export function getPlatform () {
	let params = {
		CategoryId: 'buy_usdt'
	}
	return request({
		url: '/api/settings/news',
		method: 'get',
		params
	})
}

//获取站点内容
export function getHtml(url) {
    return request({
        url,
        method: 'get',
    })
}

//获取关于我们
export function getAbout () {
	let params = {
		CategoryId: 'about'
	}
	return request({
		url: '/api/settings/news',
		method: 'get',
		params
	})
}