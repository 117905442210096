const getters = {
	token: state => state.user.token,
	name: state => state.user.name, //用户名
	id: state => state.user.id, //用户ID
	country: state => state.user.country, //用户country
	inviteCode: state => state.user.inviteCode, // 用户邀请码
	hasAssetPassword: state => state.user.hasAssetPassword,//是否设置资金密码
	idConfirmed: state => state.user.idConfirmed, //是否认证
	email: state => state.user.email,//用户邮箱
	sitename: state => state.site.name, //网站名称
	sitelogo: state => state.site.logo,//网站logo
	siteLangName: state => state.site.langName,
	siteService: state => state.site.service,// 网站客服
	langName: state => state.site.langName,//网站语言
	langId: state => state.site.langId,//网站语言Id
	currency: state => state.user.currency,// 计价单位
	exchangeRateByUSD: state => state.user.exchangeRateByUSD,//兑换USD价格
	collectionId: state => state.nft.collectionId, //集合ID
	collectionName: state => state.nft.collectionName, // 集合name
	tokenStandard: state => state.nft.tokenStandard, //集合tokenStandard
	blockchain: state => state.nft.blockchain, //集合blockchain
	fee: state => state.nft.fee, //集合fee
	paymentTokenId: state => state.nft.paymentTokenId
}

export default getters