<template>
	<div class="identity">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('identity.tit')}}
		</div>
		<div>
			<div class="tit">
				<div v-show="verifyStatus == 0">
					<h1>{{$t('identity.plsInput')}}</h1>
					<p>{{$t('identity.oneBindTs')}}</p>
				</div>
				<div v-show="verifyStatus == 1">
					<h1>{{$t('identity.status1')}}</h1>
				</div>
				<div v-show="verifyStatus == 2">
					<h1 class="green">{{$t('identity.status2')}}</h1>
				</div>
				<div v-show="verifyStatus == 3">
					<h1 class="red">{{$t('identity.status3')}}</h1>
					<p>{{$t('identity.errReason')}}：{{errTs}}</p>
				</div>
			</div>
			<div class="cnt">
				<div class="inBox" v-show="verifyStatus != 2">
					<div>{{$t('identity.name')}}<input type="text" v-model="realName" :disabled="verifyStatus==1 || verifyStatus==2"></div>
					<div>{{$t('identity.id')}}<input type="text" v-model="idCardNumber" :disabled="verifyStatus==1 || verifyStatus==2"></div>
				</div>
				<div class="imgBox" v-show="verifyStatus != 2">
					<p><span v-show="verifyStatus == 3 || !verifyStatus">{{$t('identity.plsUp')}}</span>{{$t('identity.frontImg')}}</p>
					<div>
						<input name="fs" type="file" accept="image/png,image/jpg,image/gif" id="upload_file1" style="display: none;" 
						@change="fileChange($event, 'front')">
						<img :src="frontImg" @click="imgClick1()">
					</div>
					<p><span v-show="verifyStatus == 3 || !verifyStatus">{{$t('identity.plsUp')}}</span>{{$t('identity.backImg')}}</p>
					<div>
						<input type="file" accept="image/png,image/jpg,image/gif" id="upload_file2" style="display: none;" 
						@change="fileChange($event, 'back')">
						<img :src="backImg" @click="imgClick2()">
					</div>
				</div>
				<div class="btn">
					<mt-button @click="submit()" type="primary" size="large"
					v-if = 'verifyStatus == 0'
					>{{$t('identity.status4')}}</mt-button>
					<mt-button disabled type="default" size="large"
					v-if = 'verifyStatus == 1'
					>{{$t('identity.status5')}}</mt-button>
					<mt-button disabled type="primary" size="large"
					v-if = 'verifyStatus == 2'
					>{{$t('identity.status6')}}</mt-button>
					<mt-button @click="submit()" type="default" size="large"
					v-if = 'verifyStatus == 3'
					>{{$t('identity.status7')}}</mt-button>
				</div>
			</div>
			<div class="ts"  v-show="verifyStatus != 2">
				<h1>{{$t('identity.requireTis')}}</h1>
				<p>{{$t('identity.require1')}}</p>
				<p>{{$t('identity.require2')}}</p>
				<p>{{$t('identity.require3')}}</p>
				<p>{{$t('identity.require4')}}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { uploadIdentity } from "@/api/upload.js"
import { identity, getIdentity } from "@/api/user.js"
export default {
	name: 'Identity',
	data () {
		return {
			realName: '',//真实名称
			idCardNumber: '',//身份证号码
			idCardFrontView: '',//身份证正面
			idCardBackView:'',//身份证反面
			frontImg: require('../assets/img/identity1.png'),//正面照
			backImg: require('../assets/img/identity2.png'), //反面照
			verifyStatus: 0,//认证状态
			errTs: '',//认证失败错误提示
		}
	},
	methods: {
		imgClick1() {
			//等待审核和审核通过
			if(this.verifyStatus == 1 || this.verifyStatus == 2) {
				return
			}
			document.getElementById('upload_file1').click()
		},
		imgClick2() {
			//等待审核和审核通过
			if(this.verifyStatus == 1 || this.verifyStatus == 2) {
				return
			}
			document.getElementById('upload_file2').click()
		},
		//选择文件上次
		fileChange(event, tag) {
			//console.log(event.target.files)
			let file = event.target.files[0]
			let type = file.type
			//文件不能大于10M
			if(file.size > 10485760) {
				this.$toast({
					message: this.$t('identity.limit'),
					duration: 1000
				})
			}
			let reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				uploadIdentity(reader.result, type).then(data => {
					//console.log(data)
					if(tag == 'front') {
						this.idCardFrontView = data.fileName
						this.frontImg = data.url
					}
					if(tag == 'back') {
						this.idCardBackView = data.fileName
						this.backImg = data.url
					}
				})
			}
		},
		//提交审核
		submit() {
			let data = {
				"realName": this.realName,
				"idCardNumber": this.idCardNumber,
				"idCardFrontView": this.idCardFrontView,
				"idCardBackView": this.idCardBackView
			}
			
			identity(data).then(data => {
				console.log(data)
				this.$toast({
					message: this.$t('identity.submitSuc'),
					duration: 1000
				})
			})
		}
	},
	mounted () {
		//获取认证信息
		getIdentity().then(data => {
			//console.log(data)
			this.verifyStatus = data.status.id
			this.realName = data.realName
			this.idCardNumber = data.idCardNumber
			this.frontImg = data.idCardFrontView
			this.backImg = data.idCardBackView
			this.errTs = data.error
		}).catch(err => {
			console.log(err)
			this.verifyStatus = 0
		})
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.tit {
	padding: 20px 15px;
	background-color: #f9f9f9;
}
.tit h1 {
	font-size: 15px;
	margin-bottom: 10px;
}
.tit p {
	font-size: 13px;
	color: #999;
}
.inBox div{
	display: flex;
	font-size: 14px;
	height: 46px;
	line-height: 46px;
	padding: 0 10px;
	border-bottom: 1px #eee solid;
}
.inBox div input {
	flex: 1;
	margin-left: 10px;
	color: #26a2ff;
}
.imgBox {
	padding: 15px 0 10px;
	text-align: center;
}
.imgBox p {
	font-size: 13px;
	margin-bottom: 10px;
}
.imgBox div {
	margin-bottom: 30px;
}
.imgBox img {
	width: 200px;
	opacity: 0.7;
	max-height: 200px;
}
.btn {
	padding: 10px;
	margin-bottom: 10px;
}
.ts {
	padding: 0 15px 15px;
}
.ts h1 {
	font-size: 14px;
	margin-bottom: 10px;
}
.ts p {
	font-size: 12px;
}
</style>
