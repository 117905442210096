import request from "@/utils/request.js"

// 获取所有秒合约
export function getSecondContracts () {
	return request({
		url: '/api/second-contracts',
		method: 'get',
	})
}
// 根据ID获取秒合约信息
export function getSecondContractByID (id) {
	return request({
		url: '/api/second-contracts/' + id,
		method: 'get',
	})
}
//秒合约下单
export function secondContractTrade(data) {
	return request({
		url: '/api/second-contracts/positions',
		method: 'post',
		data
	})
}
//获取秒合约的订单
export function getSecondContractRecords(params) {
	return request({
		url: '/api/second-contracts/positions',
		method: 'get',
		params
	})
}