<template>
	<div class="setting">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('setting.tit')}}
		</div>
		<div class="list">
			<div class="item" @click="showLangs()">
				<span>{{$t('setting.lang')}}</span><span class="fr">{{langName}} <em class="iconfont">&#xe62a;</em></span>
			</div>
			<div class="item" @click="showCurrency()">
				<span>{{$t('setting.price')}}</span><span class="fr">{{currency}} <em class="iconfont">&#xe62a;</em></span>
			</div>
		</div>
		<mt-popup
			v-model="langTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="langOptions" value-key='displayName' @change="selectLang"></mt-picker>
		</mt-popup>
		<mt-popup
			v-model="currencyTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="currencyOptions" value-key='displayName' @change="selectCurrency"></mt-picker>
		</mt-popup>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLangs, getCurrencies } from '@/api/setting.js'
import { changeUserInfo } from "@/api/user.js"
export default {
	name: 'Setting',
	computed: {
		...mapGetters(['langName', 'langId', 'currency','country'])
	},
	data () {
		return {
			langTag: false,
			currencyTag: false,
			langOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}],
			currencyOptions: [{
				flex: 1,
				values: [],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}],
		}
	},
	methods: {
		//显示所有可选语言
		showLangs () {
			this.langTag = !this.langTag
		},
		//显示所有可选货币
		showCurrency () {
			this.currencyTag = !this.currencyTag
		},
		//选择语言
		selectLang (picker, values){
			if(!this.langTag) {
				return
			}
			//还未获取到全部语言
			if(this.langOptions[0].values.length == 0) {
				return
			}
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			if(picker.getValues()[0]) {
				let tmpLangName = picker.getValues()[0].displayName
				let tmpLangId = picker.getValues()[0].cultureName
				//保存语言设置
				this.$store.commit("SET_SITELANGNAME", tmpLangName) //语言名称
				this.$store.commit("SET_SITELANGID", tmpLangId) //语言标识
				this.$i18n.locale = tmpLangId
			}
		},
		//选择计价单位
		selectCurrency (picker, values){
			if(!this.currencyTag) {
				return
			}
			//还未获取到全部语言
			if(this.currencyOptions[0].values.length == 0) {
				return
			}
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			if(picker.getValues()[0]) {
				let tmpCurrency = picker.getValues()[0].displayName
				//let tmpRateBuUSD = picker.getValues()[0].exchangeRateByUSD
				//保存计价
				this.$store.commit("SET_CURRENCY", tmpCurrency) //计价名称
				//this.$store.commit("SET_RATEBYUSD", tmpRateBuUSD) //计价
				changeUserInfo({'currency': tmpCurrency, 'country': this.country}).then(() => {
					//console.log('修改用户信息成功')
				})
			}
		},
	},
	mounted () {
		//获取所有语言赋值给picker
		getLangs().then(data => {
			this.langOptions[0].values=data.items
			//根据当前的语言找到picker默认的是哪一项从而设置defaultIndex
			let ind = this.langOptions[0].values.findIndex(item => {
				return item.displayName == this.langName
			})
			this.langOptions[0].defaultIndex = ind
		})
		//获取所有货币赋值给picker
		getCurrencies().then(data => {
			this.currencyOptions[0].values=data.items
			//根据当前的语言找到picker默认的是哪一项从而设置defaultIndex
			let ind = this.currencyOptions[0].values.findIndex(item => {
				return item.displayName == this.currency
			})
			this.currencyOptions[0].defaultIndex = ind
		})
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.list {
	padding: 0 0 0 10px;
}
.item {
	height: 44px;
	line-height: 44px;
	border-bottom: 1px #eee solid;
	padding-right: 10px;
}
.item span.fr {
	font-size: 12px;
	color: #999;
}
.item span em {
	margin-left: 10px;
	vertical-align: middle;
}
.mint-popup-bottom {
	width: 100%;
}
</style>
