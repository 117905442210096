import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js' //用户
import site from './modules/site.js'
import nft from './modules/nft.js'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules:{
		user,
		site,
		nft
	},
	getters
})

export default store

