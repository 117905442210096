<template>
	<div class="record">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('coinRecord.tit')}}
			<mt-button class="select fr" plain size="small" @click="showTag(true)">{{$t('coinRecord.select')}}</mt-button>
		</div>
		<div class="list">
			<div class="item" v-for="item of curRecords" :key="item.id">
				<div>{{$t('coinRecord.type')}}：<span>{{typeArr[item.category.id-1]}}</span></div>
				<div>{{$t('coinRecord.change')}}： <span :class="[item.changedAmount>0 ? 'green' :'red']">
					<em v-if="item.changedAmount>0">+</em>{{item.changedAmount}} {{item.coinId}}
				</span></div>
				<div class="time">{{$t('common.time')}}：<span>{{getLocalTime(item.creationTime)}}</span></div>
			</div>
		</div>
		<v-nodata v-if="curRecords.length == 0"></v-nodata>
		<mt-popup
			v-model="typeTag"
			position="bottom"
			modal="false">
			<mt-picker :slots="typeOptions" value-key='text' @change="selectType"></mt-picker>
		</mt-popup>
	</div>
</template>

<script>
import { timestampToTime } from "@/utils/third.js"
import {getCoinAccountsRecords, getCoinAccounts } from "@/api/coin.js"
import vNodata from "@/components/Nodata"
export default {
	name: 'CoinRecord',
	components: {
		vNodata
	},
	data () {
		return {
			records: [],//记录
			curRecords: [],//当前筛选的记录
			coinAccounts: [],//币币列表
			typeArr: [
				this.$t('coinRecord.all'),
				'划转',
				this.$t('coinRecord.recharge'),
				this.$t('coinRecord.withdraw'),
				this.$t('coinRecord.system')
			],
			typeTag: false,
			typeOptions: [{
				flex: 1,
				values: [
					{
						text: this.$t('coinRecord.all'),
						ind: 0
					},
					{
						text: this.$t('coinRecord.exchange'),
						ind: 1
					},
					// {
					// 	text: '划转',
					// 	ind: 2
					// },
					{
						text: this.$t('coinRecord.recharge'),
						ind: 3
					},
					{
						text: this.$t('coinRecord.withdraw'),
						ind: 4
					},
					{
						text: this.$t('coinRecord.system'),
						ind: 5
					},
				],
				className: 'slot1',
				textAlign: 'center',
				defaultIndex: 0
			}],
		}
	},
	methods: {
		showTag(tag) {
			this.typeTag = tag
		},
		//切换记录类型
		selectType(picker, values) {
			if (values[0] > values[1]) {
				picker.setSlotValue(1, values[0]);
			}
			this.selectRecord(picker.getValues()[0].ind)
		},
		//筛选记录
		selectRecord (ind) {
			if(ind == 0) {
				this.curRecords = [].concat(this.records)
				return
			}
			this.curRecords = this.records.filter(item => {
				return item.category.id == ind
			})
		},
		//时间戳转换成时间
		getLocalTime(nS) {  
			return timestampToTime(nS) 
		},
		//查找币种
		findCoin (id) {
			return this.coinAccounts.find(item => {
				return item.id == id
			})[0]
		}
	},
	async mounted () {
		await getCoinAccounts().then(data => {
			this.coinAccounts = data.items
		})
		getCoinAccountsRecords().then(data => {
			//console.log(data.items)
			this.records = data.items
			//遍历记录的ID从来根据币种列表查出币的名称coinId
			this.records.forEach(record => {
				let coinId = this.coinAccounts.find(item => {
					return item.id == record.accountId
				})['coinId']
				this.$set(record, 'coinId', coinId)
			})
			this.curRecords = [].concat(this.records)
			//console.log(this.records)
		})
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 10px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.select {
	font-size: 12px;
	margin-top: 15px;
	height: 26px;
}
.list {
	padding:10px;
	line-height: 26px;
	font-size: 14px;
}
.list .item {
	border-bottom: 1px #eee dashed;
}
.list .time {
	font-size: 12px;
	color: #999;
}
.mint-popup-bottom {
	width: 100%;
}
</style>

