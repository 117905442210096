const site = {
	state: {
		name: '',
		logo: '',
		service: '',
		langName: 'English',
		langId: 'en',
	},
	mutations: {
		//设置网站名称
		SET_SITENAME: (state, name) => {
			state.name = name
		},
		//设置网站logo
		SET_SITELOGO: (state, logo) => {
			state.logo = logo
		},
		//设置网站客服
		SET_SITESERVICE: (state, service) => {
			state.service = service
		},
		//设置网站语言名称
		SET_SITELANGNAME: (state, langName) => {
			state.langName = langName
			localStorage.setItem('langName', langName);
		},
		//设置网站语言ID
		SET_SITELANGID: (state, langId) => {
			state.langId = langId
			localStorage.setItem('langId', langId);
		}
	}
}

export default site