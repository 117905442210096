import request from "@/utils/request.js"

// 获取所有币种
export function getSymbols (params) {
	return request({
		url: '/api/symbols',
		method: 'get',
		params
	})
}

//获取交易对初始价格
export function getSymbolsDayMarket (params) {
	return request({
		url: '/api/symbols/day-markets',
		method: 'get',
		params
	})
}

//币币交易
export function coinOrder (data) {
	return request({
		url: '/api/coin-orders',
		method: 'post',
		data
		// {
		//   "symbolId": "string",
		//   "direction": 0,
		//   "type": 0,
		//   "volume": 0,
		//   "price": 0
		// }
	})
}
//获取订单
export function getOrders (params) {
	return request({
		url: '/api/coin-orders',
		method: 'get',
		params
	})
}
//撤单
export function deleteOrder (id) {
	return request({
		url: '/api/coin-orders/' + id,
		method: 'delete'
	})
}
//获取自选
export function getFavorites () {
	return request({
		url: '/api/symbols/favorites',
		method: 'get'
	})
}
//添加自选
export function addFavorite (symbolId) {
	return request({
		url: '/api/symbols/favorites',
		method: 'post',
		data: {
			"symbolId": symbolId,
			"sort": 0
		}
	})
}
//删自选
export function removeFavorite (symbolId) {
	return request({
		url: '/api/symbols/favorites/' + symbolId,
		method: 'delete'
	})
}