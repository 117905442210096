module.exports = {
	common: {
		name: '交易所',
		welcome: '欢迎来到',
		login: '登录',
		register: '注册',
		emailaddr: '邮箱地址',
		password: '密码',
		later: '稍后登录，先逛逛',
		next: '下一步',
		amount: '数量',
		price: '价格',
		direction: '方向',
		buy: '买入',
		sell: '卖出',
		loginFirst: '请先登录',
		confirmFirst: '请先认证',
		confirmNow: '马上去实名认证',
		cancel: '取消',
		sure: '确认',
		orderDetail: '订单详情',
		insufficient: '可交易USDT余额不足',
		orderSuc: '下单成功',
		logout: '退出登录',
		logoutSuc: '退出成功',
		sureChange: '确认修改',
		sendCode: '发送验证码',
		sendLater: '已发送，请稍后重试',
		time: '时间',
		success: '成功',
		revoke: '撤销',
		errReason: '失败原因',
		nodata: '加载中...暂无相关数据',
		verifyNow: '认证',
		wait: '稍后',
		getWait1: '正在获取充值二维码，请耐心等待...',
		getWait2: '正在获取充值地址，请耐心等待...',
		submiting: '当前一个订单正在提交中，请稍后'
	},
	footer: {
		index: '首页',
		trend: '行情',
		trade: '交易',
		finance: '资金',
		me: '我的'
	},
	login: {
		inputTs: '请输入用户名和密码'
	},
	register: {
		emailReg: '邮箱注册',
		countryTs: '国家注册后不可修改',
		success: '发送邮件成功',
		error: '邮箱格式不正确',
		sendSuc: '发送邮件成功',
		suc: '注册成功'
	},
	email: {
		tit: '邮箱',
		check: '若未收到邮件，请检查邮箱垃圾箱',
		plsInput: '请输入验证码',
		again: '重新获取验证码',
		second: '秒后重新获取'
	},
	pwd: {
		set: '设置密码',
		enter: '输入密码',
		length: '6-20位字符',
		again: '请再次输入密码',
		code: '邀请码',
		agree: '同意',
		protocol: '用户协议',
		ts1: '请输入长度6-20的有效密码',
		ts2: '两次密码不一致',
		ts3: '请输入邀请码'
	},
	index: {
		recharge: '充值',
		second: '秒合约',
		share: '分享赚佣',
		online: '在线客服',
		buyUsdt: '购买USDT',
		buyUsdtIntro: '10多个全球主流平台，可安全购买USDT',
		ranking: '涨幅榜'
	},
	quotes: {
		search: '搜索币种',
		self: '自选',
		all: '现货',
		name: '名称',
		volume: '成交量',
		price: '最新价',
		ranking: '24h涨跌'
	},
	trade: {
		coin: '币币交易',
		second: '秒合约交易',
		limit: '限价',
		market: '市价',
		price: '价格',
		amount: '数量',
		inputUSDT: '请输入要花费的USDT的数量',
		available: '可用',
		all: '全仓',
		transactionAmount: '交易额',
		handicap: '盘口',
		curEntrust: '当前委托',
		historyEntrust: '历史委托',
		totalEntrust: '委托总量',
		finish: '已经完成',
		cost: '消费',
		income: '收入',
		revoke: '撤单',
		category: '币种',
		direction: '方向',
		sure: '确认订单',
		cancel: '取消订单',
		orderStatu1: '等待成交',
		orderStatu2: '已完成',
		orderStatu3: '正在撤单',
		orderStatu4: '已撤单',
		addFavoriteSuc: '加自选成功',
		removeFavoriteSuc: '删自选成功',
		inputTs1: '请输入正确的价格和数量',
		inputTs2: '请输入正确的USDT数量',
		revokeOrder: '撤销订单',
		sureRevokeOrder: '确定撤销该订单吗?',
		revokeOrderSuc: '撤销订单成功'
	},
	trend: {
		high: '高',
		low: '低',
		volume: '量',
		turnover: '额',
		entrust: '委托',
		deal: '成交',
		buyList: '买盘',
		sellList: '卖盘',
		add: '加自选',
		remove: '删自选',
		addSuc: '加自选成功',
		removeSuc: '删自选成功'
	},
	second: {
		amount: '数量',
		time: '时间',
		odds: '赔率',
		available: '可交易余额',
		doLong: '买涨',
		doShort: '买跌',
		category: '币种',
		openPrice: '开仓价格',
		endPrice: '截止价格',
		dealCategory: '开仓币种',
		dealAmount: '开仓数量',
		dealDirection: '开仓方向',
		dealTime: '开仓时间',
		profit: "盈利",
		sure: '确认订单',
		cancel: '取消订单',
		orderStatus1: '倒计时',
		orderStatus2: '已完成',
		orderStatus3: '已取消',
		noSupport: '当前交易暂时不支持秒合约，已自动切换交易对',
		minVolume: '当前最小下注金额为:',
		maxVolume: '当前最大下注金额为:',
		being: '正在提交订单',
		fail: '订单提交失败'
	},
	me: {
		tit: '个人中心',
		identity: '身份认证',
		security: '安全设置',
		financeRecord: '财务记录',
		tradeRecord: '交易记录',
		setting: '通用设置',
		about: '关于我们',
		share: '分享好友',
		service: '联系客服',
		identityStatus1: '未认证',
		identityStatus2: '实名审核中',
		identityStatus3: '已认证',
		identityStatus4: '审核失败',
	},
	identity: {
		tit: '身份认证',
		plsInput: '请输入身份信息',
		oneBindTs: '一个证件只能实名认证绑定一个账户，请您核实无误后进行绑定。',
		status1: '等待审核中， 请耐心等待审核通过',
		status2: '审核通过, 您的账号现在更加安全',
		status3: '审核失败，请修改信息信息重新提交审核',
		errReason: '失败原因',
		name: '姓名',
		id: '身份证',
		plsUp: '请上传',
		frontImg: '您的身份证人像面',
		backImg: '您的身份证国徽面',
		status4: '申请认证',
		status5: '等待审核中',
		status6: '认证通过',
		status7: '重新认证',
		requireTis: '上传要求',
		require1: '1. 必须能看清楚身份证信息',
		require2: '2. 照片内容真实有效，不得做任何修改',
		require3: '3. 必须能看清证件号和姓名， 支持jpg / png / gif',
		require4: '4. 图片最大不超过10M',
		limit: '请选择10M以内的图片',
		submitSuc: '提交成功，请耐心等待审核通过'
	},
	news: {
		list: '公告列表',
		detail: '公告详情'
	},
	setting: {
		tit: '设置',
		lang: '语言',
		price: '计价方式'
	},
	share: {
		earnUsdt: '邀请好友赚取USDT',
		intro: '成功邀请好友，获取好友每笔充值金额的1%',
		code: '邀请码',
		copy: '复制分享链接',
		step1: '分享给好友',
		step2: '好友注册充值',
		step3: '获取佣金',
		data: '分享数据',
		register: '邀请注册',
		recharge: '充值用户',
		commission: '累积佣金',
		copySuc: '复制成功',
		copyErr: '复制失败，请扫码'
	},
	security: {
		tit: '安全设置',
		loginPwd: '登录密码',
		assetPwd: '资金密码',
		change: '修改密码',
		set: '设置密码',
		setAssetPwd: '设置资金密码',
		forgetloginPwd: '忘记登录密码',
		forgetassetPwd: '忘记资金密码',
		changeloginPwd: '修改登录密码',
		changeassetPwd: '修改资金密码',
		oldPwd: '原始密码',
		newPwd: '新密码',
		code: '邮箱验证码',
		changeloginPwdSuc: '登录密码修改成功',
		setloginPwdSuc: '登录密码设置成功',
		changeassetPwdSuc: '资金密码修改成功',
		setassetPwdSuc: '资金密码设置成功'
	},
	finance: {
		all: '总览',
		toUSDT: '总资产折合',
		recharge: '充币',
		withdraw: '提币',
		available: '可用',
		freeze: '冻结',
		equivalence: '折合'
	},
	deposit: {
		tit: '充值',
		record: '充值记录',
		channel: '充值通道',
		scan: '扫描二维码, 转入',
		address: '钱包地址',
		copy: '复制地址',
		min: '最小充值数为',
		wait: '充值之后等待10到30分钟自动到账',
		rightChannel: '请选择正确的充值通道，否则将不会到账',
		copySuc: '复制成功',
		copyErr: '复制失败，请手动复制',
		channelFail: '获取通道信息失败'
	},
	transfer: {
		tit: '提币',
		record: '提币记录',
		channel: '提币通道',
		coin: '提取币种',
		address: '提币地址',
		amount: '提币金额',
		balance: '余额',
		fee: '旷工费',
		counting: '计算中',
		number: '数量',
		detial: '提币详情',
		assetPwd: '资金密码',
		password: '密码',
		wrongAddr: '提币地址不正确',
		rightNum: '请输入有效的提币数量',
		insufficient: '余额不足',
		submitSuc: '提币申请提交成功',
		min: '最小提币'
	},
	about: {
		tit: '关于我们'
	},
	coinRecord: {
		tit: '交易记录',
		select: '筛选',
		type: '类型',
		change: '账户变化',
		all: '全部',
		exchange: '币币交易',
		recharge: '充值',
		withdraw: '提款',
		system: '系统'
	},
	financeRecord: {
		tit: '财务记录',
		recharge: '充值',
		withdraw: '提款',
		amount: '金额',
		fee: '手续费',
		address: '地址',
		transaction: '交易',
		status: '状态',
		status1: '等待处理',
		status2: '拒绝提款',
		status3: '提款撤销',
		status4: '等待转账',
		status5: '转账完成',
		revokeSuc: '撤销成功'
	},
	platform: {
		tit: '购买USDT平台',
		ts1: '可根据自己所在国家，以及支持的法币选择平台，在应用商店下载APP或者对应的官方网页进行购买USDT，完成USDT购买之后，可以转移到本平台进行投资等操作',
		ts2: '切记不要在山寨平台购买USDT，以防上当受骗'
	},
	req: {
		ts1: '正在努力加载中...',
		ts2: '数据格式错误',
		ts3: '身份认证已过期，请重新登陆',
		ts4: '抱歉，服务器错误，请稍后再试',
		ts5: '请先登录...'
	},
	question: {
		tit: '提交问题',
		type: '类型',
		describe: '描述',
		submit: '提交留言',
		history: '以往问题',
		wait: '等待回复',
		pls: '请输入问题内容',
		suc: '问题提交成功'
	},
	nfts: {
		tit: '收藏品',
		des: '发现、收集和销售独一无二的NFT',
		detail: 'Knwex是一个很大很完善的NFT平台',
	},
	collection: {
		by: '创作者',
		items: '项目',
		owners: '所有者',
		totalVolume: '总交易量',
		floorPrice: '地板价',
		bestOffer: '最佳报价',
		filters: '筛选',
		sort: '排序',
		price: '价格',
		lastSale: '最后销售',
		clearAll: '全部清除',
		done: '已完成',
		buyNow: '立即购买',
		onAuction: '拍卖中',
		pricelth: '价格从低到高',
		pricehtl: '价格从高到低',
		favorited: '喜欢最多',
		all: '全部'
	},
	nft: {
		owned: '拥有者',
		favorite: '喜欢',
		saleEnds: '促销结束 ',
		currentPrice: '当前价格',
		buyNow: '购买',
		makeOffer: '报价',
		cancelListing: '取消出售',
		sell: '出售',
		transfer: '转移',
		price: '价格',
		expiration: '到期时间',
		from: '从',
		action: '活动',
		description: '描述',
		details: '详情',
		tokenID: '代币ID',
		tokenStandard: '代币标准',
		blockchain: '区块链',
		fee: '创作者收益',
		activity: '活动',
		event: '事件',
		to: '到',
		date: '时间',
		order: '订单详情',
		collectionName: '集合名称',
		name: 'nft名称',
		buy: '购买',
		cancel: '取消',
		balance: '余额',
		day1: '1天',
		day3: '3天',
		day7: '7天',
		day30: '30天',
		inputPrice: '请输入价格',
		end: '截止',
		buySuc: '购买成功',
		cancelSuc: '取消订单成功',
		offerSuc: '竞价成功',
		sellSuc: '售卖成功',
		transferSuc: '转移成功',
		offerCancelSuc: '竞价取消成功',
		transferAccount: '输入要转移到的邮箱账户'
	},
	assets: {
		de: '的'
	}
} 