import request from "@/utils/request.js"

// 获取历史走势
export function getKlines (params) {
	return request({
		url: '/api/klines',
		method: 'get',
		params
	})
}

//获取当前正在走的哪一个走势线
export function getlatestKline (symbolId, category) {
	return request({
		url: '/api/klines/'+symbolId+'/'+category+'/latest',
		method: 'get',
	})
}