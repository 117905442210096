<template>
	<div class="message">
		<div class="header tc">
			<span @click="$router.goBack()" class="iconfont fl">&#xe607;</span>
			{{$t('question.tit')}}
		</div>
		<div class="cnt">
			<div>
				<div><h1>{{$t('question.type')}}</h1></div>
				<div class="inputBox"><input type="text" v-model="question" /></div>
			</div>
			<div>
				<div><h1>{{$t('question.describe')}}</h1></div>
				<div class="inputBox">
					<textarea v-model="detail" >
						
					</textarea>
				</div>
			</div>
			<div class="submit">
				<mt-button type="primary" size="large" @click='feedbackFunc()'>{{$t('question.submit')}}</mt-button>
			</div>
		</div>
		<div class="list" v-show="questionsArr.length">
			<div><h2>{{$t('question.history')}}</h2></div>
			<div class="item" v-for="(question,index) of questionsDetailArr" :key="index">
				<div class="q"><span>Q: </span>{{question[0]['content']}}</div>
				<div class="a" v-for="a of question.slice(1)" :key="a.id"><span>A: </span>{{a.content}}</div>
				<div class="red" v-if="question.length==1"><span>A: </span>{{$t('question.wait')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { feedback, getFeedbacks, getFeedbackByID } from '@/api/user.js'
export default {
	name: 'Message',
	computed: {
		...mapGetters(['hasAssetPassword','email'])
	},
	data () {
		return {
			questionsArr: [], //所以问题
			questionsDetailArr: [],//所以问题详情
			question: '',
			detail: ''
		}
	},
	methods: {
		//提交留言
		feedbackFunc () {
			if (this.question == '' || this.detail == '') {
				this.$toast({
					message: this.$t('question.pls'), 
					duration: 1000
				})
				return
			}
			let data = {
				question: this.question,
				detail: this.detail
			}
			feedback(data).then(data => {
				console.log(data)
				this.$toast({
					message: this.$t('question.suc'), 
					duration: 1000
				})
				this.getFeedbacksFunc()
			})
		},
		//获取问题和回复
		getFeedbacksFunc () {
			getFeedbacks().then(data => {
				console.log(data)
				this.questionsArr = data.items
				this.questionsArr.forEach(item => {
					getFeedbackByID(item.id).then(data => {
						this.questionsDetailArr.push(data.replies)
					})
				})
				console.log(this.questionsDetailArr)
			})
		}
	},
	mounted () {
		this.getFeedbacksFunc()
	}
}
</script>

<style scoped>
.header {
	height: 54px;
	line-height: 54px;
	padding: 0 50px 0 10px;
	border-bottom: 1px #f0f0f0 solid;
}
.header .iconfont {
	padding-right: 40px;
}
.cnt {
	padding: 20px 15px 10px;
}
.cnt h1 {
	font-size: 13px;
	border-left: 4px #9b8d8d solid;
	padding-left: 10px;
	line-height: 12px;
}
.inputBox {
	margin-top: 10px;	
	margin-bottom: 20px;
}
.inputBox input,.inputBox textarea {
	width: 100%;
	border: 1px #eee solid;
	line-height: 40px;
	text-indent: 10px;
}
.submit {
	margin-top: 20px;
}
.list {
	margin-top: 4px;
	padding: 10px 15px;
}
.list h2 {
	font-size: 13px;
	margin-bottom: 10px;
}
.item {
	font-size: 12px;
	border-bottom: 1px #eee dashed;
	padding: 5px;
	margin-bottom: 10px;
}
.a {
	color: #42b508;
}
</style>
